import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, catchError, tap } from 'rxjs';
import { CommonUtilities } from '../_common/common-utilities';
import { VoiceInfo } from '../_models/voice-info';

@Injectable({
  providedIn: 'root'
})
export class VoiceInfoService {

  constructor(
    private http: HttpClient) { }

  getList(ownerId: string): Observable<VoiceInfo[]> {
    console.log(`Getting VoiceInfo list from the server /api/voiceinfo/${ownerId}.`);
    return this.http.get<VoiceInfo[]>(`/api/voice/${ownerId}`).pipe(
      catchError(err => CommonUtilities.handleHttpError<VoiceInfo[]>(err))
    );
  }

  refreshVoices(): Observable<any> {
    console.log(`Refreshing voice list from the server /api/voice/refresh`);
    return this.http.get<VoiceInfo[]>(`/api/voice/refresh`).pipe(
      tap(() => console.log('Refreshed voice list.')),
      catchError(err => CommonUtilities.handleHttpError<any>(err))
    );
  }
}



