import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subscription, timer } from 'rxjs';
import { ProjectJob } from 'src/app/_models/project-job';
import { ProjectJobService } from 'src/app/_services/project-job.service';

@Component({
  selector: 'app-project-job-status',
  templateUrl: './project-job-status.component.html',
  styleUrl: './project-job-status.component.css'
})
export class ProjectJobStatusComponent implements OnInit, OnDestroy {
  @Input() ownerId = '';
  @Input() projectId = '';
  data: ProjectJob[] = [];
  jobStatusMessage = '';
  private subscription: Subscription | null = null;
  private timer: Observable<number> | null = null;

  constructor(private service: ProjectJobService) {
    console.log('ProjectJobStatusComponent constructor');
  }

  ngOnInit(): void {
    this.startTimer();
  }

  startTimer(): void {
    console.log('ProjectJobStatusComponent startTimer');

    if (this.subscription) {
      this.stopTimer();
    }

    this.timer = timer(1000, 10000);
    this.subscription = this.timer.subscribe(() => {
      this.refreshData();
    });
  }

  stopTimer(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
      this.subscription = null;
      this.timer = null;
    }
  }

  ngOnDestroy(): void {
    console.log('**** ProjectJobStatusComponent ngOnDestroy');
    this.stopTimer();
  }

  refreshData(): void {
    if (this.ownerId === '' || this.projectId === '') {
      console.log('ProjectJobStatusComponent: ownerId and projectId are required.');
      return;
    }
    else {
      this.service.getJobStatus(this.ownerId, this.projectId).subscribe(
        {
          next: data => {
            console.log('ProjectJobStatusComponent getJobStatus data:', data);

            this.data = data.filter(job => job.status !== 'COMPLETED' && job.status !== 'ERROR');
            this.updateJobStatusMessage();
          },
          error: error => {
            console.error('ProjectJobStatusComponent getJobStatus error:', error);
            this.data = [];
            this.updateJobStatusMessage();
          }
        });
    }
  }

  updateJobStatusMessage() {

    if (this.data.length === 0) {
      console.log('ProjectJobStatusComponent: No jobs in progress.');
      this.jobStatusMessage = 'No jobs in progress.';
    }
    else {
      console.log('ProjectJobStatusComponent: Jobs in progress. Preparing message...');
      // count the number of jobs in each jobtypename
      const jobTypeCounts: Map<string, number> = new Map<string, number>();

      this.data.forEach(job => {
        if (job.status !== 'COMPLETED' && job.status !== 'ERROR') {
          if (jobTypeCounts.has(job.jobTypeName) === false) {
            jobTypeCounts.set(job.jobTypeName, 1);
          }
          else {
            const value = jobTypeCounts.get(job.jobTypeName)

            if (value === undefined) {
              jobTypeCounts.set(job.jobTypeName, 1);
            }
            else {
              jobTypeCounts.set(job.jobTypeName, value + 1);
            }
          }
        }
      });

      // build the message
      let message = '';
      jobTypeCounts.forEach((value, key) => {
        if (message !== '') {
          message += ', ';
        }

        const friendlyJobInfo = this.getFriendlyJobTypeMessage(key, value);

        message += `${friendlyJobInfo}`;
      });

      this.jobStatusMessage = message;
      console.log('ProjectJobStatusComponent: job status message:', this.jobStatusMessage);
    }
  }
  getFriendlyJobTypeMessage(key: string, numberOfJobs: number) {
    let jobText: string;

    if (numberOfJobs === 1) {
      jobText = 'job';
    }
    else {
      jobText = 'jobs';
    }


    if (key === 'GenerateSlideItemAudio') {
      return `${numberOfJobs} audio file ${jobText}`;
    }
    else if (key === 'GenerateSlideVideo') {
      return `${numberOfJobs} slide video file ${jobText}`;
    }
    else if (key === 'GenerateProjectVideo') {
      return `${numberOfJobs} project video file ${jobText}`;
    }
    else if (key === 'ParseSlideText') {
      return `${numberOfJobs} slide text ${jobText}`;
    }
    else if (key === 'PerSlideScreenshots') {
      return `${numberOfJobs} slide preview image ${jobText}`;
    }
    else if (key === 'PerBulletScreenshots') {
      return `${numberOfJobs} slide detail image ${jobText}`;
    }
    else {
      return `${numberOfJobs} ${key} ${jobText}`;
    }
  }
}
