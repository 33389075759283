import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ApplicationConstants } from 'src/app/_common/application-constants';
import { ApplicationError } from 'src/app/_common/application-error';
import { CommonUtilities } from 'src/app/_common/common-utilities';
import { PresentationOutline } from 'src/app/_models/presentation-outline';
import { SlideOutline } from 'src/app/_models/slide-outline';
import { PresentationOutlineService } from 'src/app/_services/presentation-outline.service';
import { RoutingHelperService } from 'src/app/_services/routing-helper.service';
import { SecurityService } from 'src/app/_services/security.service';

@Component({
  selector: 'app-presentation-outline-detail',
  templateUrl: './presentation-outline-detail.component.html',
  styleUrl: './presentation-outline-detail.component.css'
})
export class PresentationOutlineDetailComponent implements OnInit {
  public theModel: PresentationOutline;
  public message: string = ApplicationConstants.emptyMessage;
  public theId: string = ApplicationConstants.emptyMessage;
  public ownerId: string = ApplicationConstants.emptyMessage;
  public isLoading: boolean = false;
  public isLoaded: boolean = false;

  theForm = this.fb.nonNullable.group({
    ownerId: [{ value: ApplicationConstants.defaultString, disabled: true }],
    title: [ApplicationConstants.defaultString],
    status: [ApplicationConstants.defaultString],
    statusMessage: [ApplicationConstants.defaultString],
    statusMessageDetail: [ApplicationConstants.defaultString],
    createdDate: [ApplicationConstants.defaultDate],
    completedDate: [ApplicationConstants.defaultDate],
    templateName: [ApplicationConstants.defaultString],
    promptText: [ApplicationConstants.defaultString],
    requestedSlideCount: [ApplicationConstants.defaultNumber],
    topic1Name: [ApplicationConstants.defaultString],
    topic2Name: [ApplicationConstants.defaultString],
    isComparison: [false],
    hasPptx: [false],
    pptxUrl: [ApplicationConstants.defaultString],
    slides: this.fb.nonNullable.array<SlideOutline>([]),
  });

  constructor(private service: PresentationOutlineService,
    private routingHelper: RoutingHelperService,
    public securityService: SecurityService,
    private fb: FormBuilder) {
    this.theModel = new PresentationOutline();
  }

  ngOnInit(): void {
    this.ownerId = this.routingHelper.getValue('ownerId');
    this.theId = this.routingHelper.getValue('id');

    if (
      this.theId !== '' && this.ownerId !== '' &&
      this.theId !== '0' && this.ownerId !== '0') {
      this.load();
    }
    else {
      this.message = 'owner id and id are required';
    }
  }

  load(): void {
    this.isLoading = true;
    this.service.get(this.ownerId, this.theId).subscribe({
      next: (result) => {
        this.isLoading = false;
        if (result === null) {
          this.message = 'No record found';
          this.isLoaded = false;
          this.isLoading = false;
          return;
        }
        else {
          this.theModel = result;
          this.isLoaded = true;
          this.isLoading = false;
          this.afterLoad();
        }
      },
      error: (error: ApplicationError) => {
        this.isLoading = false;
        this.isLoaded = false;
        this.message = CommonUtilities.formatErrorMessageFromAny(error);
      }
    });
  }

  afterLoad() {
    this.theForm.patchValue({
      ownerId: this.theModel.ownerId,
      title: this.theModel.title,
      status: this.theModel.status,
      statusMessage: this.theModel.statusMessage,
      statusMessageDetail: this.theModel.statusMessageDetail,
      createdDate: this.theModel.createdDate,
      completedDate: this.theModel.completedDate,
      templateName: this.theModel.templateName,
      promptText: this.theModel.promptText,
      requestedSlideCount: this.theModel.requestedSlideCount,
      topic1Name: this.theModel.topic1Name,
      topic2Name: this.theModel.topic2Name,
      isComparison: this.theModel.isComparison,
      hasPptx: this.theModel.hasPptx,
      pptxUrl: this.theModel.pptxUrl
    });

    this.theForm.controls.slides = this.fb.nonNullable.array<SlideOutline>(this.theModel.slides);
  }

  createPptx() {
    if (
      this.theId !== '' && this.ownerId !== '' &&
      this.theId !== '0' && this.ownerId !== '0') {
     
    }
    else {
      this.message = 'owner id and id are required';
      return;
    }

    this.isLoading = true;
    this.service.createPptx(this.ownerId, this.theId).subscribe({
      next: (result) => {
        this.theModel = result;
        this.isLoading = false;
        this.isLoaded = true;
        this.afterLoad();
      },
      error: (error) => {
        this.isLoading = false;
        this.isLoaded = true;
        console.error(error);
        this.message = error.message;
      }
    });
  }

  createProject() {
    if (
      this.theId !== '' && this.ownerId !== '' &&
      this.theId !== '0' && this.ownerId !== '0') {
     
    }
    else {
      this.message = 'owner id and id are required';
      return;
    }

    this.isLoading = true;
    this.service.createProject(this.ownerId, this.theId).subscribe({
      next: (result) => {
        this.load();
      },
      error: (error) => {
        this.isLoading = false;
        this.isLoaded = true;
        console.error(error);
        this.message = error.message;
      }
    });
  }

  refresh() {
    if (
      this.theId !== '' && this.ownerId !== '' &&
      this.theId !== '0' && this.ownerId !== '0') {
      this.load();
    }
    else {
      this.message = 'owner id and id are required';
    }
  }

  save() {

  }

  delete() {

  }

  cancel() {
    this.routingHelper.navigateTo('presentation-outline/list/' + this.ownerId);
  }


}
