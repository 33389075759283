/* eslint-disable @typescript-eslint/no-explicit-any */
import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { CommonUtilities } from '../_common/common-utilities';
import { SayTextUpdate } from '../_models/say-text-update';
import { SlideItem } from '../_models/slide-item';

@Injectable({
  providedIn: 'root'
})
export class SlideItemService {

  constructor(
    private http: HttpClient) { }

  updateSayText(data: SayTextUpdate) : Observable<SayTextUpdate> {
    console.log(`Updating say text for slide item to the server /api/slideitem/${data.ownerId}.`);

    const url = `/api/slidedata/saytext/${data.ownerId}`;

    return this.http.post<SayTextUpdate>(url, data).pipe(
      catchError(err => CommonUtilities.handleHttpError<SayTextUpdate>(err))
    );
  }

  getUpdatedAudioUrl(data: SlideItem) : Observable<string> {
    console.log(`getting updated audio url from /api/slideitem/${data.ownerId}/${data.projectId}/${data.slideId}/${data.id}.`);

    const url = `/api/slidedata/itemaudiourl/${data.ownerId}/${data.projectId}/${data.slideId}/${data.id}`;

    return this.http.get<string>(url);
  }
}
