/* eslint-disable @angular-eslint/no-host-metadata-property */
import { AfterViewChecked, Component, OnInit, ViewChild } from '@angular/core';
import { SlideData } from '../../../_models/slide-data';
import { SlideDataService } from '../../../_services/slide-data.service';
import { RoutingHelperService } from '../../../_services/routing-helper.service';
import { Validators, FormBuilder } from '@angular/forms';
import { ApplicationError } from 'src/app/_common/application-error';
import { CommonUtilities } from 'src/app/_common/common-utilities';
import { ApplicationConstants } from 'src/app/_common/application-constants';
import { ProjectSlideData } from 'src/app/_models/project-slide-data';
import { SlideItemImageInfo } from 'src/app/_models/slide-item-image-info';
import { Project } from 'src/app/_models/project';
import { JobQueueService } from 'src/app/_services/job-queue.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { VideoPlayerDialogComponent } from '../../video-player-dialog/video-player-dialog.component';
import { VideoPlayerDialogInfo } from '../../video-player-dialog/VideoPlayerDialogInfo';
import { ProjectSlideDataUtilities } from 'src/app/_models/project-slide-data-utilities';
import { SlideItemListComponent } from '../../slide-item/slide-item-list/slide-item-list.component';
import { SecurityService } from 'src/app/_services/security.service';
import { MatSnackBar } from '@angular/material/snack-bar';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare let bootstrap: any;

@Component({
  selector: 'app-slide-data-details',
  templateUrl: './slide-data-details.component.html',
  styleUrls: ['./slide-data-details.component.css'],
  host: {
    '(document:keydown.control.1)': 'queueVideoRender()'
  }
})
export class SlideDataDetailsComponent implements OnInit, AfterViewChecked {

  @ViewChild('slideItemListControl', { static: false })
  public slideItemListControl: SlideItemListComponent | null = null;

  public theModel: SlideData;
  public project: Project = new Project();
  public slideItemImages: SlideItemImageInfo[] = [];
  public message: string = ApplicationConstants.emptyMessage;
  public theId: string = ApplicationConstants.emptyMessage;
  public isLoadingVideo = false;
  public isLoading = false;

  theForm = this.fb.nonNullable.group({
    slideDataPartitionKey: [ApplicationConstants.defaultString,
    [Validators.minLength(0), Validators.maxLength(100)]],
    slideDataSlideNumber: [ApplicationConstants.defaultString,
    [Validators.minLength(0), Validators.maxLength(100)]],
    slideDataSlideId: [ApplicationConstants.defaultString,
    [Validators.minLength(0), Validators.maxLength(100)]],
    slideDataSlideLayout: [ApplicationConstants.defaultString,
    [Validators.minLength(0), Validators.maxLength(100)]],
    slideDataSlideAnimationCount: [ApplicationConstants.defaultNumber, Validators.required],
    slideDataSlideTemplateAnimationCount: [ApplicationConstants.defaultNumber, Validators.required],
    slideDataItems: [ApplicationConstants.defaultString, Validators.required],
    slideDataItemsId: [ApplicationConstants.defaultNumber, Validators.required],
    slideDataId: [ApplicationConstants.defaultString],
    slideDataEtag: [ApplicationConstants.defaultString],
    slideDataTimestamp: [ApplicationConstants.defaultDate],
    previousSlideLink: [ApplicationConstants.defaultString],
    nextSlideLink: [ApplicationConstants.defaultString],
    startWithPause: [ApplicationConstants.defaultBoolean],
    endWithPause: [ApplicationConstants.defaultBoolean]
  });
  ownerId = ApplicationConstants.defaultString;
  projectId = ApplicationConstants.defaultString;
  projectSlideData: ProjectSlideData | null = null;

  constructor(private slideDataService: SlideDataService,
    private routingHelper: RoutingHelperService,
    private queueService: JobQueueService,
    public securityService: SecurityService,
    private fb: FormBuilder,
    private dialog: MatDialog,
    private snackBar: MatSnackBar) {
    this.theModel = new SlideData();
  }

  public showToastMessage(message: string, action: string) {
    this.snackBar.open(message, action, { duration: 3000, verticalPosition: 'top', horizontalPosition: 'center' });
  }

  updatePauses() {
    console.log('updatePauses()');

    if (this.isLoading === false) {
      this.isLoading = true;

      this.slideDataService.updatePauses(
        this.ownerId,
        this.projectId,
        this.theId,
        this.theForm.controls.startWithPause.value,
        this.theForm.controls.endWithPause.value).subscribe({
          next: (data: SlideData) => {
            this.theModel = data;
            this.theId = data.id.toString();
            this.afterLoad();
            this.isLoading = false;
          },
          error: (error: ApplicationError) => {
            this.message = CommonUtilities.formatErrorMessage(error);
            this.isLoading = false;
          }
        });
    }
  }

  startWithPauseChanged($event: any) {
    console.log('startWithPauseChanged()');
    this.updatePauses();
  }

  endWithPauseChanged($event: any) {
    console.log('endWithPauseChanged()');
    this.updatePauses();
  }


  ngOnInit() {
    this.theId = this.routingHelper.getId();
    this.ownerId = this.routingHelper.getValue('ownerId');
    this.projectId = this.routingHelper.getValue('projectId');

    this.loadById(this.ownerId, this.projectId, this.theId);

    this.theForm.controls.endWithPause.valueChanges.subscribe(() => {
      this.endWithPauseChanged(null);
    });

    this.theForm.controls.startWithPause.valueChanges.subscribe(() => {
      this.startWithPauseChanged(null);
    });
  }

  ngAfterViewChecked(): void {
    this.initializeTooltips();
  }

  initializeTooltips() {
    const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]');

    // console.log('tooltipTriggerList: ' + tooltipTriggerList);

    const tooltipList = Array.from(tooltipTriggerList).map(tooltipTriggerEl => {
      // console.log('tooltipTriggerEl: ' + tooltipTriggerEl);

      const tooltip = bootstrap.Tooltip.getOrCreateInstance(tooltipTriggerEl);

      return tooltip;
    });

    // console.log('tooltipList: ' + tooltipList);
  }

  hideAllTooltips() {
    const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]');

    // console.log('tooltipTriggerList: ' + tooltipTriggerList);

    const tooltipList = Array.from(tooltipTriggerList).map(tooltipTriggerEl => {
      // console.log('tooltipTriggerEl: ' + tooltipTriggerEl);

      const tooltip = bootstrap.Tooltip.getOrCreateInstance(tooltipTriggerEl);

      tooltip.hide();

      return tooltip;
    });

    // console.log('tooltipList: ' + tooltipList);
  }

  refresh() {
    this.hideAllTooltips();
    this.message = "Refreshing...";

    this.loadById(this.ownerId, this.projectId, this.theId);
  }

  public showVideoDialog() {
    this.hideAllTooltips();

    if (this.theModel === null || this.project === null) {
      this.message = 'No slide data is available for this slide.';
    }
    else {
      this.message = ApplicationConstants.emptyMessage;

      this.isLoadingVideo = true;

      this.slideDataService.getByOwnerIdAndId(
        this.project.ownerId, this.project.id, this.theModel.id).subscribe({
          next: (data: ProjectSlideData | null) => {
            this.isLoadingVideo = false;
            if (data === null || data.slideData === null || data.project === null) {
              this.message = "Problem loading slide video url. Please try again.";
            } else {
              this.showVideoDialogUsingRefreshedSlideData(data.slideData);
            }
          },
          error: (error: ApplicationError) => {
            this.isLoadingVideo = false;
            this.message = CommonUtilities.formatErrorMessageFromAny(error);
          }
        });
    }
  }

  private showVideoDialogUsingRefreshedSlideData(slide: SlideData) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.maxHeight = '90vh';

    const data = new VideoPlayerDialogInfo();

    data.videoUrl = slide.videoUrl;
    console.log(`showVideoDialogUsingRefreshedSlideData() -- videoUrl: ${data.videoUrl}`);
    data.title = `Slide Video for ${this.project.name} - Slide ${slide.slideNumber}`;

    dialogConfig.data = data;

    this.dialog.open(VideoPlayerDialogComponent, dialogConfig);
  }

  queueScreenshots() {
    this.hideAllTooltips();
    this.queueService.queueScreenshotsOneSlide(this.ownerId, this.projectId,
      Number(this.theModel.slideNumber)).subscribe({
        next: () => {
          this.showToastMessage('Screenshots have been queued for processing.', 'close');
        },
        error: (error: ApplicationError) => {
          this.message = CommonUtilities.formatErrorMessage(error);
        }
      });
  }

  // @HostListener('document:keydown', ['$event'])
  // logKeydownEvent(event: KeyboardEvent) {

  //   console.log(`logKey() -- key: ${event}`);
  // }


  // @HostListener('window:keydown.control.alt.q', ['$event'])
  // @HostListener('keydown.alt.q', ['$event'])
  queueVideoRender() {
    console.log('queueVideoRender()');
    this.hideAllTooltips();
    this.slideDataService.queueVideoRender(this.ownerId, this.projectId, this.theModel.id).subscribe({
      next: () => {
        console.log('queueVideoRender() -- success');
        this.showToastMessage('Video creation has been queued for processing.', 'close');
      },
      error: (error: ApplicationError) => {
        this.message = CommonUtilities.formatErrorMessage(error);
      }
    });
  }

  afterLoad() {
    if (this.theModel !== null) {
      console.log('afterLoad() -- theModel is not null');
      this.populateFormFromModel();
    } else {
      console.log('afterLoad() -- theModel is null');
    }
  }

  save() {
    this.message = ApplicationConstants.emptyMessage;
    if (this.theForm.valid) {
      this.beforeSave();

      this.slideDataService.save(this.theModel).subscribe({
        next: (data: SlideData) => {
          this.message = ApplicationConstants.savedMessage;
          this.theModel = data;
          this.theId = data.id.toString();
          this.afterLoad();
        },
        error: (error: ApplicationError) => {
          this.message = CommonUtilities.formatErrorMessage(error);
        }
      });
    } else {
      const msg = 'Slide data form is in an invalid state';
      this.message = msg;
      console.info(msg);
    }
  }

  beforeSave() {
    this.hideAllTooltips();
    if (this.theModel !== null) {
      console.log('beforeSave() -- theModel is not null');
      this.populateModelFromForm();

    } else {
      console.log('beforeSave() -- theModel is null');
    }
  }

  navigateToList() {
    this.hideAllTooltips();
    this.routingHelper.navigateTo('/slide-data/slide-data-list');
  }

  cancel() {
    this.hideAllTooltips();
    this.routingHelper.back();
  }

  delete() {
    this.hideAllTooltips();
    if (window.confirm('Are you sure you want to delete this item?')) {
      if (this.theId !== '0') {
        this.slideDataService.deleteById(Number(this.theId))
          .subscribe({
            next: () => {
              this.navigateToList();
            },
            error: error => {
              this.message = CommonUtilities.formatErrorMessage(error);
            }
          });
      } else {
        this.navigateToList();
      }
    }
  }

  private initializeToBlank() {
    const parentId = this.routingHelper.getValue('parentId');

    if (parentId === null) {
      this.message = 'Parent id for Slide item is missing.';
    } else {
      const temp = new SlideData();
      temp.itemsId = parseInt(parentId, 10);
      this.theModel = temp;

      this.populateFormFromModel();
    }
  }
  private populateModelFromForm() {
    const toValue = this.theModel;

    if (toValue === null) {
      this.message = 'the model is null';
    }
    else {
      toValue.partitionKey = this.theForm.controls.slideDataPartitionKey.value;
      toValue.slideNumber = this.theForm.controls.slideDataSlideNumber.value;
      toValue.slideId = this.theForm.controls.slideDataSlideId.value;
      toValue.slideLayout = this.theForm.controls.slideDataSlideLayout.value;
      toValue.slideAnimationCount = this.theForm.controls.slideDataSlideAnimationCount.value;
      toValue.slideTemplateAnimationCount = this.theForm.controls.slideDataSlideTemplateAnimationCount.value;
      toValue.etag = this.theForm.controls.slideDataEtag.value;
      toValue.timestamp = this.theForm.controls.slideDataTimestamp.value;

    }
  }

  private populateFormFromModel() {
    const fromValue = this.theModel;

    if (fromValue === null) {
      this.message = 'the model is null';
    }
    else {
      this.theForm.patchValue({
        slideDataPartitionKey: fromValue.partitionKey,
        slideDataSlideNumber: fromValue.slideNumber,
        slideDataSlideId: fromValue.slideId,
        slideDataSlideLayout: fromValue.slideLayout,
        slideDataSlideAnimationCount: fromValue.slideAnimationCount,
        slideDataSlideTemplateAnimationCount: fromValue.slideTemplateAnimationCount,
        slideDataId: fromValue.id,
        slideDataEtag: fromValue.etag,
        slideDataTimestamp: fromValue.timestamp,
        startWithPause: fromValue.startWithPause,
        endWithPause: fromValue.endWithPause
      });
    }
  }

  private loadById(ownerId: string, projectId: string, slideDataId: string) {
    this.theId = slideDataId;
    this.ownerId = ownerId;
    this.projectId = projectId;

    const startTime = new Date().getTime();

    this.isLoading = true;

    this.slideDataService.getByOwnerIdAndId(
      ownerId, projectId, slideDataId).subscribe({
        next: (data: ProjectSlideData | null) => {
          console.log(`loadById() -- Duration to get result from service: ${new Date().getTime() - startTime} ms`);
          if (data === null || data.slideData === null || data.project === null) {
            this.projectSlideData = null;
            this.theModel = new SlideData();
            this.project = new Project();
            this.message = ApplicationConstants.noDataMessage;
          } else {
            this.projectSlideData = data;
            this.theModel = ProjectSlideDataUtilities.AssembleSlideItemsForSlide(data);
            this.project = data.project;
            this.slideItemImages = data.slideItemImages;

            this.populateSlideNavigation(data);

            this.message = ApplicationConstants.emptyMessage;

            this.afterLoad();

            console.log(`loadById() -- Duration to complete: ${new Date().getTime() - startTime} ms`);
          }

          this.isLoading = false;
        },
        error: (error: ApplicationError) => {
          this.message = CommonUtilities.formatErrorMessageFromAny(error);
          this.isLoading = false;
        }
      });
  }

  populateSlideNavigation(item: ProjectSlideData) {
    this.theForm.controls.previousSlideLink.disable();
    this.theForm.controls.nextSlideLink.disable();

    if (item === null) {
      return;
    }
    else {
      if (item.slideData?.nextSlideId !== '') {
        this.theForm.controls.nextSlideLink.enable();
      }

      if (item.slideData?.previousSlideId !== '') {
        this.theForm.controls.previousSlideLink.enable();
      }
    }
  }

  updateVideoIfChanged() {
    if (this.slideItemListControl !== null &&
      this.slideItemListControl.wasVoiceOverTextChanged === true) {
      this.queueVideoRender();
    }
  }

  navigateToNextSlide() {
    this.hideAllTooltips();
    console.log('navigateToNextSlide()');
    if (
      this.projectSlideData !== null &&
      this.projectSlideData?.slideData?.nextSlideId !== null &&
      this.projectSlideData?.project !== null) {

      const nextSlideId = this.projectSlideData?.slideData?.nextSlideId;

      if (nextSlideId === '' || nextSlideId === null || nextSlideId === undefined) {
        console.log('navigateToNextSlide() -- nextSlideId is empty');
        return;
      }

      const url = `/slide-data/slide-data-details/${this.projectSlideData?.project?.ownerId}/${this.projectSlideData?.project?.id}/${nextSlideId}`;

      this.updateVideoIfChanged();

      this.routingHelper.navigateTo(url);

      this.loadById(
        this.projectSlideData.project.ownerId,
        this.projectSlideData.project.id,
        nextSlideId);
    }
  }

  navigateToPreviousSlide() {
    this.hideAllTooltips();
    console.log('navigateToPreviousSlide()');
    if (
      this.projectSlideData !== null &&
      this.projectSlideData?.slideData?.previousSlideId !== null &&
      this.projectSlideData?.project !== null) {

      const previousSlideId = this.projectSlideData?.slideData?.previousSlideId;

      if (previousSlideId === '' || previousSlideId === null || previousSlideId === undefined) {
        console.log('navigateToPreviousSlide() -- previousSlideId is empty');
        return;
      }

      const url = `/slide-data/slide-data-details/${this.projectSlideData?.project?.ownerId}/${this.projectSlideData?.project?.id}/${previousSlideId}`;

      this.updateVideoIfChanged();

      this.routingHelper.navigateTo(url);

      this.loadById(
        this.projectSlideData.project.ownerId,
        this.projectSlideData.project.id,
        previousSlideId);
    }
  }

}
