import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ApplicationConstants } from 'src/app/_common/application-constants';
import { ApplicationError } from 'src/app/_common/application-error';
import { CommonUtilities } from 'src/app/_common/common-utilities';
import { Project } from 'src/app/_models/project';
import { ProjectService } from 'src/app/_services/project.service';
import { RoutingHelperService } from 'src/app/_services/routing-helper.service';
import { VoiceInfoService } from 'src/app/_services/voice-info.service';
import { VoiceInfo } from 'src/app/_models/voice-info';
import { filter } from 'rxjs';

@Component({
  selector: 'app-voice-chooser',
  templateUrl: './voice-chooser.component.html',
  styleUrl: './voice-chooser.component.css'
})
export class VoiceChooserComponent implements OnInit {

  public theModel: Project | null = null;
  public message: string = ApplicationConstants.emptyMessage;
  public theId: string = ApplicationConstants.emptyMessage;
  public ownerId: string = ApplicationConstants.emptyMessage;

  theForm = this.fb.nonNullable.group({
    projectName: [ApplicationConstants.defaultString],
    selectedVoiceId: ['', Validators.required],
    selectedVoiceName: [{ value: '(not selected)', disabled: true }],
    filterText: [ApplicationConstants.defaultString],
    filterLanguage: [ApplicationConstants.defaultString],
  });

  theVoices: VoiceInfo[] = [];
  theVoicesAllValues: VoiceInfo[] = [];
  voicesLoaded = false;

  constructor(private projectService: ProjectService,
    private voiceInfoService: VoiceInfoService,
    private routingHelper: RoutingHelperService,
    private fb: FormBuilder
  ) {

  }

  hasChanges(): boolean {
    if (this.theModel === null) {
      return false;
    }
    else {
      if (this.theModel.voiceId !== this.theForm.controls.selectedVoiceId.value) {
        return true;
      }
      else {
        return false;
      }
    }
  }

  ngOnInit(): void {
    this.ownerId = this.routingHelper.getValue('ownerId');
    this.theId = this.routingHelper.getValue('id');

    if (this.theId === '') {
      this.message = `No project id specified.`;
      return;
    }
    else if (this.ownerId === '') {
      this.message = `No owner id specified.`;
      return;
    }

    this.theForm.controls.filterText.valueChanges.subscribe({
      next: (data: string) => {
        this.filterVoices();
      }
    });

    this.theForm.controls.filterLanguage.valueChanges.subscribe({
      next: (data: string) => {
        this.filterVoices();
      }
    });

    this.voicesLoaded = false;

    this.voiceInfoService.getList(this.ownerId).subscribe({
      next: (data: VoiceInfo[]) => {
        this.voicesLoaded = false;

        if (data === null || data.length === 0) {
          this.message = `No voices found for this user.`;
          return;
        }
        else {
          console.log(`Voices found: ${data.length}`);
          this.theVoices = data;
          this.theVoicesAllValues = data;

          // i need to sort the voices by language then by name
          this.theVoices = data.sort((a, b) => {
            if (a.language < b.language) {
              return -1;
            }
            else if (a.language > b.language) {
              return 1;
            }
            else {
              if (a.voiceName < b.voiceName) {
                return -1;
              }
              else if (a.voiceName > b.voiceName) {
                return 1;
              }
              else {
                return 0;
              }
            }
          });
        }
      },
      error: (error: ApplicationError) => {
        this.message = CommonUtilities.formatErrorMessage(error);
      }
    });

    this.projectService.getOwnerAndById(this.ownerId, this.theId).subscribe({
      next: (data: Project | null) => {
        if (data === null) {
          this.message = `Project with id ${this.theId} not found.`;
          return;
        }
        else {
          this.theModel = data;

          if (this.theModel.voiceName === '') {
            this.theModel.voiceName = '(not selected)';
          }

          this.theForm.patchValue({
            projectName: this.theModel.name,
            selectedVoiceId: this.theModel.voiceId,
            selectedVoiceName: this.theModel.voiceName
          });
        }
      },
      error: (error: ApplicationError) => {
        this.message = CommonUtilities.formatErrorMessage(error);
      }
    });
  }

  resetVoice() {
    if (this.theModel === null) {
      return;
    }
    else {
      this.theForm.patchValue({
        projectName: this.theModel.name,
        selectedVoiceId: this.theModel.voiceId,
        selectedVoiceName: this.theModel.voiceName
      });
    }
  }

  filterVoices() {
    if (this.theVoicesAllValues === null || this.theVoicesAllValues.length === 0) {
      this.theVoices = [];
      return;
    }
    else {
      let filterText = this.theForm.controls.filterText.value;
      let filterLanguage = this.theForm.controls.filterLanguage.value;

      if (filterText === null || filterText === undefined) {
        filterText = '';
      }

      if (filterLanguage === null || filterLanguage === undefined) {
        filterLanguage = '';
      }

      filterText = filterText.trim().toLowerCase();
      filterLanguage = filterLanguage.trim().toLowerCase();

      if (filterText === '' && filterLanguage === '') {
        this.theVoices = this.theVoicesAllValues;
        return;
      }

      this.theVoices = this.theVoicesAllValues.filter((voice: VoiceInfo) => {
        let languageMatch = false;
        let textMatch = false;

        if (filterLanguage === '') {
          languageMatch = true;
        }
        else {
          if (voice.language.toLowerCase().includes(filterLanguage)) {
            languageMatch = true;
          }
        }

        if (filterText === '') {
          textMatch = true;
        }
        else {
          console.log(`Checking voice: ${voice.voiceName} for filter text: ${filterText}`);
          if (voice.voiceName.toLowerCase().includes(filterText) ||
            voice.labels?.accent.toLowerCase().includes(filterText) ||
            voice.labels?.age.toLowerCase().includes(filterText) ||
            voice.descriptive.toLowerCase().includes(filterText) ||
            voice.labels?.useCase?.toLowerCase().includes(filterText) ||
            voice.labels?.description?.toLowerCase().includes(filterText) ||
            voice.labels?.gender?.toLowerCase().includes(filterText) ||
            voice.description.toLowerCase().includes(filterText)) {
            textMatch = true;
          }
        }

        return languageMatch && textMatch;
      });
    }
  }

  save() {
    if (this.theModel === null) {
      this.message = `Cannot save because the model is null.`;
      return;
    }
    else {
      this.theModel.voiceId = this.theForm.controls.selectedVoiceId.value;
      this.theModel.voiceName = this.theForm.controls.selectedVoiceName.value;

      this.projectService.save(this.theModel).subscribe({
        next: (data: Project) => {
          this.message = ApplicationConstants.savedMessage;
          this.theModel = data;
          this.routingHelper.navigateTo(`/project/project-editor/${this.ownerId}/${this.theId}`);
        },
        error: (error: ApplicationError) => {
          this.message = CommonUtilities.formatErrorMessage(error);
        }
      });
    }
  }

  cancel() {
    this.routingHelper.navigateTo(`/project/project-editor/${this.ownerId}/${this.theId}`);
  }

  selectVoice(voice: VoiceInfo) {
    this.theForm.patchValue({
      selectedVoiceId: voice.voiceId,
      selectedVoiceName: voice.voiceName
    });
  }

  getVoiceDescription(voice: VoiceInfo): string {
    let returnValue = '';

    if (voice === null) {
      return returnValue;
    }

    if (voice.labels === null) {
      return returnValue;
    }

    let needsSeparator = false;

    if (voice.language !== ApplicationConstants.defaultString) {
      if (needsSeparator) {
        returnValue = returnValue + '; ';
      }

      const regionNamesInEnglish = new Intl.DisplayNames(['en'], { type: 'language' });

      const languageName = regionNamesInEnglish.of(voice.language.toUpperCase().trim());

      if (languageName !== null && languageName !== undefined) {
        returnValue = returnValue + `Language: ${voice.language} -  ${languageName}`;
      }
      else {
        returnValue = returnValue + `Language: ${voice.language}`;
      }

      needsSeparator = true;
    }

    if (voice.labels.accent !== ApplicationConstants.defaultString) {
      if (needsSeparator) {
        returnValue = returnValue + '; ';
      }

      returnValue = returnValue + `Accent: ${voice.labels.accent}`;
      needsSeparator = true;
    }

    if (voice.labels.age !== ApplicationConstants.defaultString) {
      if (needsSeparator) {
        returnValue = returnValue + '; ';
      }

      returnValue = returnValue + `Age: ${voice.labels.age}`;
      needsSeparator = true;
    }

    if (voice.descriptive !== ApplicationConstants.defaultString) {
      if (needsSeparator) {
        returnValue = returnValue + '; ';
      }

      returnValue = returnValue + `Descriptive: ${voice.descriptive}`;
      needsSeparator = true;
    }

    if (voice.labels.gender !== ApplicationConstants.defaultString) {
      if (needsSeparator) {
        returnValue = returnValue + '; ';
      }

      returnValue = returnValue + `Gender: ${voice.labels.gender}`;
      needsSeparator = true;
    }

    if (voice.labels.useCase !== ApplicationConstants.defaultString) {
      if (needsSeparator) {
        returnValue = returnValue + '; ';
      }

      returnValue = returnValue + `Use Case: ${voice.labels.useCase}`;
      needsSeparator = true;
    }

    return returnValue;
  }

}
