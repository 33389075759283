import { Component, OnInit } from '@angular/core';
import { SecurityService } from '../_services/security.service';
import { RoutingHelperService } from '../_services/routing-helper.service';
import { MsalService } from '@azure/msal-angular';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  
  constructor(
    private routingHelper: RoutingHelperService,
    private authService: MsalService,
    public service: SecurityService) { }

  ngOnInit(): void {
    console.log('HomeComponent.ngOnInit()');
  }

  login() {
    this.authService.loginRedirect();
  }

  goToProjectList() {
    this.routingHelper.navigateTo('/project');
    this.service.ensureCurrentUserIsPopulated();
  }

  logoClick() {
    if (this.service.securityState !== null && this.service.securityState.activeAccount !== null) {
      this.goToProjectList();
    }
    else {
      this.login();
    }
  }
}