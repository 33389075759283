<div *ngIf="message" class="alert alert-primary" role="alert">{{message}}</div>


<!-- display for xs screens (aka. mobile) -->
<div>
  <ng-container *ngFor="let item of items; let i = index;">
    <ng-container *ngIf="showSectionHeader(item, i) === true">
      <div>
        <div class="badge text-bg-primary col-5 mt-2 ms-2 me-2 mb-3">
          {{item.sectionName}}
        </div>
      </div>
    </ng-container>

    <div class="pb-3">
      <div class="card" (click)="rowClicked($event, item)" (keyup)="rowClickedKeyboardEvent($event, item)"
        (keydown)="rowClickedKeyboardEvent($event, item)" (keypress)="rowClickedKeyboardEvent($event, item)"
        tabindex="0">
        <div class="card-header">
          <b>Slide #{{item.slideNumber | number}}</b>
        </div>
        <div class="card-body">
          <div class="card-title">
            <div class="row">
              <div class="col">
                <img [src]="item.slideScreenshotUrl" alt="slide image" class="img-thumbnail"
                  *ngIf="item.hasSlideScreenshot" />
              </div>
              <div class="col">
                Voice-over Text:
                <br />{{getSayText(item, 100)}}
              </div>
            </div>
          </div>
          <div class="card-text text-muted">
            Slide Text:
            <br />{{getSlideText(item, 100)}}
          </div>
        </div>
        <div class="card-footer text-body-secondary">
          <div class="row">
            <div class="col">Item Count: {{item.items.length}}</div>
            <div class="col">Status: {{GetVideoStatus(item)}}</div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>