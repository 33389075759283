<div>
    <div class="row" *ngIf="isLoading === true && item === null">
        <div class="col-12 text-center">
            <div class="spinner-border text-primary" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
    </div>
    <div class="row" *ngIf="isLoading === false && item === null">
        <div class="col-12 text-center">
            <div class="display-6">Sign up for Slide Speaker monthly or yearly</div>
        </div>


        <!-- test mode -->
        <div class="col-12 text-center"
            *ngIf="settingsService.settings !== null && (settingsService.settings.EnvironmentMode === 'test' || settingsService.settings.EnvironmentMode === 'Development')">
            <stripe-pricing-table id="pricingTableTest" pricing-table-id="prctbl_1PP2UKIjpem3CuNoMSfEGPGx"
                publishable-key="pk_test_0DWE3LU0OK1BqDRoNARu52ej00OOGDIeWW"
                [attr.client-reference-id]="clientReferenceIdForStripePricingTable"></stripe-pricing-table>
        </div>
        <!-- prod mode -->
        <div class="col-12 text-center"
            *ngIf="settingsService.settings !== null && settingsService.settings.EnvironmentMode === 'prod'">
            <stripe-pricing-table pricing-table-id="prctbl_1PPN3oIjpem3CuNoW1bOteCH" id="pricingTableProd"
                publishable-key="pk_live_YhwajInGBVClc94f9PwTSKnr008FRPVchj"
                [attr.client-reference-id]="clientReferenceIdForStripePricingTable">
            </stripe-pricing-table>
        </div>
        <ng-template
            *ngIf="settingsService.settings !== null && (settingsService.settings.EnvironmentMode !== 'prod' && settingsService.settings.EnvironmentMode !== 'test')">
            <!-- invalid mode -->
            <div class="col-12 text-center">
                '{{settingsService.settings.EnvironmentMode}}' is not a valid environment mode.
            </div>
        </ng-template>
        <ng-template *ngIf="settingsService.settings === null">
            <!-- invalid mode -->
            <div class="col-12 text-center">
                settings is null
            </div>
        </ng-template>

    </div>
    <div *ngIf="item !== null">
        <div class="col-12 text-center">
            <div class="display-6">You have an active subscription.</div>
        </div>
        <div class="col-12 text-center">
            <div class="lead">Your subscription id is {{item.subscriptionId}}</div>
        </div>
        <div class="col-12 text-center">

            <a *ngIf="settingsService.settings !== null && settingsService.settings.EnvironmentMode === 'prod'"
                href="https://billing.stripe.com/p/login/cN23e93GWd0CfQs4gg" target="_blank">View / Edit
                Subscription</a>
            <a *ngIf="settingsService.settings !== null && settingsService.settings.EnvironmentMode !== 'prod'"
                href="https://billing.stripe.com/p/login/test_fZe2c8b4j1BEe527ss" target="_blank">View / Edit
                Subscription</a>
        </div>
    </div>
    <div *ngIf="item !== null && securityService.isAdministrator === true">
        <app-read-only-field-view [parentForm]="theForm" controlName="ownerId" displayName="Owner Id" #ownerId></app-read-only-field-view>
        <app-read-only-field-view [parentForm]="theForm" controlName="subscriptionId" displayName="SubscriptionId" #subscriptionId></app-read-only-field-view>
        <app-read-only-field-view [parentForm]="theForm" controlName="productId" displayName="Product Id" #productId></app-read-only-field-view>
        <app-read-only-field-view [parentForm]="theForm" controlName="priceId" displayName="Price Id" #priceId></app-read-only-field-view>
        <app-read-only-field-view [parentForm]="theForm" controlName="planId" displayName="Plan Id" #planId></app-read-only-field-view>
        <app-read-only-field-view [parentForm]="theForm" controlName="subscriptionStartDate" displayName="Subscription Start Date" #subscriptionStartDate></app-read-only-field-view>
        <app-read-only-field-view [parentForm]="theForm" controlName="subscriptionEndDate" displayName="Subscription End Date" #subscriptionEndDate></app-read-only-field-view>
        <app-read-only-field-view [parentForm]="theForm" controlName="subscriptionStatus" displayName="Subscription Status" #subscriptionStatus></app-read-only-field-view>
        <app-read-only-field-view [parentForm]="theForm" controlName="customerId" displayName="Customer Id" #customerId></app-read-only-field-view>
        <app-read-only-field-view [parentForm]="theForm" controlName="customerEmail" displayName="Customer Email" #customerEmail></app-read-only-field-view>
        <app-read-only-field-view [parentForm]="theForm" controlName="customerOwnerId" displayName="Customer Owner Id" #customerOwnerId></app-read-only-field-view>
        <app-read-only-field-view [parentForm]="theForm" controlName="clientReferenceId" displayName="Client Reference Id" #clientReferenceId></app-read-only-field-view>
        <div class="col-12 text-center p-3" *ngIf="securityService.isAdministrator === true">
            <button class="btn btn-danger" (click)="delete()">Delete Subscription</button>
        </div>
    </div>
    <div class="col-12 text-center p-3">
        <button class="btn btn-secondary" (click)="load()">
            Refresh
        </button>
    </div>
</div>