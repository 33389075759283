<nav style="
    --bs-breadcrumb-divider: url(
      &#34;data:image/svg + xml,
      %3Csvgxmlns='http://www.w3.org/2000/svg'width='8'height='8'%3E%3Cpathd='M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z'fill='currentColor'/%3E%3C/svg%3E&#34;
    );
  " aria-label="breadcrumb">
  <ol class="breadcrumb">
    <li class="breadcrumb-item">
      <a routerLink="/project/project-list/" title="View all projects">Projects</a>
    </li>
    <li class="breadcrumb-item" *ngIf="project">
      <a routerLink="/project/project-details/{{project.ownerId}}/{{ project.id }}" title="View all slides">{{
        project.name
        }}</a>
    </li>
    <li class="breadcrumb-item active" aria-current="page">
      Slide {{ theModel.slideNumber | number}}
    </li>
  </ol>
  <div class="d-flex flex-row">
    <div class="pe-2">
      <button (click)="navigateToPreviousSlide()" [disabled]="!theForm.controls.previousSlideLink.enabled"
        class="btn btn-secondary next-prev-slide-button" data-bs-toggle="tooltip" data-bs-placement="top"
        title="Previous slide">
        <i class="fa-solid fa-arrow-left"></i>
        &nbsp;
        <span>Back</span>
      </button>
    </div>
    <div>
      <button (click)="navigateToNextSlide()" [disabled]="!theForm.controls.nextSlideLink.enabled"
        class="btn btn-secondary next-prev-slide-button" data-bs-toggle="tooltip" data-bs-placement="top"
        title="Next slide">
        <span>Next</span>
        &nbsp;
        <i class="fa-solid fa-arrow-right"></i>
      </button>
    </div>

  </div>
  <div class="row pt-1">
    <div class="col p-1">
      <button (click)="refresh()" class="btn btn-outline-primary" data-bs-toggle="tooltip" data-bs-placement="top"
        title="Refresh this page" id="refresh-button">
        refresh
      </button>
    </div>
    <div class="col p-1" *ngIf="theModel !== null && theModel.isVideoCurrent === true">
      <button class="btn btn-outline-secondary" data-bs-toggle="tooltip" data-bs-placement="top"
        (click)="queueVideoRender()" title="Video is ready. No refresh needed...but you can if you want."
        id="queue-video-render-button">
        update video
      </button>
    </div>
    <div class="col p-1" *ngIf="theModel !== null && theModel.isVideoCurrent === false">
      <button (click)="queueVideoRender()" class="btn btn-outline-secondary" data-bs-toggle="tooltip"
        data-bs-placement="top" title="Refresh video for this slide" id="queue-video-render-button">
        update video
      </button>
    </div>
    <div class="col p-1">
      <button (click)="showVideoDialog()" class="btn btn-outline-success" *ngIf="theModel.hasVideo === true"
        id="show-video-dialog-button" title="Click to view the video for this slide." data-bs-toggle="tooltip"
        data-bs-placement="top">
        <span class="spinner-border spinner-border-sm" aria-hidden="true" *ngIf="isLoadingVideo === true"></span>
        view video
      </button>
      <button class="btn btn-info btn-outline-dark" *ngIf="theModel.hasVideo === false" id="show-video-dialog-button"
        title="No video available. Click 'update video' to create one." data-bs-toggle="tooltip"
        data-bs-placement="top">
        view video
      </button>
    </div>
    <div class="col p-1">
      <button (click)="queueScreenshots()" class="btn btn-outline-info" data-bs-toggle="tooltip" data-bs-placement="top"
        title="Refresh screenshots for this slide" id="queue-screenshots-button">
        redo screenshots
      </button>
    </div>
  </div>
</nav>


<div class="form-horizontal">
  <h2>Slide {{theModel.slideNumber | number}}</h2>

  <div *ngIf="message" class="alert alert-primary" role="alert">{{message}}</div>

  <app-evaluation-mode-message></app-evaluation-mode-message>

  <app-project-job-status [ownerId]="project.ownerId" [projectId]="project.id"></app-project-job-status>

  <div *ngIf="theModel.isHidden === true" class="display-6">This slide is marked as hidden.</div>



  <div class="pb-3"
    *ngIf="theModel !== null && theModel.isHidden === false && theModel.slideNotes !== null && theModel.slideNotes !== ''">
    <div class="card">
      <div class="card-header">
        Slide Notes
      </div>
      <div class="card-body">
        <div class="card-title">{{theModel.slideNotes}}</div>
      </div>
    </div>
  </div>

  <div class="form-group">
    <form [formGroup]="theForm">
      <div class="row">
        <div class="col-3">
          <app-checkbox-field [parentForm]="theForm" controlName="startWithPause" [displayAsSwitch]="true" [isLarge]="true"
            displayName="Start Slide with 1 Second Pause"
            #startWithPause></app-checkbox-field>
        </div>
        <div class="col-3">
          <app-checkbox-field [parentForm]="theForm" controlName="endWithPause" [displayAsSwitch]="true" [isLarge]="true"
            displayName="End Slide with 1 Second Pause"
            #endWithPause></app-checkbox-field>
        </div>
      </div>
      <app-slide-item-list #slideItemListControl labelText="Items" [hideCreate]="true"
        [data]="projectSlideData"></app-slide-item-list>

    </form>
  </div>
</div>