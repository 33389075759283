export const environment = {
  production: false,
  // baseUrl: 'https://slidespeakerapi.benday.com',
  // baseUrl: 'https://slidespeakerapi-test.azurewebsites.net',
  baseUrl: 'https://localhost:5001',
  // baseUrl: 'https://api.slidespeaker.ai',
    msalConfig: {
    auth: {
      clientId: '1729a430-2dce-4145-a4d7-c798c575448b',
    }
  },
  apiConfig: {
    scopes: ['https://bendaycom.onmicrosoft.com/1729a430-2dce-4145-a4d7-c798c575448b/access_as_user'],
    uri: '/api/*'
  },
  b2cPolicies: {
    names: {
      signUpSignIn: "B2C_1_signupsignin",
      resetPassword: "b2c_1_reset",
      editProfile: "b2c_1_edit_profile"
    },
    authorities: {
      signUpSignIn: {
        authority: 'https://bendaycom.b2clogin.com/bendaycom.onmicrosoft.com/B2C_1_signupsignin'
      },
      resetPassword: {
        authority: 'https://bendaycom.b2clogin.com/bendaycom.onmicrosoft.com/b2c_1_reset'
      },
      editProfile: {
        authority: "https://bendaycom.b2clogin.com/bendaycom.onmicrosoft.com/b2c_1_edit_profile"
      }
    },
    authorityDomain: "bendaycom.b2clogin.com"
  }
};
