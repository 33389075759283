import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ApplicationConstants } from 'src/app/_common/application-constants';
import { UserSubscription } from 'src/app/_models/user-subscription';
import { ApplicationSettingsService } from 'src/app/_services/application-settings.service';
import { RoutingHelperService } from 'src/app/_services/routing-helper.service';
import { SecurityService } from 'src/app/_services/security.service';
import { UserSubscriptionService } from 'src/app/_services/user-subscription.service';

@Component({
  selector: 'app-subscription-status',
  templateUrl: './subscription-status.component.html',
  styleUrl: './subscription-status.component.css'
})
export class SubscriptionStatusComponent implements OnInit {

  public item: UserSubscription | null = null;
  public isLoading = false;
  public clientReferenceIdForStripePricingTable = '';

  theForm = this.fb.nonNullable.group({
    ownerId: [{ value: ApplicationConstants.defaultString, disabled: true }],
    subscriptionId: [{ value: ApplicationConstants.defaultString, disabled: true }],
    productId: [{ value: ApplicationConstants.defaultString, disabled: true }],
    priceId: [{ value: ApplicationConstants.defaultString, disabled: true }],
    planId: [{ value: ApplicationConstants.defaultString, disabled: true }],
    subscriptionStartDate: [{ value: ApplicationConstants.defaultDate, disabled: true}],
    subscriptionEndDate: [{ value: ApplicationConstants.defaultDate, disabled: true}],
    subscriptionStatus: [{ value: ApplicationConstants.defaultString, disabled: true}],
    customerId: [{ value: ApplicationConstants.defaultString, disabled: true}],
    customerEmail: [{ value: ApplicationConstants.defaultString, disabled: true}],
    customerOwnerId: [{ value: ApplicationConstants.defaultString, disabled: true}],
    clientReferenceId: [{ value: ApplicationConstants.defaultString, disabled: true}]    
  });

  constructor(
    public settingsService: ApplicationSettingsService,
    private service: UserSubscriptionService,
    public securityService: SecurityService,
    private routingHelper: RoutingHelperService,
    private fb: FormBuilder
  ) { }

  ngOnInit(): void {
    this.load();
  }

  clientReferenceId(): string {
    if (this.securityService.currentUser) {
      const returnValue = `${this.securityService.currentUser.ownerId.replace(".", "----")}__${this.securityService.currentUser.userId}`;

      console.log('clientReferenceId:', returnValue);
      console.log('clientReferenceId length:', returnValue.length);


      return returnValue;
    }
    else {
      console.log('clientReferenceId:', '');

      return '';
    }
  }

  createSubscription() {
    console.log('SubscriptionStatusComponent createSubscription');

    if (!this.securityService.currentUser) {
      console.error('SubscriptionStatusComponent: user is not authenticated.');
      return;
    }
    else {
      console.log('SubscriptionStatusComponent: user is authenticated.');

      const ownerId = this.securityService.currentUser.ownerId;
      if (ownerId === '') {
        console.error('SubscriptionStatusComponent: ownerId is required.');
        return;
      }
      const clientRefId = `${ownerId.replace(".", "----")}__${this.securityService.currentUser.userId}`;
      const urlEncodedClientRefId = encodeURIComponent(clientRefId);

      // const emailAddress = this.securityService.currentUser.emailAddress;

      // const stripeUrl = 
      //   `https://buy.stripe.com/test_cN2bJw8FV8fA1qw8wy?client_reference_id=${clientRefId}&prefilled_email=${emailAddress}`;

      const stripeUrl = `https://buy.stripe.com/test_cN2bJw8FV8fA1qw8wy?client_reference_id=${urlEncodedClientRefId}`;

      console.log('SubscriptionStatusComponent createSubscription stripeUrl:', stripeUrl);

      window.location.href = stripeUrl;
    }
  }

  load() {
    this.settingsService.ensureSettingsAreLoaded();

    if (this.settingsService.settings === null) {
      console.error('** SubscriptionStatusComponent: settings are null.');
    }
    else {
      console.log('** SubscriptionStatusComponent: settings are loaded:', this.settingsService.settings);

      if (this.settingsService.settings.EnvironmentMode === 'prod') {
        console.log('** SubscriptionStatusComponent: EnvironmentMode is prod.');
      }
      else {
        console.log('** SubscriptionStatusComponent: EnvironmentMode is not prod.');
      }
    }

    this.isLoading = true;

    const ownerId = this.securityService.currentUser?.ownerId;
    if (ownerId === null || ownerId === undefined || ownerId === '') {
      console.error('SubscriptionStatusComponent: ownerId is required.');

      this.securityService.ensureCurrentUserIsPopulated();

      // wait 2 seconds and try again
      setTimeout(() => {
        this.load();
      }, 2000);

      return;
    }

    this.service.getByOwnerId(ownerId).subscribe({
      next: data => {
        console.log('SubscriptionStatusComponent getByOwnerId data:', data);
        this.item = data;
        this.clientReferenceIdForStripePricingTable = this.clientReferenceId();
        this.isLoading = false;
        this.afterLoad();
      },
      error: error => {
        console.error('SubscriptionStatusComponent getByOwnerId error:', error);
        this.isLoading = false;
      }
    });
  }
  afterLoad() {
    if (this.item === null) {
      console.error('SubscriptionStatusComponent: item is required.');
      return;
    }

    this.theForm.patchValue({
      ownerId: this.item.ownerId,
      subscriptionId: this.item.id,
      productId: this.item.productId,
      priceId: this.item.priceId,
      planId: this.item.planId,
      subscriptionStartDate: this.item.subscriptionStartDate,
      subscriptionEndDate: this.item.subscriptionEndDate,
      subscriptionStatus: this.item.subscriptionStatus,
      customerId: this.item.customerId,
      customerEmail: this.item.customerEmail,
      customerOwnerId: this.item.customerOwnerId,
      clientReferenceId: this.item.clientReferenceId
    });
  }

  delete() {
    this.isLoading = true;

    if (this.item === null) {
      console.error('SubscriptionStatusComponent: item is required.');
      return;
    }
    else {
      this.service.deleteByOwnerId(this.item.ownerId, this.item.id).subscribe({
        next: data => {
          console.log('SubscriptionStatusComponent deleteByOwnerId data:', data);
          this.item = null;
          this.isLoading = false;
          this.load();
        },
        error: error => {
          console.error('SubscriptionStatusComponent deleteByOwnerId error:', error);
          this.isLoading = false;
        }
      });
    }
  }

  toDateString(input: Date | undefined): string {
    if (input === null || input === undefined) {
      return '';
    }
    else {
      return input.toDateString();
    }
  }
}
