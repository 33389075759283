import { AccountInfo } from '@azure/msal-browser';


export class SecurityStateInfo {
  title = 'Angular 16 - MSAL Angular v3 Sample';
  isIframe = false;
  loginDisplay = false;
  activeAccount: AccountInfo | null = null;
  accounts: AccountInfo[] = [];

  onLogoutRequested?: () => void;
  onLoginRequested?: () => void;

  requestLogout() {
    if (this.onLogoutRequested) {
      this.onLogoutRequested();
    }
  }

  requestLogin() {
    if (this.onLoginRequested) {
      this.onLoginRequested();
    }
  }
}
