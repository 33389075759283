<hr />
<ng-container [formGroup]="parentForm">
    <div class="row">
        <div class="mb-3 display-6">
            Slide {{slideNumber}}
            <br />
            {{item.value.title}}
        </div>
    </div>

    <div *ngFor="let bullet of item.value.bullets; let i = index">
        <div class="row">
            <h5>Bullet #{{i+1}}</h5>
        </div>
        <div class="row">
            <div class="col-3"><b>Text</b></div>
            <div class="col-9">{{bullet.text}}</div>
        </div>
        <div class="row">
            <div class="col-3 "><b>Voice over</b></div>
            <div class="col-9">{{bullet.voiceOver}}</div>
        </div>
    </div>

    <div class="row" *ngIf="item.value.hasCodeSample === false">
        <div class="col-auto"><i>(no code sample)</i></div>
    </div>

    <div *ngIf="item.value.hasCodeSample === true && item.value.codeSampleForTopic1 !== ''">
        <div class="row">
            <div class="col-auto"><b>Code Sample 1</b></div>
        </div>
        <div class="row">
            <div class="col-9">

                <pre
                class="bg-light p-3 border rounded"><code>{{item.value.codeSampleForTopic1.trimStart()}}</code></pre>
            </div>
        </div>
    </div>

    <div *ngIf="item.value.hasCodeSample === true && item.value.codeSampleForTopic2 !== ''">
        <div class="row">
            <div class="col-auto"><b>Code Sample 2</b></div>
        </div>
        <div class="row">
            <div class="col-9">

                <pre
                    class="bg-light p-3 border rounded"><code>{{item.value.codeSampleForTopic2.trimStart()}}</code></pre>
            </div>
        </div>
    </div>


</ng-container>