<nav style="
    --bs-breadcrumb-divider: url(
      &#34;data:image/svg + xml,
      %3Csvgxmlns='http://www.w3.org/2000/svg'width='8'height='8'%3E%3Cpathd='M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z'fill='currentColor'/%3E%3C/svg%3E&#34;
    );
  " aria-label="breadcrumb">
  <ol class="breadcrumb">
    <li class="breadcrumb-item">
      <a routerLink="/project/project-list/" 
      title="View all projects">Projects</a>
    </li>
    <li class="breadcrumb-item" *ngIf="theModel">
      <a routerLink="/project/project-details/{{theModel.ownerId}}/{{ theModel.id }}" 
      title="View all slides">{{
        theModel.name
        }}</a>
    </li>
  </ol>
</nav>

<form [formGroup]="theForm">
    <div *ngIf="message!==''">
        <div class="alert alert-primary" role="alert">
            {{message}}
        </div>
    </div>

    <h3>Currently Selected Voice</h3>
    
    <div class="row mb-3">
        <label for="selectedVoiceName" class="col-2 col-form-label">Voice Name:</label>
        <div class="col-4"><input readonly formControlName="selectedVoiceName" id="selectedVoiceName"
                class="form-control-plaintext" /></div>
    </div>
    <div class="row mb-3" *ngIf="voicesLoaded === true && theForm.controls.selectedVoiceId.errors?.['required']">
        <div class="col-5">
            <div class="alert alert-danger" role="alert">
                No voice selected. Please choose a voice below and click save.
            </div>
        </div>
    </div>

    <div class="btn-toolbar" *ngIf="hasChanges() === true">
        <button type="button" class="btn btn-primary mx-2" [disabled]="!theForm.valid" (click)="save()">Save</button>
        <button type="button" class="btn btn-secondary mx-2" (click)="cancel()">Cancel</button>
    </div>

    <h3>Available Voices</h3>

    <div class="container">
        
        <div class="row">
            <div class="col">
                <app-textbox-field [parentForm]="theForm" 
                    controlName="filterText"
                    displayName="Filter by text" #filterText ></app-textbox-field>
            </div>
            <div class="col">
                <app-textbox-field [parentForm]="theForm" 
                    controlName="filterLanguage"
                    displayName="Filter by language code (en, es, fr, pr, etc.)" #filterLanguage ></app-textbox-field>
            </div>
        </div>
        <div class="row">
            <div class="col">
                @if (theForm.controls.filterText.value === '' && theForm.controls.filterLanguage.value === '') {
                    Available voices: {{theVoicesAllValues.length}}
                }
                @else {
                    Filtered voices: {{theVoices.length}} of {{theVoicesAllValues.length}}
                }
            </div>              
        </div>
    </div>


    <div class="container">
        <div class="row p-3 bg-light" *ngIf="theVoices.length === 0">
            <div class="col-12 align-items-center text-center">(no voices available)</div>
        </div>
    </div>

    <div class="container">
        <ng-container *ngFor="let item of theVoices; let i = index">
            <div class="card">
                <div class="card-header bg-info text-white" 
                    *ngIf="item.voiceId === theForm.controls.selectedVoiceId.value">
                    <div class="badge text-bg-primary mt-2 me-2 mb-2">
                        Selected
                    </div>
                </div>
                <div class="card-body" [ngClass]='{"bg-light border border-2": item.voiceId === theForm.controls.selectedVoiceId.value}'>
                    <h5 class="card-title">{{item.voiceName}}</h5>
                    <h6 class="card-subtitle mb-2 text-muted">{{item.description}}</h6>
                    <p class="card-text">
                        {{getVoiceDescription(item)}}                        
                    </p>
                    <audio controls>
                        <source [src]="item.previewUrl" type="audio/mpeg">
                        Your browser does not support the audio element.
                    </audio>
                </div>
                <div class="card-footer" *ngIf="item.voiceId !== theForm.controls.selectedVoiceId.value">
                    <button class="btn btn-link" (click)="selectVoice(item)">select</button>
                </div>
                <div class="card-footer" *ngIf="item.voiceId === theForm.controls.selectedVoiceId.value && item.voiceId !== theModel?.voiceId">
                    <p class="text-danger">You are changing the voice for your project. The current voice is {{theForm.controls.selectedVoiceName.value}}.  Are you sure you want to change the voice?</p>
                    <button type="button" class="btn btn-primary mx-2" [disabled]="!theForm.valid" (click)="save()">Save</button>
                    <button type="button" class="btn btn-secondary mx-2" (click)="resetVoice()">Undo</button>
                </div>
                
            </div>
            
            <div class="spacer p-3"></div>
        </ng-container>
    </div>
</form>