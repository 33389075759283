<div>
    <div class="d-block">
        Bootstrap Debugger
    </div>
    <div class="d-block d-sm-none">
        Size: xs
    </div>
    <div class="d-none d-sm-block d-md-none">
        Size: sm
    </div>
    <div class="d-none d-md-block d-lg-none">
        Size: md
    </div>
    <div class="d-none d-lg-block d-xl-none">
        Size: lg
    </div>
    <div class="d-none d-xl-block d-xxl-none">
        Size: xl
    </div>
    <div class="d-none d-xxl-block">
        Size: xxl
    </div>
</div>