import { FormArray, FormBuilder, FormGroup } from "@angular/forms";
import { PronunciationGridRowItem } from "../pronunciation-grid-row/pronunciation-grid-row-item";

export class PronunciationGridFormArray extends FormArray<FormGroup<PronunciationGridRowItem>> {
    constructor(private fb: FormBuilder) {
        super([]);
    }

    addRow() {
        const row = this.fb.nonNullable.group<PronunciationGridRowItem>(
            new PronunciationGridRowItem()
        );

        this.controls.push(row);
    }

    removeRow(index: number) {
        this.removeAt(index);
    }
}