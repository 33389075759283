import { ModelBase } from './model-base';
import { ApplicationConstants } from '../_common/application-constants';
import { SlideItem } from './slide-item';


export class SlideData extends ModelBase {
  partitionKey: string;
  slideNumber: string;
  slideId: string;
  slideNotes: string;
  slideLayout: string;
  slideAnimationCount: number;
  slideTemplateAnimationCount: number;
  items: SlideItem[];
  itemsId: number;
  isHidden: boolean;
  sectionName: string;
  isReadyForVideo: boolean;
  isVideoCurrent: boolean;
  hasVideo: boolean;
  videoUrl: string;
  videoCreateDate: Date;
  hasScreenshots: boolean = false;
  nextSlideId: string = ApplicationConstants.defaultString;
  previousSlideId: string = ApplicationConstants.defaultString;
  hasSlideScreenshot: boolean = false;
  slideScreenshotDate: Date = new Date();
  slideScreenshotUrl: string = ApplicationConstants.defaultString;
  startWithPause: boolean = false;
  endWithPause: boolean = false;

  constructor() {
    super();
    this.partitionKey = ApplicationConstants.defaultString;
    this.slideNumber = ApplicationConstants.defaultString;
    this.slideId = ApplicationConstants.defaultString;
    this.slideNotes = ApplicationConstants.defaultString;
    this.slideLayout = ApplicationConstants.defaultString;
    this.slideAnimationCount = ApplicationConstants.defaultNumber;
    this.slideTemplateAnimationCount = ApplicationConstants.defaultNumber;
    this.items = [];
    this.itemsId = ApplicationConstants.defaultNumber;
    this.isHidden = false;
    this.sectionName = ApplicationConstants.defaultString;
    this.hasVideo = false;
    this.isReadyForVideo = false;
    this.isVideoCurrent = false;
    this.videoUrl = ApplicationConstants.defaultString;
    this.videoCreateDate = ApplicationConstants.defaultDate;
  }  
}


