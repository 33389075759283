import { Component, Input } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { SlideOutline } from 'src/app/_models/slide-outline';

@Component({
  selector: 'app-slide-outline-detail',
  templateUrl: './slide-outline-detail.component.html',
  styleUrl: './slide-outline-detail.component.css'
})
export class SlideOutlineDetailComponent {
  @Input()
  public item!: FormControl<SlideOutline>;

  @Input()
  public parentForm!: FormGroup;

  @Input()
  public slideNumber!: number;
}
 
