import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SlideData } from '../_models/slide-data';
import { catchError } from 'rxjs/operators';
import { CommonUtilities } from '../_common/common-utilities';
import { ProjectSlideData } from '../_models/project-slide-data';
import { VideoRenderRequest } from '../_models/video-render-request';
import { ProjectSlideDataMultiple } from '../_models/project-slide-data-multiple';

@Injectable({
  providedIn: 'root'
})
export class SlideDataService {

  constructor(
    private http: HttpClient) { }

  getList(): Observable<SlideData[]> {
    console.log(`Getting Slide data list from the server /api/slidedata.`);
    return this.http.get<SlideData[]>('/api/slidedata').pipe(
      catchError(err => CommonUtilities.handleHttpError<SlideData[]>(err))
    );
  }

  getById(id: number): Observable<SlideData | null> {
    console.log(`Getting Slide data id ${id} from the server /api/slidedata/${id}.`);
    return this.http.get<SlideData>(
      '/api/slidedata/' + id.toString()).pipe(
        catchError(err => CommonUtilities.handleHttpError<SlideData>(err))
      );
  }

  getByOwnerIdAndId(ownerId: string, projectId: string, slideDataId: string):
    Observable<ProjectSlideData | null> {
    console.log(`Getting Slide data id ${slideDataId} from the server /SlideData/GetById/${ownerId}/${projectId}/${slideDataId}.`);
    return this.http.get<ProjectSlideData>(
      `/api/slidedata/GetById/${ownerId}/${projectId}/${slideDataId}`).pipe(
        catchError(err => CommonUtilities.handleHttpError<ProjectSlideData | null>(err)));
  }

  queueVideoRender(ownerId: string, projectId: string, slideDataId: string): Observable<VideoRenderRequest> {
    console.log(`Queuing video render for ${slideDataId}...`);

    const request = new VideoRenderRequest();
    request.ownerId = ownerId;
    request.projectId = projectId;
    request.slideDataId = slideDataId;

    return this.http.post<VideoRenderRequest>(
      `/api/slidedata/rendervideo/${ownerId}`, request).pipe(
        catchError(err => CommonUtilities.handleHttpError<VideoRenderRequest>(err)));
  }

  markAllAsCurrent(ownerId: string, projectId: string) {
    return this.http.post(
      `/api/slidedata/markallcurrent/${ownerId}/${projectId}`, new Object()).pipe(
        catchError(err => CommonUtilities.handleHttpError<VideoRenderRequest>(err)));
  }


  getByProject(ownerId: string, projectId: string): Observable<ProjectSlideDataMultiple> {
    console.log(`Getting slide data for owner id ${ownerId} and project id ${projectId} from the server /project/GetByProject/${ownerId}/${projectId}.`);
    return this.http.get<ProjectSlideDataMultiple>(
      `/api/slidedata/GetByProject/${ownerId}/${projectId}`).pipe(
        catchError(err => CommonUtilities.handleHttpError<ProjectSlideDataMultiple>(err))
      );
  }

  deleteById(id: number): Observable<SlideData | null> {
    console.log(`Deleting Slide data id ${id} from the server /api/slidedata/${id}.`);
    return this.http.delete<SlideData>('/api/slidedata/' + id.toString()).pipe(
      catchError(err => CommonUtilities.handleHttpError<SlideData>(err))
    );
  }

  save(saveThis: SlideData): Observable<SlideData> {
    console.log(`Saving Slide data to the server /api/slidedata.`);
    return this.http.post<SlideData>('/api/slidedata', saveThis).pipe(
      catchError(err => CommonUtilities.handleHttpError<SlideData>(err))
    );
  }

  updatePauses(
    ownerId: string,
    projectId: string,
    slideDataId: string,
    startWithPause: boolean,
    endWithPause: boolean):
    Observable<SlideData> {
      
      const params = new HttpParams()
      .set('startWithPause', startWithPause.toString())
      .set('endWithPause', endWithPause.toString());
  
    return this.http.post<SlideData>(
      `/api/slidedata/updatepauses/${ownerId}/${projectId}/${slideDataId}`, 
      null, 
      { params }
    ).pipe(
      catchError(err => CommonUtilities.handleHttpError<SlideData>(err))
    );
  }

}
