import { Component, OnInit } from '@angular/core';
import { RoutingHelperService } from 'src/app/_services/routing-helper.service';
import { SecurityService } from 'src/app/_services/security.service';

@Component({
  selector: 'app-subscription-thanks',
  templateUrl: './subscription-thanks.component.html',
  styleUrl: './subscription-thanks.component.css'
})
export class SubscriptionThanksComponent implements OnInit {

  constructor(
    public securityService: SecurityService,
    private routingHelper: RoutingHelperService,
  ) { }

  ngOnInit(): void {
    if (!this.securityService.currentUser) {
      console.error('SubscriptionThanksComponent: user is not authenticated.');
      // this.routingHelper.navigateTo('/');
    }
    else {
      console.log('SubscriptionThanksComponent: user is authenticated.');
      // this.routingHelper.navigateTo(`/subscription-status/${this.securityService.currentUser.ownerId}`);
    }

    this.securityService.ensureCurrentUserIsPopulated();
  }
}
