
export class SayTextUpdate {
  public ownerId: string = '';
  public projectId: string = '';
  public slideDataId: string = '';
  public slideItemId: string = '';
  public slideItemNumber: number = -1;
  public sayText: string = '';
  public returnValue: string = '';
  public refresh: boolean = false;
}


