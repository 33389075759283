<nav style="
    --bs-breadcrumb-divider: url(
      &#34;data:image/svg + xml,
      %3Csvgxmlns='http://www.w3.org/2000/svg'width='8'height='8'%3E%3Cpathd='M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z'fill='currentColor'/%3E%3C/svg%3E&#34;
    );
  " aria-label="breadcrumb">
    <ol class="breadcrumb">
        <li class="breadcrumb-item">
            <a routerLink="/presentation-outline/list/{{theModel.ownerId}}">Outlines</a>
        </li>
        <li class="breadcrumb-item" *ngIf="theModel">
            <a routerLink="/presentation-outline/detail/{{theModel.ownerId}}/{{ theModel.id }}">{{
                theModel.title
                }}</a>
        </li>
    </ol>
</nav>

<div>
    <a (click)="refresh()" class="actionLink">Refresh</a>
</div>

<div *ngIf="message!==''">
    <div class="alert alert-primary" role="alert">
        {{message}}
    </div>
</div>

<ng-template [ngIf]="isLoading === true">
    <div class="spinner-border" role="status">
        <span class="visually-hidden">Loading...</span>
    </div>
</ng-template>

<ng-template [ngIf]="isLoaded === true">

    <div class="form-horizontal">
        <h2>Presentation Outline: {{theModel.title}}</h2>

        <div *ngIf="message" class="alert alert-primary" role="alert">
            {{ message }}
        </div>

        <div class="form-group">
            <form [formGroup]="theForm">

                <app-textbox-field [parentForm]="theForm" controlName="title" displayName="title"
                    #title></app-textbox-field>

                <div class="row mb-3" *ngIf="theModel.hasPptx === true">
                    <div class="col-2"><button (click)="createPptx()" class="btn btn-info">Regenerate PPTX File</button></div>
                    <div class="col-6"><a href="{{theForm.controls.pptxUrl.value}}" target="_blank">(slides)</a></div>                    
                </div>

                <div class="row mb-3" *ngIf="theModel.hasPptx === false">
                    <div class="col-2"><button (click)="createPptx()" class="btn btn-info">Generate PPTX File</button></div>
                    <div class="col-6">PPTX file has not been generated.</div>                    
                </div>

                <div class="row mb-3" *ngIf="theModel.hasProject === true">
                    <div class="col-2">
                        <button (click)="createProject()" class="btn btn-info">Recreate Project</button>
                    </div>
                    <div class="col-6">
                        <a routerLink="/project/project-details/{{theModel.ownerId}}/{{theModel.projectId}}">Project
                            Details</a>
                    </div>
                </div>

                <div class="row mb-3" *ngIf="theModel.hasProject === false">
                    <div class="col-2">
                        <button (click)="createProject()" class="btn btn-info">Create Project</button>
                    </div>
                    <div class="col-4">
                        If you like this outline, you can create a project from it.
                    </div>                    
                </div>

                <ng-template [ngIf]="theModel.isComparison === true">
                    <app-read-only-field-view [parentForm]="theForm" controlName="topic1Name" displayName="Topic 1 Name"
                        #topic1Name></app-read-only-field-view>

                    <app-read-only-field-view [parentForm]="theForm" controlName="topic2Name" displayName="Topic 2 Name"
                        #topic2Name></app-read-only-field-view>
                </ng-template>


                <ng-container formArrayName="slides">
                    <div *ngFor="let slide of theForm.controls.slides.controls; let i = index" [formGroupName]="i">
                        <app-slide-outline-detail [slideNumber]="i + 1" [parentForm]="theForm"
                            [item]="slide"></app-slide-outline-detail>
                    </div>
                </ng-container>


                <div class="btn-toolbar mb-3" role="toolbar">
                    <button type="button" (click)="save()" class="btn btn-primary mx-2" [disabled]="!theForm.valid">
                        Save
                    </button>
                    <button type="button" class="btn btn-secondary mx-2" (click)="cancel()">
                        Cancel
                    </button>
                    <button type="button" class="btn btn-danger mx-2" (click)="delete()">
                        Delete
                    </button>
                </div>
            </form>
        </div>
    </div>

</ng-template>