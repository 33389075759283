import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ApplicationError } from 'src/app/_common/application-error';
import { KeyValuePair } from 'src/app/_models/key-value-pair';
import { Lookup } from 'src/app/_models/lookup';
import { LookupService } from 'src/app/_services/lookup.service';

@Component({
  selector: 'app-lookup-dropdown-list',
  templateUrl: './lookup-dropdown-list.component.html',
  styleUrls: ['./lookup-dropdown-list.component.css']
})
export class LookupDropdownListComponent implements OnInit {
  @Input()
  parentForm!: FormGroup;

  @Input()
  controlName!: string;

  @Input()
  displayName = '(displayName not set)';

  @Input() autoPopulateLookups = true;

  @Input() lookupTypeName!: string;

  @Output()
  selectedValueChanged = new EventEmitter();

  public availableValues: KeyValuePair[] = [];

  constructor(private lookupService: LookupService) {
   
  }

  ngOnInit(): void {
    if (this.autoPopulateLookups === true) {
      this.populateAvailableLookups();
    }
  }

  public populateAvailableLookups() {
    if (this.lookupTypeName === null ||
      this.lookupTypeName === undefined ||
      this.lookupTypeName === '') {
      console.error('Lookup type name is not set.');
    } else {
      this.lookupService.getByType(this.lookupTypeName).subscribe({
        next: (results: Lookup[]) => {
          if (results === null) {
            this.availableValues = [];
          }
          else {
            const values = results.map((item) => {
              const temp = new KeyValuePair();

              temp.key = item.lookupKey;
              temp.value = item.lookupValue;

              return temp;
            });
            this.availableValues = values;
          }
        },
        error: (error: ApplicationError) => {
          console.error(error.friendlyMessage);
        }
      });
    }
  }

  public addAvailableValue(key: string, value: string) {
    const temp = new KeyValuePair();

    temp.key = value;
    temp.value = value;

    this.availableValues.push(temp);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  selectionChanged(event: Event) {
    this.selectedValueChanged?.emit(this.parentForm.get(this.controlName)?.value);
  }
}
