/* eslint-disable @typescript-eslint/no-explicit-any */
import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { CommonUtilities } from '../_common/common-utilities';
import { JobQueueRequest } from '../_models/job-queue-request';
import { QueueStatusInfo } from '../_models/queue-status-info';

@Injectable({
  providedIn: 'root'
})
export class JobQueueService {
  
  constructor(
    private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string) { }

  peek(): Observable<QueueStatusInfo> {
    return this.http.get<QueueStatusInfo>(`/api/jobqueue/peek`).pipe(
      catchError(err => CommonUtilities.handleHttpError<QueueStatusInfo>(err))
    );
  }

  clear(): Observable<any> {
    return this.http.delete(`/api/jobqueue/clear`).pipe(
      catchError(err => CommonUtilities.handleHttpError<any>(err))
    );
  }

  restartService(): Observable<any> {
    return this.http.delete(`/api/service/restart`).pipe(
      catchError(err => CommonUtilities.handleHttpError<any>(err))
    );
  }


  quitService(): Observable<any> {
    return this.http.delete(`/api/service/quit`).pipe(
      catchError(err => CommonUtilities.handleHttpError<any>(err))
    );
  }

  queueParseText(ownerId: string, projectId: string, skipScreenshots: boolean, start: number, end: number): Observable<JobQueueRequest> {
    return this.queueJob('parsetext', skipScreenshots, start, end, ownerId, projectId);
  }

  queueScreenshots(ownerId: string, projectId: string) : Observable<JobQueueRequest> {
    const url = `/api/jobqueue/screenshot/${ownerId}/${projectId}`;

    return this.http.get<JobQueueRequest>(url).pipe(
      catchError(err => CommonUtilities.handleHttpError<JobQueueRequest>(err))
    );
  }

  queuePerSlideScreenshots(ownerId: string, projectId: string) : Observable<JobQueueRequest> {
    const url = `/api/jobqueue/screenshotperslide/${ownerId}/${projectId}`;

    return this.http.get<JobQueueRequest>(url).pipe(
      catchError(err => CommonUtilities.handleHttpError<JobQueueRequest>(err))
    );
  }

  queueScreenshotsOneSlide(ownerId: string, projectId: string, slideNumber: number): Observable<JobQueueRequest> {
    const url = `/api/jobqueue/screenshot/${ownerId}/${projectId}/${slideNumber}`;

    return this.http.get<JobQueueRequest>(url).pipe(
      catchError(err => CommonUtilities.handleHttpError<JobQueueRequest>(err))
    );
  }

  private queueJob(operationName: string, skipScreenshots: boolean, start: number, end: number, ownerId: string, projectId: string) {
    let queryStringParameters = new HttpParams();

    queryStringParameters = queryStringParameters.append('skipScreenshots', skipScreenshots.toString());
 
    queryStringParameters = queryStringParameters.append('start', start.toString());
    queryStringParameters = queryStringParameters.append('end', end.toString());
  
    const url = `/api/jobqueue/${operationName}/${ownerId}/${projectId}`;

    return this.http.get<JobQueueRequest>(url, {
      params: queryStringParameters
    }).pipe(
      catchError(err => CommonUtilities.handleHttpError<JobQueueRequest>(err))
    );
  }
}