import { Component, OnInit } from '@angular/core';
import { ApplicationConstants } from 'src/app/_common/application-constants';
import { ApplicationError } from 'src/app/_common/application-error';
import { QueueStatusInfo } from 'src/app/_models/queue-status-info';
import { JobQueueService } from 'src/app/_services/job-queue.service';
import { RoutingHelperService } from 'src/app/_services/routing-helper.service';

@Component({
  selector: 'app-job-queue-details',
  templateUrl: './job-queue-details.component.html',
  styleUrl: './job-queue-details.component.css'
})
export class JobQueueDetailsComponent implements OnInit {

  public message: string = ApplicationConstants.emptyMessage;
  public items: QueueStatusInfo = new QueueStatusInfo();

  constructor(private service: JobQueueService,
    private routingHelper: RoutingHelperService) { }

  ngOnInit() {
    this.load();
  }

  private load() {
    this.service.peek().subscribe({
      next: (data: QueueStatusInfo) => {
        this.items = data;

        if (data === null) {
          this.message = ApplicationConstants.noDataMessage;
        } else {
          this.message = ApplicationConstants.emptyMessage;
        }
      },
      error: (error: ApplicationError) => this.message = error.friendlyMessage
    });
  }

  clear() {
    this.service.clear().subscribe({
      next: () => this.load(),
      error: (error: ApplicationError) => this.message = error.friendlyMessage
    });
  }

  quitService() {
    this.service.quitService().subscribe({
      next: () => this.load(),
      error: (error: ApplicationError) => this.message = error.friendlyMessage
    });
  }

  restartService() {
    this.service.restartService().subscribe({
      next: () => this.load(),
      error: (error: ApplicationError) => this.message = error.friendlyMessage
    });
  }

  refresh() {
    this.load();
  }
}
