/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Input, OnInit } from '@angular/core';
// import { timer, switchMap } from 'rxjs';
import { SecurityService } from '../_services/security.service';
import { UserData } from '../_models/user-data';
import { SecurityStateInfo } from '../security-state-info';
import { Router } from '@angular/router';
// import { timer, switchMap } from 'rxjs';
// import { RequiredValidator } from '@angular/forms';

@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.css']
})
export class NavMenuComponent implements OnInit {

  @Input()
  securityState: SecurityStateInfo = new SecurityStateInfo();

  showMenu = false;

  isExpanded = false;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  checkForUserInfoSubscription: any;
  public userInfo: UserData | null = null;

  constructor(
    public service: SecurityService,
    private router: Router
  ) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    router.events.subscribe(event => {
      if (router.url === '/') {
        this.showMenu = false;
      }
      else {
        this.showMenu = true;
      }
    });
  }

  ngOnInit() {
    console.log('nav-menu ngOnInit');    
  }

  handleKeyUp($event: KeyboardEvent) {
    if ($event.key === 'Enter') {
      this.toggle();
    }
    else if ($event.key === 'Escape') {
      this.collapse();
    }
  }

  collapse() {
    this.isExpanded = false;
  }

  toggle() {
    this.isExpanded = !this.isExpanded;
  }

  requestLogout() {
    console.log('logout clicked');
    this.securityState.requestLogout();
  }

  requestLogin() {
    console.log('login clicked');
    this.securityState.requestLogin();
  }
}
