
export class ProtectedResourceUtility {
  static populateProtectedResourceMap(map: Map<string, Array<string>>, scopes: string[], baseUrl: string) {
    this.addProtectedResourceToMap(map, baseUrl, scopes, "/api/security/user");
    this.addProtectedResourceToMap(map, baseUrl, scopes, "/api/security/isloggedin");
    this.addProtectedResourceToMap(map, baseUrl, scopes, "/api/security/claims");
    this.addProtectedResourceToMap(map, baseUrl, scopes, "/api/project");
    this.addProtectedResourceToMap(map, baseUrl, scopes, "/api/project/*");
    this.addProtectedResourceToMap(map, baseUrl, scopes, "/api/slidedata/*");
    this.addProtectedResourceToMap(map, baseUrl, scopes, "/api/SlideData/*");
    this.addProtectedResourceToMap(map, baseUrl, scopes, "/api/upload/*");
    this.addProtectedResourceToMap(map, baseUrl, scopes, "/api/securitysummary/*");
    this.addProtectedResourceToMap(map, baseUrl, scopes, "/api/voice/*");
    this.addProtectedResourceToMap(map, baseUrl, scopes, "/api/jobqueue/*");
    this.addProtectedResourceToMap(map, baseUrl, scopes, "/api/projectjob/*");
    this.addProtectedResourceToMap(map, baseUrl, scopes, "/api/usersubscription/*");
    this.addProtectedResourceToMap(map, baseUrl, scopes, "/api/outline/*");
  }

  static addProtectedResourceToMap(map: Map<string, Array<string>>, baseUrl: string, scopes: string[], protectedUrl: string) {
    const fullyQualifiedProtectedUrl = `${baseUrl}${protectedUrl}`;

    console.log(`ProtectedResourceUtility.addProtectedResourceToMap: ${fullyQualifiedProtectedUrl} for ${scopes}`);
    map.set(protectedUrl, scopes);
  }
}
