import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ApplicationConstants } from 'src/app/_common/application-constants';
import { ApplicationError } from 'src/app/_common/application-error';
import { CommonUtilities } from 'src/app/_common/common-utilities';
import { CreateOutlineRequest } from 'src/app/_models/create-outline-request';
import { PresentationOutline } from 'src/app/_models/presentation-outline';
import { SlideOutline } from 'src/app/_models/slide-outline';
import { PresentationOutlineService } from 'src/app/_services/presentation-outline.service';
import { RoutingHelperService } from 'src/app/_services/routing-helper.service';
import { SecurityService } from 'src/app/_services/security.service';

@Component({
  selector: 'app-presentation-outline-create',
  templateUrl: './presentation-outline-create.component.html',
  styleUrl: './presentation-outline-create.component.css'
})
export class PresentationOutlineCreateComponent implements OnInit {
  public theModel: CreateOutlineRequest;
  public message: string = ApplicationConstants.emptyMessage;
  public theId: string = ApplicationConstants.emptyMessage;
  public ownerId: string = ApplicationConstants.emptyMessage;
  
  theForm = this.fb.nonNullable.group({
    topicText: [ApplicationConstants.defaultString, Validators.required],
    approximateSlideCount: [ApplicationConstants.defaultNumber, Validators.required],
    isComparison: [false]
  });

  constructor(private service: PresentationOutlineService,
    private routingHelper: RoutingHelperService,
    public securityService: SecurityService,
    private fb: FormBuilder) {
    this.theModel = new CreateOutlineRequest();
  }

  ngOnInit(): void {
    this.ownerId = this.routingHelper.getValue('ownerId');
    
    if (
      this.ownerId !== '' && this.ownerId !== '0') {
      
    }
    else {
      this.message = 'owner id is required';
    }
  }

  
  save() {
    if (this.theForm.invalid) {
      return;
    }

    this.theModel.topicText = this.theForm.controls.topicText.value;
    this.theModel.approximateSlideCount = this.theForm.controls.approximateSlideCount.value;
    this.theModel.isComparison = this.theForm.controls.isComparison.value;

 this.service.create(this.ownerId, this.theModel).subscribe({
       next: (result) => {
          this.message = 'Outline created successfully';
          this.routingHelper.navigateTo('presentation-outline/list/' + this.ownerId);
       },
       error: (error: ApplicationError) => {
         console.error(error);
         this.message = error.message;
       }
     });
 

  }

  delete() {

  }

  cancel() {
    this.routingHelper.navigateTo('presentation-outline/list/' + this.ownerId);
  }

  
}
