import { MsalInterceptorConfiguration, MsalGuardConfiguration } from "@azure/msal-angular";
import { InteractionType, LogLevel, IPublicClientApplication, PublicClientApplication, BrowserCacheLocation } from "@azure/msal-browser";
import { environment } from "src/environments/environment";
import { getBaseUrl } from "src/main";
import { ProtectedResourceUtility } from "./protected-resource-utility";

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
    const protectedResourceMap = new Map<string, Array<string>>();
  
    let baseUrl = getBaseUrl();
  
    if (baseUrl.endsWith('/')) {
      baseUrl = baseUrl.substring(0, baseUrl.length - 1);
    }
  
    console.log('MSALInterceptorConfigFactory setting protected resources...');
  
    //  protectedResourceMap.set(getBaseUrl(), environment.apiConfig.scopes);
    // protectedResourceMap.set(serviceUrl, environment.apiConfig.scopes);
    // protectedResourceMap.set('/api', environment.apiConfig.scopes);
  
    ProtectedResourceUtility.populateProtectedResourceMap(
      protectedResourceMap, 
      environment.apiConfig.scopes, 
      baseUrl);
  
    return {
      interactionType: InteractionType.Redirect,
      protectedResourceMap
    };
  }

  export function MSALGuardConfigFactory(): MsalGuardConfiguration {
    const temp = {
      interactionType: InteractionType.Redirect,
      authRequest: {
        scopes: [...environment.apiConfig.scopes],
      },
      loginFailedRoute: '/login-failed'
    };
  
    console.log('MSALGuardConfigFactory', JSON.stringify(temp));
  
    return {
      interactionType: InteractionType.Redirect,
      authRequest: {
        scopes: [...environment.apiConfig.scopes],
      },
      loginFailedRoute: '/login-failed'
    };
  }

  export function loggerCallback(logLevel: LogLevel, message: string) {
    console.log(message);
  }
  
  export function MSALInstanceFactory(): IPublicClientApplication {
    const temp = new PublicClientApplication({
      auth: {
        clientId: environment.msalConfig.auth.clientId,
        authority: environment.b2cPolicies.authorities.signUpSignIn.authority,
        redirectUri: '/',
        postLogoutRedirectUri: '/',
        knownAuthorities: [environment.b2cPolicies.authorityDomain]      
      },
      cache: {
        cacheLocation: BrowserCacheLocation.LocalStorage,
      },
      system: {
        allowNativeBroker: false, // Disables WAM Broker
        loggerOptions: {
          loggerCallback,
          logLevel: LogLevel.Info,
          piiLoggingEnabled: false
        }
      }
    });
  
    // temp.addEventCallback((message) => {
    //   console.log('*** MSALInstanceFactory.addEventCallback', message);
    // }
  
    // const originalFunction = temp.loginRedirect;
  
    // // replace the loginRedirect function with a version that doesn't require a redirectUri
    // temp.loginRedirect = function (request: RedirectRequest) {
    //   console.log('*** MSALInstanceFactory.loginRedirect', request);
    //   return originalFunction.call(this, request);
    // };
  
    return temp;
  }