import { ModelBase } from './model-base';
import { ApplicationConstants } from '../_common/application-constants';
import { CustomPronunciation } from './custom-pronunciation';

export class Project extends ModelBase {
  name: string;
  description: string;
  hasVideo: boolean;
  isVideoCurrent: boolean;
  videoUrl: string;
  videoCreateDate: Date;
  voiceId: string;
  voiceName: string;
  customPronunciations: CustomPronunciation[] = [];

  constructor() {
    super();
    this.ownerId = ApplicationConstants.defaultString;
    this.name = ApplicationConstants.defaultString;
    this.description = ApplicationConstants.defaultString;
    this.hasVideo = false;
    this.isVideoCurrent = false;
    this.videoUrl = ApplicationConstants.defaultString;
    this.videoCreateDate = ApplicationConstants.defaultDate;
    this.voiceId = ApplicationConstants.defaultString;
    this.voiceName = ApplicationConstants.defaultString;
  }
}
