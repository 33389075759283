import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ApplicationConstants } from 'src/app/_common/application-constants';
import { PresentationOutline } from 'src/app/_models/presentation-outline';
import { PresentationOutlineService } from 'src/app/_services/presentation-outline.service';
import { RoutingHelperService } from 'src/app/_services/routing-helper.service';
import { SecurityService } from 'src/app/_services/security.service';

@Component({
  selector: 'app-presentation-outline-list',
  templateUrl: './presentation-outline-list.component.html',
  styleUrl: './presentation-outline-list.component.css'
})
export class PresentationOutlineListComponent implements OnInit {

  public items: PresentationOutline[] = [];
  public message: string = ApplicationConstants.emptyMessage;
  public isLoading: boolean = false;
  public isLoaded: boolean = false;

  // theForm = this.fb.nonNullable.group({
  //   ownerId: [{ value: ApplicationConstants.defaultString, disabled: true }],
  //   title: [ApplicationConstants.defaultString],
  //   status: [ApplicationConstants.defaultString],
  //   statusMessage: [ApplicationConstants.defaultString],
  //   statusMessageDetail: [ApplicationConstants.defaultString],
  //   createdDate: [ApplicationConstants.defaultDate],
  //   completedDate: [ApplicationConstants.defaultDate],
  //   templateName: [ApplicationConstants.defaultString],
  //   promptText: [ApplicationConstants.defaultString],
  //   requestedSlideCount: [ApplicationConstants.defaultNumber],
  //   topic1Name: [ApplicationConstants.defaultString],
  //   topic2Name: [ApplicationConstants.defaultString],
  //   isComparison: [false]
  // });

  // theForm = this.fb.nonNullable.group({
  //   outlines: this.fb.nonNullable.array<PresentationOutline>([])
  // });

  constructor(private service: PresentationOutlineService,
    private routingHelper: RoutingHelperService,
    public securityService: SecurityService,
    private fb: FormBuilder) {



  }

  ngOnInit(): void {
    // Initialization logic here
    let ownerId = this.routingHelper.getOwnerId();

      if (ownerId == '' || ownerId == null || ownerId == undefined) {
        throw new Error('Owner Id is required');
      }

      this.load(ownerId);
        
  }


  private load(ownerId: string) {
    this.isLoading = true;
    this.isLoaded = false;

    this.service.getListByOwnerId(ownerId).subscribe({
      next: (result) => {
        this.items = result;
        this.isLoading = false;
        this.isLoaded = true;
        this.afterLoad();
      },
      error: (error) => {
        this.isLoading = false;
        this.isLoaded = true;
        console.error(error);
        this.message = error.message;
      }
    });
  }

  rowClicked(event: MouseEvent, item: PresentationOutline) {
    const url = `/presentation-outline/detail/${item.ownerId}/${item.id}`;

    console.log('Navigating to ' + url);

    this.routingHelper.navigateTo(url);
  }

  afterLoad() {
    console.log('Loaded ' + this.items.length + ' items');
    // this.theForm.controls.outlines.setValue(this.items);
  }

  createNew() {
    let ownerId = this.routingHelper.getOwnerId();
    if (ownerId == '' || ownerId == null || ownerId == undefined) {
     
    }
    else 
    {
      this.routingHelper.navigateTo('presentation-outline/new/' + ownerId);
    }    
  }

  refresh() {
    let ownerId = this.routingHelper.getOwnerId();

    if (ownerId == '' || ownerId == null || ownerId == undefined) {
     
    }
    else 
    {
      this.load(ownerId);
    } 
  }
}
