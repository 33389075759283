import { ApplicationConstants } from '../_common/application-constants';


export class CustomPronunciation {
    literalString: string;
    phoneticPronunciation: string;

    constructor() {
        this.literalString = ApplicationConstants.defaultString;
        this.phoneticPronunciation = ApplicationConstants.defaultString;
    }
}

