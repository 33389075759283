import { Component, OnInit, Input } from '@angular/core';
import { SlideDataService } from '../../../_services/slide-data.service';
import { SlideData } from '../../../_models/slide-data';
import { ApplicationError } from 'src/app/_common/application-error';
import { ApplicationConstants } from 'src/app/_common/application-constants';
import { RoutingHelperService } from 'src/app/_services/routing-helper.service';
import { ProjectService } from 'src/app/_services/project.service';
import { Project } from 'src/app/_models/project';

import { ProjectSlideDataMultiple } from 'src/app/_models/project-slide-data-multiple';
import { ProjectSlideDataUtilities } from 'src/app/_models/project-slide-data-utilities';

@Component({
  selector: 'app-slide-data-list',
  templateUrl: './slide-data-list.component.html',
  styleUrls: ['./slide-data-list.component.css']
})
export class SlideDataListComponent implements OnInit {
  @Input() public items: SlideData[] = [];
  @Input() public autoLoad = true;
  @Input() public hideCreate = false;
  @Input() public parentId = '';
  public message: string = ApplicationConstants.emptyMessage;
  public project: Project | null = null;
  public projectId = '';
  public ownerId = '';
  public data: ProjectSlideDataMultiple | null = null;

  constructor(
    private slidedataService: SlideDataService,
    private projectService: ProjectService,
    private routingHelper: RoutingHelperService) { }

  ngOnInit() {
    if (this.autoLoad === true) {

      this.projectId = this.routingHelper.getValue('projectId');
      this.ownerId = this.routingHelper.getValue('ownerId');

      this.loadByProject(this.ownerId, this.projectId)
    } else {
      this.items = [];
      this.message = ApplicationConstants.emptyMessage;
    }
  }

  loadByProject(ownerId: string, projectId: string) {
    this.projectId = projectId;
    this.ownerId = ownerId;

    this.message = 'Loading...';

    this.data = null;
    this.items = [];

    this.slidedataService.getByProject(ownerId, projectId).subscribe({
      next: (data: ProjectSlideDataMultiple) => {
        this.data = data;
        
        if (data === null || data.slides === null || data.slides.length === 0) {
          this.items = [];
          this.message = ApplicationConstants.noDataMessage;
        } else {
          this.items = ProjectSlideDataUtilities.AssembleSlidesAndSlideItems(data);
          this.message = ApplicationConstants.emptyMessage;
        }
      },
      error: (error: ApplicationError) => this.message = error.friendlyMessage
    });

    this.projectService.getOwnerAndById(ownerId, projectId).subscribe({
      next: (data: Project | null) => {
        this.project = data;
      },
      error: (error: ApplicationError) => this.message = error.friendlyMessage
    });
  }

  rowClicked(event: MouseEvent, item: SlideData) {
    const url = `/slide-data/slide-data-details/${this.ownerId}/${this.projectId}/${item.id}`;
    console.log(`Navigating to ${url}`);
    this.routingHelper.navigateTo(url);
  }

  rowClickedKeyboardEvent(event: KeyboardEvent, item: SlideData) {
    const url = `/slide-data/slide-data-details/${this.ownerId}/${this.projectId}/${item.id}`;
    console.log(`Navigating to ${url}`);
    this.routingHelper.navigateTo(url);
  }

  showSectionHeader(slide: SlideData, currentIndex: number) {
    if (currentIndex === 0) {
      return true;
    } else {
      if (slide.sectionName !== this.items[currentIndex - 1].sectionName) {
        return true;
      }
      else {
        return false;
      }
    }
  }

  public getSlideText(slide: SlideData, maxLength: number): string {
    if (slide.isHidden === true) {
      return '[Hidden Slide]';
    }

    let text = '';
    slide.items.forEach(item => {
      text += item.text + ' ';
    });

    if (text.length > maxLength) {
      text = text.substring(0, maxLength) + '...';
    }

    return text;
  }

  public getSayText(slide: SlideData, maxLength: number): string {
    if (slide.isHidden === true) {
      return '';
    }

    let text = '';
    slide.items.forEach(item => {
      text += item.sayText + '\n';
    });

    if (text.trim().length === 0) {
      return '[No voice over text yet. Click to add some.]'
    }
    else {
      if (text.length > maxLength) {
        text = text.substring(0, maxLength) + '...';
      }
  
      return text;
    }    
  }

  GetVideoStatus(slide: SlideData | null): string {
    if (slide === null) {
      return 'Unknown';
    }
    else {
      if (slide.hasScreenshots === false) {
        return 'Needs Screenshots';
      }
      else if (slide.isVideoCurrent === true) {
        return 'Video Ready';
      }
      else if (slide.hasVideo === true && slide.isReadyForVideo === true) {
        return 'Needs Refresh';
      } else if (slide.hasVideo === false && slide.isReadyForVideo === true) {
        return 'Ready for Video Refresh';
      } else {
        return 'Not Ready for Video Creation';
      }
    }
  }
}
