<div class="form-horizontal">
    <h2>Create New Presentation using ChatGPT</h2>

    <div class="col-8">
        <p class="lead">
            Use ChatGPT to create a new presentation based on the topic you provide. 
        </p>
        <p>
            The voice-over text will also be generated for each slide and will appear in the notes section of the slide.       
        </p>
        <p>
            If you like the outline, you can then use it to create a PowerPoint / Google Slides PPTX file that you can then download. You'll also have the option to create a video project directly from the outline.
        </p>
    </div>

    <div *ngIf="message" class="alert alert-primary" role="alert">
        {{ message }}
    </div>

    <div class="form-group">
        <form [formGroup]="theForm">
            <app-textarea-field 
                            [parentForm]="theForm" 
                            controlName="topicText" 
                            displayName="Topic description"
                            placeholder="Examples...'Create a presentation that explains why the sky is blue' or 'Create a presentation that compares web development with React versus Angular'"
                            labelIsBold="true"
                            [rows]="4"
                            #topicText></app-textarea-field>
            <app-checkbox-field 
                            [parentForm]="theForm" 
                            controlName="isComparison" 
                            displayName="Is this topic a comparison? (e.g. 'Cheese vs Milk' or 'React vs Angular')"
                            #isComparison></app-checkbox-field>
            <app-textbox-field 
                            [parentForm]="theForm" 
                            controlName="approximateSlideCount" 
                            displayName="Approximate # of slides"                            
                            #approximateSlideCount></app-textbox-field>
            

            <div class="btn-toolbar mb-3" role="toolbar">
                <button type="button" (click)="save()" class="btn btn-primary mx-2"
                    [disabled]="!theForm.valid">
                    Save
                </button>
                <button type="button" class="btn btn-secondary mx-2" (click)="cancel()">
                    Cancel
                </button>
                
            </div>
        </form>
    </div>
</div>