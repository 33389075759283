<ng-container [formGroup]="formGroup">
    <div class="row p-2" [class.bg-light]="formGroup.controls.markedForDelete.value">
        <div class="col-5">
            <input class="form-control" type="text" formControlName="literalString"
                placeholder="text string to replace">
        </div>
        <div class="col-5">
            <input class="form-control" type="text" formControlName="phoneticPronunciation"
                placeholder="phonetic pronunciation">
        </div>
        <div class="col-2 p-1">

            <i class="fa fa-solid fa-trash-can fa-lg" (click)="delete()"
                *ngIf="!formGroup.controls.markedForDelete.value"></i>

            <i (click)="undelete()" *ngIf="formGroup.controls.markedForDelete.value"
                class="fa fa-solid fa-trash-can-undo fa-lg"></i>

        </div>
    </div>
</ng-container>