<div *ngIf="claimsResponse === null">
    Loading...
</div>

<div *ngIf="message !== null">
    {{ message }}
</div>

<div *ngIf="accountMessage !== null">
    {{ accountMessage }}
</div>

<div *ngIf="service === null">
    security service is null
</div>

<div *ngIf="service !== null">
    <div *ngIf="service.currentUser === null">
        current user is null
    </div>

    <div *ngIf="service.currentUser !== null">
        current user is null

        <p>
            {{ service.currentUser }}
        </p>
        <p>
            isAdministrator: {{ service.currentUser.isAdministrator }}
        </p>
    </div>


</div>


<div *ngIf="claimsResponse !== null">
    <div class="container">
        <div class="row">
            <div class="col-2">
                Is User:
            </div>
            <div class="col-auto">
                {{isUser}}
            </div>
        </div>

        <div class="row">
            <div class="col-2">
                Is Admin:
            </div>
            <div class="col-auto">
                {{isAdministrator}}
            </div>
        </div>

        <div class="row">
            <div class="col-2">
                Has Subscription:
            </div>
            <div class="col-auto">
                {{hasSubscription}}
            </div>
        </div>

        <div class="row">
            <div class="col-2">
                Custom Voices:
            </div>
            <div class="col-auto">
                {{hasCustomVoices}}
            </div>
        </div>
    </div>
</div>

<div *ngIf="claimsResponse !== null">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <h1>Profile</h1>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <h2>Claims</h2>
                <table class="table table-striped">
                    <thead>
                        <tr>
                            <th>Claim Type</th>
                            <th>Claim Value</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let claim of claimsResponse.claims">
                            <td>{{ claim.type }}</td>
                            <td>{{ claim.value }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>

<div *ngIf="activeAccount!==null">
    <div class="container">
        <div class="row">
            <div class="col">
                authorityType: {{ activeAccount.authorityType }}
            </div>
        </div>

        <div class="row">
            <div class="col">
                environment: {{ activeAccount.environment }}
            </div>
        </div>

        <div class="row">
            <div class="col">
                homeAccountId: {{ activeAccount.homeAccountId }}
            </div>
        </div>

        <div class="row">
            <div class="col">
                name: {{ activeAccount.name }}
            </div>
        </div>

        <div class="row">
            <div class="col">
                nativeAccountId: {{ activeAccount.nativeAccountId }}
            </div>
        </div>

        <div class="row">
            <div class="col">
                tenantId: {{ activeAccount.tenantId }}
            </div>
        </div>

        <div class="row">
            <div class="col">
                username: {{ activeAccount.username }}
            </div>
        </div>

        <div class="row">
            <div class="col">
                idToken: {{ activeAccount.idToken }}
            </div>
        </div>

        <div class="row">
            <div class="col">
                idTokenClaims?.acr: {{ activeAccount.idTokenClaims?.acr }}
            </div>
        </div>

        <div class="row">
            <div class="col">
                idTokenClaims?.auth_time: {{ activeAccount.idTokenClaims?.auth_time }}
            </div>
        </div>

        <div class="row">
            <div class="col">
                idTokenClaims?.amr: {{ activeAccount.idTokenClaims?.amr }}
            </div>
        </div>

        <div class="row">
            <div class="col">
                idTokenClaims?.at: {{ activeAccount.idTokenClaims?.at }}
            </div>
        </div>

        <div class="row">
            <div class="col">
                idTokenClaims?.aud: {{ activeAccount.idTokenClaims?.aud }}
            </div>
        </div>

        <div class="row">
            <div class="col">
                idTokenClaims?.cloud_instance_host_name: {{ activeAccount.idTokenClaims?.cloud_instance_host_name }}
            </div>
        </div>

        <div class="row">
            <div class="col">
                cnf: {{ activeAccount.idTokenClaims?.cnf }}
            </div>
        </div>

        <div class="row">
            <div class="col">
                emails: {{ activeAccount.idTokenClaims?.emails }}
            </div>
        </div>

        <div class="row">
            <div class="col">
                exp: {{ toDate(activeAccount.idTokenClaims?.exp) }}
            </div>
        </div>

        <div class="row">
            <div class="col">
                home_oid: {{ activeAccount.idTokenClaims?.home_oid }}
            </div>
        </div>


        <div class="row">
            <div class="col">
                iat: {{ activeAccount.idTokenClaims?.iat }}
            </div>
        </div>

        <div class="row">
            <div class="col">
                idp: {{ activeAccount.idTokenClaims?.idp }}
            </div>
        </div>

        <div class="row">
            <div class="col">
                iss: {{ activeAccount.idTokenClaims?.iss }}
            </div>
        </div>

        <div class="row">
            <div class="col">
                login_hint: {{ activeAccount.idTokenClaims?.login_hint }}
            </div>
        </div>

        <div class="row">
            <div class="col">
                m: {{ activeAccount.idTokenClaims?.m }}
            </div>
        </div>

        <div class="row">
            <div class="col">
                name: {{ activeAccount.idTokenClaims?.name }}
            </div>
        </div>

        <div class="row">
            <div class="col">
                nbf: {{ activeAccount.idTokenClaims?.nbf }}
            </div>
        </div>

        <div class="row">
            <div class="col">
                nonce: {{ activeAccount.idTokenClaims?.nonce }}
            </div>
        </div>

        <div class="row">
            <div class="col">
                oid: {{ activeAccount.idTokenClaims?.oid }}
            </div>
        </div>

        <div class="row">
            <div class="col">
                p: {{ activeAccount.idTokenClaims?.p }}
            </div>
        </div>

        <div class="row">
            <div class="col">
                preferred_username: {{ activeAccount.idTokenClaims?.preferred_username }}
            </div>
        </div>

        <div class="row">
            <div class="col">
                roles: {{ activeAccount.idTokenClaims?.roles }}
            </div>
        </div>

        <div class="row">
            <div class="col">
                sid: {{ activeAccount.idTokenClaims?.sid }}
            </div>
        </div>

        <div class="row">
            <div class="col">
                sub: {{ activeAccount.idTokenClaims?.sub }}
            </div>
        </div>

        <div class="row">
            <div class="col">
                tenant_region_scope: {{ activeAccount.idTokenClaims?.tenant_region_scope }}
            </div>
        </div>

        <div class="row">
            <div class="col">
                tenant_region_sub_scope: {{ activeAccount.idTokenClaims?.tenant_region_sub_scope }}
            </div>
        </div>

        <div class="row">
            <div class="col">
                tfp: {{ activeAccount.idTokenClaims?.tfp }}
            </div>
        </div>

        <div class="row">
            <div class="col">
                tid: {{ activeAccount.idTokenClaims?.tid }}
            </div>
        </div>

        <div class="row">
            <div class="col">
                ts: {{ activeAccount.idTokenClaims?.ts }}
            </div>
        </div>

        <div class="row">
            <div class="col">
                u: {{ activeAccount.idTokenClaims?.u }}
            </div>
        </div>

        <div class="row">
            <div class="col">
                upn: {{ activeAccount.idTokenClaims?.upn }}
            </div>
        </div>

        <div class="row">
            <div class="col">
                ver: {{ activeAccount.idTokenClaims?.ver }}
            </div>
        </div>

        <div class="row">
            <div class="col">
                x5c_ca: {{ activeAccount.idTokenClaims?.x5c_ca }}
            </div>
        </div>


    </div>
</div>

