import { ApplicationConstants } from "../_common/application-constants";
import { ModelBase } from "./model-base";
import { SlideOutline } from "./slide-outline";


export class PresentationOutline extends ModelBase {
  title: string = '';
  status: string = '';
  statusMessage: string = '';
  statusMessageDetail: string = '';
  createdDate: Date = new Date();
  completedDate: Date = new Date(0);
  templateName: string = '';
  promptText: string = '';
  requestedSlideCount: number = 0;
  topic1Name: string = '';
  topic2Name: string = '';
  isComparison: boolean = false;
  hasPptx: boolean = false;
  pptxUrl: string = '';
  hasProject: boolean = false;
  projectId: string = '';  
  slides: SlideOutline[] = [];
}

