import { Injectable } from '@angular/core';
import { PresentationOutline } from '../_models/presentation-outline';
import { Project } from '../_models/project';
import { HttpClient } from '@angular/common/http';
import { GenericService } from './GenericService';
import { CommonUtilities } from '../_common/common-utilities';
import { catchError, Observable } from 'rxjs';
import { CreateOutlineRequest } from '../_models/create-outline-request';

@Injectable({
  providedIn: 'root'
})
export class PresentationOutlineService extends GenericService<PresentationOutline> {

  constructor(
    http: HttpClient
  ) {
    super(http);
  }

  get endpoint(): string {
    return '/api/outline';
  }

  create(ownerId: string, saveThis: CreateOutlineRequest): Observable<PresentationOutline> {
    return this.http.post<PresentationOutline>(`${this.endpoint}/create/${ownerId}`, saveThis).pipe(
      catchError(err => CommonUtilities.handleHttpError<PresentationOutline>(err))
    );
  }

  createPptx(ownerId: string, id: string): Observable<PresentationOutline> {
    return this.http.get<PresentationOutline>(`${this.endpoint}/createpptx/${ownerId}/${id}`).pipe(
      catchError(err => CommonUtilities.handleHttpError<PresentationOutline>(err))
    );
  }

  createProject(ownerId: string, id: string): Observable<Project> {
    return this.http.get<Project>(`/api/project/createfromoutline/${ownerId}/${id}`).pipe(
      catchError(err => CommonUtilities.handleHttpError<Project>(err))
    );
  }
}


