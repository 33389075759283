import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MsalService, MsalBroadcastService, MSAL_GUARD_CONFIG, MsalGuardConfiguration } from '@azure/msal-angular';
import { AuthenticationResult, InteractionStatus, PopupRequest, RedirectRequest, EventMessage, EventType } from '@azure/msal-browser';
import { Subject, interval } from 'rxjs';
import { filter, takeUntil, takeWhile } from 'rxjs/operators';
import { SecurityStateInfo } from './security-state-info';
import { SecurityService } from './_services/security.service';
import { ApplicationSettingsService } from './_services/application-settings.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy {
  securityState!: SecurityStateInfo;
  private readonly _destroying$ = new Subject<void>();


  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private authService: MsalService,
    private securityService: SecurityService,
    private settingsService: ApplicationSettingsService,
    private msalBroadcastService: MsalBroadcastService
  ) {

    this.securityState = this.securityService.securityState;
    this.securityState.onLoginRequested = () => this.loginRedirect();
    this.securityState.onLogoutRequested = () => this.logout(false);
  }

  getIdTokensAsString(): string {
    if (this.securityState.activeAccount) {
      return JSON.stringify(this.securityState.activeAccount.idTokenClaims);
    }
    else {
      return "No active account";
    }
  }

  ngOnInit(): void {
    this.securityState.isIframe = window !== window.parent && !window.opener; // Remove this line to use Angular Universal
    this.setLoginDisplay();

    this.settingsService.ensureSettingsAreLoaded();

    this.authService.instance.enableAccountStorageEvents(); // Optional - This will enable ACCOUNT_ADDED and ACCOUNT_REMOVED events emitted when a user logs in or out of another tab or window
    this.msalBroadcastService.msalSubject$
      .pipe(
        // tap((msg: EventMessage) => console.log('*** BROADCAST SERVICE EVENT: ' + JSON.stringify(msg))),
        filter((msg: EventMessage) => msg.eventType === EventType.ACCOUNT_ADDED || msg.eventType === EventType.ACCOUNT_REMOVED),
      )
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .subscribe((result: EventMessage) => {
        console.log(`Got en event message: ${JSON.stringify(result)}`);

        if (this.authService.instance.getAllAccounts().length === 0) {
          console.log('Setting window location pathname to /...');
          window.location.pathname = "/";
        } else {
          console.log('calling setLoginDisplay()...');
          this.setLoginDisplay();
        }
      });

    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.HANDLE_REDIRECT_END),
        // tap((msg: EventMessage) => console.log('*** BROADCAST SERVICE EVENT after filter: ' + JSON.stringify(msg))),
      )
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .subscribe((result: EventMessage) => {

        const timer$ = interval(250);

        timer$.pipe(takeWhile(() => this.securityService.securityState === null ||
          this.securityService.securityState.activeAccount === null)).subscribe(() => {
            console.log('timer$ getActiveAccount() subscription...');

            const account = this.authService.instance.getActiveAccount();

            if (account) {
              console.log('*** account: ' + JSON.stringify(account));
              this.securityService.securityState.activeAccount = account;
              this.securityService.ensureCurrentUserIsPopulated();
            }
            else {
              console.log('timer$: account is null')
            }

          });
      });



    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        this.setLoginDisplay();
        this.checkAndSetActiveAccount();
      })
  }

  setLoginDisplay() {
    const theValue = this.authService.instance.getAllAccounts().length > 0;

    console.log('*** setLoginDisplay() theValue: ' + theValue);

    this.securityState.loginDisplay = theValue;
  }

  checkAndSetActiveAccount() {
    /**
     * If no active account set but there are accounts signed in, sets first account to active account
     * To use active account set here, subscribe to inProgress$ first in your component
     * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
     */
    this.securityState.activeAccount = this.authService.instance.getActiveAccount();

    if (!this.securityState.activeAccount && this.authService.instance.getAllAccounts().length > 0) {
      this.securityState.accounts = this.authService.instance.getAllAccounts();
      this.authService.instance.setActiveAccount(this.securityState.accounts[0]);
    }
  }

  loginRedirect() {
    if (this.msalGuardConfig.authRequest) {
      this.authService.loginRedirect({ ...this.msalGuardConfig.authRequest } as RedirectRequest);
    } else {
      this.authService.loginRedirect();
    }
  }

  loginPopup() {
    if (this.msalGuardConfig.authRequest) {
      this.authService.loginPopup({ ...this.msalGuardConfig.authRequest } as PopupRequest)
        .subscribe((response: AuthenticationResult) => {
          this.authService.instance.setActiveAccount(response.account);
        });
    } else {
      this.authService.loginPopup()
        .subscribe((response: AuthenticationResult) => {
          this.authService.instance.setActiveAccount(response.account);
        });
    }
  }

  logout(popup?: boolean) {
    if (popup) {
      this.authService.logoutPopup({
        mainWindowRedirectUri: "/"
      });
    } else {
      this.authService.logoutRedirect();
    }
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }

  loginCallback() {
    this.loginPopup();
  }

  logoutCallback() {
    console.log("logoutCallback called");
    this.logout(false);
  }
}
