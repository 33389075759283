<!-- eslint-disable @angular-eslint/template/interactive-supports-focus -->
<!-- eslint-disable @angular-eslint/template/click-events-have-key-events -->
<div class="container">
    <div class="row pb-3">

    </div>
    <div class="row text-center">

        <div class="col">
            <img src="assets/slide-speaker-logo-1.svg" 
                (click)="logoClick()"
                class="mx-auto pt-5" width="400" alt="slide speaker logo" />
        </div>

        <div class="col text-start">
            <div class="display-4 pb-3">Slide Speaker helps you create videos with voice-over from your PowerPoint
                presentations.</div>

            <div>
                <ul class="list-group list-group-flush">
                    <li class="list-group-item">Step 1: Upload your presentation</li>
                    <li class="list-group-item">Step 2: Add the text you want to say for each slide</li>
                    <li class="list-group-item">Step 3: We'll create the audio and video for you</li>
                    <li class="list-group-item">Step 4: Download the video and do whatever you want with it</li>
                    <li class="list-group-item"><a href="getting-started-demos.html"><b>Demos</b></a> | <a href="features.html"><b>Features</b></a> | <a href="pricing.html"><b>Pricing</b></a> </li>
                </ul>
            </div>
        </div>

    </div>

    <div class="row pt-3" *ngIf="service.securityState === null || service.securityState.activeAccount === null">
        <div class="col text-center">
            <div class="display-6">We are going to need you to log in though...</div>
            <div class="pt-3">
                <button class="btn btn-primary btn-lg" (click)="login()">Login</button>
            </div>
        </div>
    </div>

    <div class="row pt-3" *ngIf="service.securityState !== null && service.securityState.activeAccount !== null">
        <div class="col text-center">
            <div class="display-6">It looks like you're logged in. Let's go to your projects...</div>
            <div class="pt-3">
                <button class="btn btn-primary btn-lg" (click)="goToProjectList()">Project List</button>
            </div>
        </div>
    </div>
</div>