/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { MsalService } from "@azure/msal-angular";
import { Observable } from "rxjs";
import { getBaseUrl } from "src/main";


@Injectable()
export class BaseUrlInterceptor implements HttpInterceptor {
  constructor(private authService: MsalService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // const baseUrl = environment.baseUrl;
    let baseUrl = getBaseUrl();

    // const token = this.authService.instance.getActiveAccount()?.idToken;
    const token: string | null = null;

    // if baseUrl ends with a slash, remove it
    if (baseUrl.endsWith('/')) {
      baseUrl = baseUrl.substr(0, baseUrl.length - 1);
    }

    // if (baseUrl.endsWith('/api') === false) {
    //   baseUrl = `${baseUrl}/api`
    // }

    if (req.url.startsWith('https://') ||
      req.url.startsWith('http://') ||
      req.url.startsWith('/assets')) {
      console.log(`BaseUrlInterceptor: Skipping ${req.url} --> not changing url.`);

      if (token) {
        req = req.clone(
          {
            headers: req.headers.set('Authorization', `Bearer ${token}`)
          });
      }

      return next.handle(req);
    }
    else {
      let url: string;

      if (req.url.startsWith('/')) {
        url = `${baseUrl}${req.url}`;
      }
      else {
        url = `${baseUrl}/${req.url}`
      }

      console.log(`BaseUrlInterceptor: Changing ${req.url} to ${url}`);

      if (token) {
        req = req.clone(
          {
            url: url,
            headers: req.headers.set('Authorization', `Bearer ${token}`)
          });

        return next.handle(req);
      }
      else {
        const apiReq = req.clone({ url: url });

        return next.handle(apiReq);
      }
    }
  }
}
