

export class SlideItemImageInfo {
  public slideItemId: string | null = null;
  public slideItemNumber: number = -1;
  public originalUrl: string | null = null;
  public mediumlUrl: string | null = null;
  public smallUrl: string | null = null;

}
