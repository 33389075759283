import { Component, HostListener, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ProjectJob } from 'src/app/_models/project-job';
import { ProjectJobDetailDialogInfo } from './project-job-detail-dialog-info';
import { FormBuilder } from '@angular/forms';
import { timestamp } from 'rxjs';
import { ApplicationConstants } from 'src/app/_common/application-constants';

@Component({
  selector: 'app-project-job-detail-dialog',
  templateUrl: './project-job-detail-dialog.component.html',
  styleUrl: './project-job-detail-dialog.component.css'
})
export class ProjectJobDetailDialogComponent {

  item: ProjectJob | null = null;

  theForm = this.fb.nonNullable.group({
    timestamp: [{ value: ApplicationConstants.defaultDate, disabled: true }],
    id: [ApplicationConstants.defaultString],
    scriptId: [ApplicationConstants.defaultString],
    parentJobId: [ApplicationConstants.defaultString],
    jobTypeName: [ApplicationConstants.defaultString],
    jobType: [ApplicationConstants.defaultString],
    status: [ApplicationConstants.defaultString],
    errorMessage: [ApplicationConstants.defaultString],
    projectId: [ApplicationConstants.defaultString],
    slideDataId: [ApplicationConstants.defaultString],
    slideItemId: [ApplicationConstants.defaultString],
    slideNumbers: [ApplicationConstants.defaultString],
    replaceExisting: [false],
    endDate: [ApplicationConstants.defaultDate],
    lastModifiedDate: [ApplicationConstants.defaultDate],
  });

  constructor(
    private dialogRef: MatDialogRef<ProjectJobDetailDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ProjectJobDetailDialogInfo,
    private fb: FormBuilder
  ) {
    this.item = data.item;
    console.log('ProjectJobDetailDialogComponent item:', this.item);

    if (this.item !== null) {
      this.theForm.patchValue({
        timestamp: this.item.timestamp,
        id: this.item.id,
        scriptId: this.item.scriptId,
        parentJobId: this.item.parentJobId,
        jobTypeName: this.item.jobTypeName,
        jobType: this.item.jobType,
        status: this.item.status,
        errorMessage: this.item.errorMessage,
        projectId: this.item.projectId,
        slideDataId: this.item.slideDataId,
        slideItemId: this.item.slideItemId,
        slideNumbers: this.formatSlideNumbers(this.item.slideNumbers),
        replaceExisting: this.item.replaceExisting,
        endDate: this.item.EndDate    ,
        lastModifiedDate: this.item.timestamp    
      });
    }
  }

  

  @HostListener('document:keyup', ['$event'])
  handleDeleteKeyboardEvent(event: KeyboardEvent) {
    console.log('ProjectJobDetailDialogComponent handleDeleteKeyboardEvent event:', event);
    console.log('ProjectJobDetailDialogComponent handleDeleteKeyboardEvent event.key:', event.key);
    if (event.key === 'ArrowRight' || event.key === 'ArrowDown') {
      this.next();
    }
    else if (event.key === 'ArrowLeft' || event.key === 'ArrowUp') {
      this.previous();
    }
  }

  formatSlideNumbers(values: number[]): string {
    if (values === undefined || values === null || values.length === 0) {
      return '';
    }
    else {
      return values.join(', ');
    }
  }

  toDateString(input: Date): string {
    if (input === null || input === undefined) {
      return '';
    }
    else {
      return input.toDateString();
    }    
  }

  previous() {
    this.dialogRef.close('previous');
  }

  next() {
    this.dialogRef.close('next');
  }

  cancel() {
    this.dialogRef.close('cancel');
  }
}

