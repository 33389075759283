import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Project } from 'src/app/_models/project';
import { ProjectJob } from 'src/app/_models/project-job';
import { ProjectJobService } from 'src/app/_services/project-job.service';
import { ProjectService } from 'src/app/_services/project.service';
import { RoutingHelperService } from 'src/app/_services/routing-helper.service';
import { ProjectJobDetailDialogComponent } from '../project-job-detail-dialog/project-job-detail-dialog.component';
import { ProjectJobDetailDialogInfo } from '../project-job-detail-dialog/project-job-detail-dialog-info';

@Component({
  selector: 'app-recent-project-jobs',
  templateUrl: './recent-project-jobs.component.html',
  styleUrl: './recent-project-jobs.component.css'
})
export class RecentProjectJobsComponent implements OnInit {
  public theModel: Project | null = null;
  public projectId = '';
  public ownerId = '';
  public data: ProjectJob[] = [];
  public message: string = '';
  public lastRefreshDate: Date | null = null;
  public selectedIndex = -1;

  constructor(
    private projectService: ProjectService,
    private projectJobService: ProjectJobService,
    private routingHelper: RoutingHelperService,
    private dialog: MatDialog) { }

  ngOnInit(): void {
    this.load();
  }

  public load() {
    this.projectId = this.routingHelper.getId();
    this.ownerId = this.routingHelper.getOwnerId();

    this.message = '';

    if (this.projectId === '' || this.ownerId === '') {
      console.error('RecentProjectJobsComponent: projectId and ownerId are required.');
      this.message = 'projectId and ownerId are required.';
      return;
    }
    else {
      this.projectService.getOwnerAndById(this.ownerId, this.projectId).subscribe(
        {
          next: data => {
            console.log('RecentProjectJobsComponent getDataByOwnerAndById data:', data);
            this.theModel = data;
          },
          error: error => {
            console.error('RecentProjectJobsComponent getDataByOwnerAndById error:', error);
            this.theModel = null;
            this.message = 'Error loading project.';
          }
        });

      this.projectJobService.getRecent(this.ownerId, this.projectId).subscribe(
        {
          next: data => {
            console.log('RecentProjectJobsComponent getRecent data:', data);
            this.data = data;
            this.lastRefreshDate = new Date();
          },
          error: error => {
            console.error('RecentProjectJobsComponent getRecent error:', error);
            this.data = [];
          }
        });
    }
  }

  maxLength(maxLength: number, value: string) {
    if (value.length > maxLength) {
      return value.substring(0, maxLength) + '...';
    }
    else {
      return value;
    }
  }

  public showDetailDialog(index: number) {
    // item: ProjectJob | null = null;

    if (index < 0 || index >= this.data.length) {
      console.error('RecentProjectJobsComponent showDetailDialog index out of range:', index);
      this.selectedIndex = -1;
      return;
    }
    
    this.selectedIndex = index;
    const item = this.data[index];

    const dialogConfig = new MatDialogConfig();

    dialogConfig.maxHeight = '90vh';

    const data = new ProjectJobDetailDialogInfo();
    data.item = item;

    dialogConfig.data = data;
    dialogConfig.enterAnimationDuration = 0;
    dialogConfig.closeOnNavigation = true;
    dialogConfig.exitAnimationDuration = 0;

    const dialogRef = this.dialog.open(ProjectJobDetailDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      console.log('RecentProjectJobsComponent showDetailDialog dialogRef.afterClosed result:', result);

      if (result === 'next') {
        this.showDetailDialog(index + 1);
      }
      else if (result === 'previous') {
        this.showDetailDialog(index - 1);
      }
    });
  }
    

  public clear() {
    if (this.projectId === '' || this.ownerId === '') {
      return;
    }
    else {
      this.projectJobService.deleteRecentProjectJobs(this.ownerId, this.projectId).subscribe(
        {
          next: data => {
            console.log('RecentProjectJobsComponent clear data:', data);
            this.message = 'Jobs cleared.';
            this.data = [];
            this.load();
          },
          error: error => {
            console.error('RecentProjectJobsComponent clear error:', error);
            this.message = 'Error clearing jobs.';
            this.data = [];
          }
        });
    }
  }
}
