import { Component, Inject, OnInit } from '@angular/core';
import { VideoPlayerDialogInfo } from './VideoPlayerDialogInfo';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-video-player-dialog',
  standalone: true,
  imports: [],
  templateUrl: './video-player-dialog.component.html',
  styleUrl: './video-player-dialog.component.css'
})
export class VideoPlayerDialogComponent implements OnInit {
  constructor(
    private dialogRef: MatDialogRef<VideoPlayerDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: VideoPlayerDialogInfo
  ) {
  }

  ngOnInit() {
    console.log('VideoPlayerDialogComponent.ngOnInit');
  }

  cancel() {
    this.dialogRef.close();
  }
}

