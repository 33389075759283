/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Project } from '../_models/project';
import { catchError } from 'rxjs/operators';
import { CommonUtilities } from '../_common/common-utilities';
import { VideoRenderRequest } from '../_models/video-render-request';
import { ProjectData } from '../_models/project-data';

@Injectable({
  providedIn: 'root'
})
export class ProjectService {
  
  constructor(
    private http: HttpClient
    ) { }

    uploadFile(ownerId: string, projectId: string, file: File): Observable<any> {
      const url = `/api/upload/${ownerId}/${projectId}`
      console.log(`Uploading file to the server ${url}.`);
      const formData = new FormData();
      formData.append('file', file, file.name);
      return this.http.post(url, formData).pipe(
        catchError(err => CommonUtilities.handleHttpError<any>(err))
      );
    }
    

  getList(): Observable<Project[]> {
    console.log(`Getting Project list from the server /api/project.`);
    return this.http.get<Project[]>('/api/project').pipe(
      catchError(err => CommonUtilities.handleHttpError<Project[]>(err))
    );
  }

  getListByOwnerId(ownerId: string): Observable<Project[]> {
    console.log(`Getting Project list from the server /api/project/GetAllByOwnerId/${ownerId}.`);

    if (ownerId === '' || ownerId === null || ownerId === undefined) {
      throw new Error('ownerId is required.');
    }

    return this.http.get<Project[]>(`/api/project/GetAllByOwnerId/${ownerId}`).pipe(
      catchError(err => CommonUtilities.handleHttpError<Project[]>(err))
    );
  }

  isProjectNameAvailable(ownerId: string, projectName: string): Observable<boolean> {
    const url = `/api/project/isnameavailable/${ownerId}/${projectName}`;
    console.log(`Checking if project name is available.  Url: ${url}`);
    return this.http.get<false>(url).pipe(
      catchError(err => CommonUtilities.handleHttpError<boolean>(err))
    );
  }

  getById(id: number): Observable<Project | null> {
    console.log(`Getting Project id ${id} from the server /api/project/${id}.`);
    return this.http.get<Project>(
      '/api/project/' + id.toString()).pipe(
        catchError(err => CommonUtilities.handleHttpError<Project>(err))
      );
  }

  refreshAllVideos(ownerId: string, id: string): Observable<Project | null> {
    return this.http.get<Project | null>(
      `/api/project/refreshvideos/${ownerId}/${id}`).pipe(
        catchError(err => CommonUtilities.handleHttpError<Project>(err))
      );
  }

  getOwnerAndById(ownerId: string, id: string): Observable<Project | null> {
    console.log(`Getting project by owner id ${ownerId} and id ${id} from the server /api/project/GetByIdAndOwnerId/${ownerId}/${id}.`);
    return this.http.get<Project>(
      `/api/project/GetByIdAndOwnerId/${ownerId}/${id}`).pipe(
        catchError(err => CommonUtilities.handleHttpError<Project>(err))
      );
  }

  getDataByOwnerAndById(ownerId: string, id: string): Observable<ProjectData | null> {
    const url = `/api/project/data/${ownerId}/${id}`;
    console.log(`Getting project data by owner id ${ownerId} and id ${id} from the server ${url}.`);
    return this.http.get<ProjectData | null>(
      url).pipe(
        catchError(err => CommonUtilities.handleHttpError<ProjectData | null>(err))
      );
  }

  deleteById(ownerId: string, id: string): Observable<any> {
    console.log(`Deleting Project id ${id} for owner id ${ownerId} from the server //project/${ownerId}/${id}.`);
    return this.http.delete(`/api/project/${ownerId}/${id}`).pipe(
      catchError(err => CommonUtilities.handleHttpError<any>(err))
    );
  }

  save(saveThis: Project): Observable<Project> {
    console.log(`Saving Project to the server /api/project/${saveThis.ownerId}.`);
    return this.http.post<Project>(`/api/project/${saveThis.ownerId}`, saveThis).pipe(
      catchError(err => CommonUtilities.handleHttpError<Project>(err))
    );
  }

  queueRefreshPresentationVideo(ownerId: string, projectId: string, forceRefresh: boolean): Observable<VideoRenderRequest> {
    console.log(`Queuing presentation video render for ${projectId}...`);

      const request = new VideoRenderRequest();
      request.ownerId = ownerId;
      request.projectId = projectId;
      request.forceRefresh = forceRefresh;

      return this.http.post<VideoRenderRequest>(
        `/api/project/rendervideo/${ownerId}`, request).pipe(
        catchError(err => CommonUtilities.handleHttpError<VideoRenderRequest>(err)));   
  }
}
