<h3>Presentation Outlines</h3>

<div>
  <a [routerLink]="" (click)="createNew()" class="actionLink">Create New Presentation using ChatGPT</a> |
  <a [routerLink]="" (click)="refresh()" class="actionLink">Refresh</a>
</div>

<div class="col-8">
  <p class="lead">
      Use ChatGPT to create a new presentation based on the topic you provide. 
  </p>
  <p>
      NOTE: it might take about a minute for the presentation outline to be created.
  </p>  
</div>

<div *ngIf="message" class="alert alert-primary" role="alert">{{message}}</div>

<ng-template [ngIf]="isLoading === true">
  <div class="spinner-border" role="status">
    <span class="visually-hidden">Loading...</span>
  </div>
</ng-template>

<ng-template [ngIf]="isLoaded === true">

  <div class="form-group table-reponsive">
    <table class="table table-striped table-hover" id="search-results">
      <thead>
        <tr>
          <th>Title</th>
          <th>Status</th>
          <th>Slide Count</th>
          <th>PPTX File</th>
          <th>Project</th>
          <th>Last Modified</th>
          <th>&nbsp;</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of items">

          <td (click)="rowClicked($event, item)" *ngIf="item.title !== ''">{{item.title}}</td>
          <td (click)="rowClicked($event, item)" *ngIf="item.title === ''">{{item.promptText}}</td>
          <td (click)="rowClicked($event, item)">{{item.status}}</td>
          <td (click)="rowClicked($event, item)">{{item.slides.length}}</td>


          <td (click)="rowClicked($event, item)" *ngIf="item.pptxUrl !== ''"><a href="{{item.pptxUrl}}" target="_blank">(slides)</a></td>
          <td (click)="rowClicked($event, item)" *ngIf="item.pptxUrl === ''"></td>

          <td (click)="rowClicked($event, item)" *ngIf="item.projectId !== ''">
            <a routerLink="/project/project-details/{{item.ownerId}}/{{item.projectId}}">(project)</a>                    
          </td>
          <td (click)="rowClicked($event, item)" *ngIf="item.projectId === ''"></td>


          <td (click)="rowClicked($event, item)">{{item.timestamp | date:'short'}}</td>
          <td>
            <!-- <button mat-icon-button role="button" class="border-0 btn btn-link" [matMenuTriggerFor]="menu"
              [matMenuTriggerData]="{ item: item }" aria-label="Delete project">
              <i class="fa-solid fa-ellipsis"></i>
            </button> -->
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</ng-template>