import { Component, OnInit } from '@angular/core';
import { SecurityService } from '../_services/security.service';
import { ClaimsResponse } from '../_models/claims-response';
import { MsalService } from '@azure/msal-angular';
import { AccountInfo } from '@azure/msal-browser';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {
  claimsResponse: ClaimsResponse | null = null;
  message = '';
  accountMessage = '';
  activeAccount: AccountInfo | null = null;

  isUser = false;
  isAdministrator = false;
  hasSubscription = false;
  hasCustomVoices = false;

  constructor(
    public service: SecurityService,
    private authService: MsalService
  ) { }

  toDate(epoch: number | undefined): string {
    if (epoch === null || epoch === undefined) {
      return '';
    }

    return new Date(epoch * 1000).toLocaleString();
  }

  afterLoad() {
    if (this.claimsResponse === null) {
      this.isUser = false;
      this.isAdministrator = false;
      this.hasSubscription = false;
      return;
    }

    this.claimsResponse.claims.sort((a, b) => a.type.localeCompare(b.type));
    
    const roleClaimName = 'http://schemas.microsoft.com/ws/2008/06/identity/claims/role';
    const roleNameForUser = 'PptxUtil.User';
    const roleNameForAdmin = 'PptxUtil.Admin';

    const ClaimValue_Role_CustomVoiceUser = "PptxUtil.CustomVoices";
    const ClaimValue_SubscriptionStatus = "PptxUtil.SubscriptionStatus";

    this.isUser = this.claimsResponse.claims.some(c => c.type === roleClaimName && c.value === roleNameForUser);
    this.isAdministrator = this.claimsResponse.claims.some(c => c.type === roleClaimName && c.value === roleNameForAdmin);
    this.hasSubscription = this.claimsResponse.claims.some(c => c.type === ClaimValue_SubscriptionStatus && c.value === "ACTIVE");
    this.hasCustomVoices = this.claimsResponse.claims.some(c => c.type === roleClaimName && c.value === ClaimValue_Role_CustomVoiceUser);
  }

  ngOnInit() {
    this.service.getClaims()
    .subscribe({
      next: (result) => {
        this.claimsResponse = result;

        this.afterLoad();

        this.message = '';
      },
      error: (error) => {
        console.error(error);
        this.message = 'An error occurred while retrieving the claims. ' + error.friendlyMessage;
      }
    });

    this.activeAccount = this.authService.instance.getActiveAccount();

    if (this.activeAccount === null) {
      this.accountMessage = 'Active account is null.';
    }
    else {
      this.accountMessage = 'Active account is not null.';

      if (this.activeAccount.idTokenClaims) {
        console.log('idTokenClaims: ' + JSON.stringify(this.activeAccount.idTokenClaims));
      }
    }
  }
}
