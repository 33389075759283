import { Injectable } from '@angular/core';
import { ProjectJob } from '../_models/project-job';
import { HttpClient } from '@angular/common/http';
import { Observable, catchError } from 'rxjs';
import { CommonUtilities } from '../_common/common-utilities';

@Injectable({
  providedIn: 'root'
})
export class ProjectJobService {
 

 

  constructor(private http: HttpClient) { }

  getJobStatus(ownerId: string, projectId: string): Observable<ProjectJob[]> {
    console.log(`Getting ProjectJob list from the server /api/projectjob/inprogress/${ownerId}/${projectId}.`);
    
    return this.http.get<ProjectJob[]>(`/api/projectjob/inprogress/${ownerId}/${projectId}`).pipe(
      catchError(err => CommonUtilities.handleHttpError<ProjectJob[]>(err))
    );
  }

  getRecent(ownerId: string, projectId: string): Observable<ProjectJob[]> {
    console.log(`Getting ProjectJob list from the server /api/projectjob/recent/${ownerId}/${projectId}.`);
    
    return this.http.get<ProjectJob[]>(`/api/projectjob/recent/${ownerId}/${projectId}`).pipe(
      catchError(err => CommonUtilities.handleHttpError<ProjectJob[]>(err))
    );
  }

  deleteProjectJobs(ownerId: string, projectId: string) {
    console.log(`Deleting ProjectJobs from the server /api/projectjob/inprogress/${ownerId}/${projectId}.`);
    
    return this.http.delete<unknown>(`/api/projectjob/${ownerId}/${projectId}`).pipe(
      catchError(err => CommonUtilities.handleHttpError<ProjectJob[]>(err))
    );
  }

  deleteRecentProjectJobs(ownerId: string, projectId: string) {
    console.log(`Deleting ProjectJobs from the server /api/projectjob/recent/${ownerId}/${projectId}.`);
    
    return this.http.delete<unknown>(`/api/projectjob/recent/${ownerId}/${projectId}`).pipe(
      catchError(err => CommonUtilities.handleHttpError<ProjectJob[]>(err))
    );
  }
}
