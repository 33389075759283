import { Component, OnInit, Input } from '@angular/core';
import { ProjectService } from '../../../_services/project.service';
// import { ProjectAttribute } from '../../../_models/project-attribute';
import { Project } from '../../../_models/project';
import { ApplicationError } from 'src/app/_common/application-error';
import { ApplicationConstants } from 'src/app/_common/application-constants';
import { CommonUtilities } from 'src/app/_common/common-utilities';
import { RoutingHelperService } from 'src/app/_services/routing-helper.service';
import { SecurityService } from 'src/app/_services/security.service';
import { UserData } from 'src/app/_models/user-data';
import { FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-project-list',
  templateUrl: './project-list.component.html',
  styleUrls: ['./project-list.component.css']
})
export class ProjectListComponent implements OnInit {

  @Input() public items: Project[] = [];
  @Input() public autoLoad = true;
  @Input() public hideCreate = false;
  @Input() public parentId = '';
  public ownerId = '';
  public message: string = ApplicationConstants.emptyMessage;
  public userInfo: UserData | null = null;

  theForm = this.fb.nonNullable.group({
    currentOwnerId: [ { value: ApplicationConstants.defaultString, disabled: true }],
  });

  constructor(private projectService: ProjectService,
    private routingHelper: RoutingHelperService,
    public securityService: SecurityService,
    private fb: FormBuilder) { }

  ngOnInit() {

    if (this.autoLoad === true) {
      if (this.userInfo === null) {
        this.securityService.getCurrentUser().subscribe({
          next: (result) => {
            this.userInfo = result;
          },
          error: (error) => {
            console.error(error);
            this.userInfo = null;
          }
        });
      }

      let ownerId = this.routingHelper.getOwnerId();

      if (ownerId == '' || ownerId == null || ownerId == undefined) {

        this.securityService.getCurrentUser().subscribe({
          next: (data) => {
            if (data !== null) {
              if (data.isLoggedIn === true) {
                ownerId = data.ownerId;

                this.routingHelper.navigateTo(`/project/project-list/${ownerId}`);
                this.load(ownerId);
              }
              else {
                this.message = 'You must be logged in to view projects.';
              }
            }
          },
          error: (error) => {
            this.message = CommonUtilities.formatErrorMessage(error);
          }
        });
      }
      else {
        this.load(ownerId);
      }
    } else {
      this.items = [];
      this.message = ApplicationConstants.emptyMessage;
    }
  }

  changeOwner() {
    const ownerId = this.theForm.controls.currentOwnerId.value;

    this.routingHelper.navigateTo(`/project/project-list/${ownerId}`);

    this.load(ownerId);
  }

  public refresh() {
    console.log('ProjectListComponent refreshing...');
    this.load(this.ownerId);
    console.log('ProjectListComponent refreshed.');
  }

  public createNew() {
    if (this.securityService.isAdminOrHasSubscription === false && this.items.length > 0) {
      this.message = 'You must have a subscription to create more than one project.';
      return;
    }

    const ownerId = this.routingHelper.getOwnerId();

    this.routingHelper.navigateTo(`/project/project-editor/${ownerId}/0`);
  }

  public createOutline() {
    const ownerId = this.routingHelper.getOwnerId();

    this.routingHelper.navigateTo(`/presentation-outline/new/${ownerId}`);
  }

  private load(ownerId: string) {
    this.ownerId = ownerId;

    this.theForm.patchValue({
      currentOwnerId: ownerId,
    });

    if (this.securityService.currentUser === null ||
      this.securityService.currentUser.isAdministrator === false) {
      console.log('ProjectListComponent: Current user is not an administrator.');
      this.theForm.controls.currentOwnerId.disable();
    }
    else if (this.securityService.currentUser.isAdministrator === true) {
      console.log('ProjectListComponent: Current user is an administrator.');
      this.theForm.controls.currentOwnerId.enable();
    }

    this.projectService.getListByOwnerId(ownerId).subscribe(
      (data: Project[]) => {
        this.items = data;

        if (data === null || data.length === 0) {
          this.message = 'No projects found. Click the "Create New" button to create a new project.';
        } else {
          this.message = ApplicationConstants.emptyMessage;
        }
      },
      (error: ApplicationError) => this.message = error.friendlyMessage
    );
  }

  rowClicked(event: MouseEvent, item: Project) {
    this.routingHelper.navigateTo(`/project/project-details/${item.ownerId}/${item.id}`);
  }

  delete(event: MouseEvent, item: Project) {
    if (item !== null) {
      if (
        window.confirm(
          `Are you sure you want to delete this project? Project name: ${item.name}`
        )
      ) {
        this.projectService.deleteById(item.ownerId, item.id).subscribe(
          () => {
            this.load(this.ownerId);
          },
          (error) => {
            this.message = CommonUtilities.formatErrorMessage(error);
          }
        );
      }
    }
  }
}
