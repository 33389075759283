/* eslint-disable @typescript-eslint/no-unused-vars */
import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';

export const AuthGuard: CanActivateFn = (route, state) => {
  const authService = inject(MsalService);
  const router = inject(Router);

  const account = authService.instance.getActiveAccount();
  if (!account) {
    router.navigate(['/login']);
    return false;
  }
  return true;
};
