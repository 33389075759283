import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UserSubscription } from '../_models/user-subscription';
import { Observable, catchError } from 'rxjs';
import { CommonUtilities } from '../_common/common-utilities';

@Injectable({
  providedIn: 'root'
})
export class UserSubscriptionService {

  constructor(
    private http: HttpClient
  ) { }

  getByOwnerId(ownerId: string): Observable<UserSubscription | null> {
    console.log(`Getting user subscription id ${ownerId} from the server /api/usersubscription/${ownerId}.`);
    return this.http.get<UserSubscription | null>(
      `/api/usersubscription/${ownerId}`).pipe(
        catchError(err => CommonUtilities.handleHttpError<UserSubscription | null>(err))
      );
  }

  deleteByOwnerId(ownerId: string, subscriptionId: string): Observable<UserSubscription | null> {
    console.log(`deleting user subscription id ${ownerId} and subscription id ${subscriptionId} from the server /api/usersubscription/${ownerId}.`);
    return this.http.delete<UserSubscription | null>(
      `/api/usersubscription/${ownerId}/${subscriptionId}`).pipe(
        catchError(err => CommonUtilities.handleHttpError<UserSubscription | null>(err))
      );
  }
}
