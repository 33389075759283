<form [formGroup]="formGroup">
    <div class="container bg-dark-subtle">
        <div class="row">
            <div class="col-5 border p-2">
                text string to replace
            </div>
            <div class="col-5 border p-2">
                phonetic pronunciation
            </div>
            <div class="col-2 p-2 border">
                delete / undelete
            </div>
        </div>
    </div>
    <div class="container">
        <div class="row p-3 bg-light" *ngIf="control?.controls?.length === 0">
            <div class="col-12 align-items-center text-center">(no custom pronunciations)</div>
        </div>
    </div>
    <ng-container formArrayName="pronunciationGrid">
        <div class="container">
            <ng-container *ngFor="let item of control?.controls; let i = index">
                <app-pronunciation-grid-row [formGroup]="item"></app-pronunciation-grid-row>
            </ng-container>
            <div class="row col-2">
                <button type="button" class="btn btn-danger" (click)="addRow()">add</button>
            </div>
        </div>
    </ng-container>
</form>