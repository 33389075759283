<div mat-dialog-title class="p-3 bg-secondary text-white">
    {{data.title}}
</div>
<div mat-dialog-content class="video-container">
    <video controls class="video-container">
        <source src="{{data.videoUrl}}" type="video/mp4">
        Your browser does not support the video tag.
    </video>
</div>
<div class="videoToolbar" mat-dialog-actions>
    <button type="button" class="btn btn-primary mx-2" (click)="cancel()">
        Close
    </button>
    <a [href]="data.videoUrl" target="_blank">
        download video
    </a>    
</div>