import { SlideData } from './slide-data';
import { ProjectSlideDataMultiple } from './project-slide-data-multiple';
import { ProjectSlideData } from './project-slide-data';

export class ProjectSlideDataUtilities {

  static AssembleSlideItemsForSlide(data: ProjectSlideData): SlideData {

    if (data.slideData === undefined || data.slideData === null) {
      return new SlideData();
    }
    else {
      const slide = data.slideData;
      const items = data.slideItems.filter((item) => item.slideId === slide.id);

      if (items !== undefined || items !== null) {
        slide.items = items;
      }

      return slide;
    }
  }

  public static AssembleSlidesAndSlideItems(data: ProjectSlideDataMultiple): SlideData[] {
    const slides: SlideData[] = [];

    // foreach slide in the project, find the matching slide items
    data.slides.forEach((slide: SlideData) => {
      const items = data.slideItems.filter((item) => item.slideId === slide.id);

      if (items !== undefined) {
        slide.items = items;
        slides.push(slide);
      }
    });

    return slides;
  }
}
