<!-- display for xs screens (aka. mobile) -->
<div class="d-block d-lg-none">
  <ng-container *ngFor="let item of data?.slideItems; let last = last">
    <div class="pb-3">
      <div class="card">
        <div class="card-header">
          Item {{item.slideItemNumber | number}} of {{data?.slideItems?.length}}
        </div>
        <div class="card-body">
          <div class="card-title">{{item.text}}</div>
          <div class="card-text text-muted">
            <ng-container *ngIf="item.hasScreenshot === true; else noScreenshots">
              <img [src]="getImageUrl(item).smallUrl" class="img-thumbnail img-fluid" [alt]="item.slideItemNumber" />
              <br />
              <i class="fa-regular fa-circle-info" [id]="getImageTooltipId(item, true)" data-bs-toggle="tooltip"
                data-bs-placement="right" [title]="getImageUrl(item).smallUrl"></i>
            </ng-container>
            <ng-template #noScreenshots>
              <img src="assets/screenshot-pending.svg" class="img-thumbnail img-fluid" alt="screenshots in progress" />
            </ng-template>
            <div class="form-floating">
              <div>Say Text:</div>
              <textarea class="form-control say-text-control-mobile" placeholder="Enter voice-over text here..."
                [id]="getTextAreaId(item, true)"
                (change)="sayTextChanged($event, item, true, false)">{{item.sayText.trim()}}</textarea>
            </div>
            <div class="row pt-3" >
              <div class="col">
                <audio controls [id]="getAudioControlId(item, true)">
                  <source [src]="item.audioFileUrl" type="audio/mpeg">
                  Your browser does not support the audio element.
                </audio>
              </div>
              
              <div class="col-auto">
                <button type="button" class="btn btn-light" (click)="sayTextChanged($event, item, true, true)">
                  <i class="fa-sharp fa-light fa-arrows-retweet"></i> Regenerate
                </button>
              </div>
              <div class="col-auto">
                <button type="button" class="btn btn-light" (click)="refreshAudioFile(item, true)">
                  <i class="fa-sharp fa-light fa-rotate"></i> Reload Audio
                </button>
              </div>
            </div>
            
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>
<!-- display for non-mobile screens -->
<div class="d-none d-lg-block">
  <ng-container *ngFor="let item of data?.slideItems; let last = last">
    <div class="pb-3">
      <div class="card">
        <div class="card-header">
          Item {{item.slideItemNumber | number}} of {{data?.slideItems?.length | number}}
        </div>

        <div class="card-body">
          <div class="card-title">
            {{item.text}}
          </div>
          <div class="card-text text-muted">
            <div class="row">
              <div class="col p-2">
                <ng-container *ngIf="item.hasScreenshot === true; else noScreenshots">
                  <img [src]="getImageUrl(item).smallUrl" class="img-thumbnail img-fluid"
                    [alt]="item.slideItemNumber" />
                  <br />
                  <i class="fa-regular fa-circle-info" [id]="getImageTooltipId(item, false)" data-bs-toggle="tooltip"
                    data-bs-placement="right" [title]="getImageUrl(item).smallUrl"></i>
                </ng-container>
                <ng-template #noScreenshots>
                  <img src="assets/screenshot-pending.svg" class="img-thumbnail img-fluid"
                    alt="screenshots in progress" />
                </ng-template>

                <ng-container *ngIf="last">
                  {{enableTooltips(false)}}
                </ng-container>
              </div>
              <div class="col p-2">
                <div>
                  Voice-over Text:
                </div>
                <div class="row pt-2">
                  <div class="form-floating">
                    <textarea class="form-control say-text-control" placeholder="Enter voice-over text here..."
                      [id]="getTextAreaId(item, false)"
                      (change)="sayTextChanged($event, item, false, false)">{{item.sayText.trim()}}</textarea>
                  </div>
                </div>
              </div>
              <div class="row pt-2">
                <div class="col-8">
                  <audio controls style="width: 100%;" [id]="getAudioControlId(item, false)">
                    <source [src]="item.audioFileUrl" type="audio/mpeg">
                    Your browser does not support the audio element.
                  </audio>
                </div>
                <div class="col"></div>
                <div class="col-auto">
                  <button type="button" class="btn btn-light" (click)="sayTextChanged($event, item, false, true)">
                    <i class="fa-sharp fa-light fa-arrows-retweet"></i> Regenerate
                  </button>
                </div>
                <div class="col-auto">
                  <button type="button" class="btn btn-light" (click)="refreshAudioFile(item, false)">
                    <i class="fa-sharp fa-light fa-rotate"></i> Reload Audio
                  </button>
                </div>
              </div>
            
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>