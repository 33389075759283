<h3>Job Queue</h3>

<div *ngIf="message" class="alert alert-primary" role="alert">{{message}}</div>

<button class="btn btn-link" (click)="refresh()">refresh</button> |
<button class="btn btn-link" (click)="clear()">clear</button> |
<button class="btn btn-link" (click)="restartService()">restart service</button> |
<button class="btn btn-link" (click)="quitService()">quit service</button> 

<div class="table-reponsive">
  <h4>Powerpoint Queue</h4>
  <div>
    Last Updated: {{items.statusDate}}
  </div>
  <table class="table table-striped table-hover" id="search-results">
    <thead>
      <tr>
        <th>Message id</th>
        <th>Data</th>
        <th>Dequeue Count</th>
        <th>Inserted On</th>
        <th>Expires On</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of items.pptxQueue">
        <td >{{item.messageId}}</td>
        <td >{{item.data}}</td>
        <td >{{item.dequeueCount}}</td>
        <td >{{item.insertedOn }}</td>
        <td >{{item.expiresOn }}</td>        
      </tr>
    </tbody>
  </table>
</div>

<div class="table-reponsive">
  <h4>Audio Render Queue</h4>
  <table class="table table-striped table-hover" id="search-results">
    <thead>
      <tr>
        <th>Message id</th>
        <th>Data</th>
        <th>Dequeue Count</th>
        <th>Inserted On</th>
        <th>Expires On</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of items.audioRenderQueue">
        <td >{{item.messageId}}</td>
        <td >{{item.data}}</td>
        <td >{{item.dequeueCount}}</td>
        <td >{{item.insertedOn }}</td>
        <td >{{item.expiresOn }}</td>        
      </tr>
    </tbody>
  </table>
</div>

<div class="table-reponsive">
  <h4>Video Render Queue</h4>
  <table class="table table-striped table-hover" id="search-results">
    <thead>
      <tr>
        <th>Message id</th>
        <th>Data</th>
        <th>Dequeue Count</th>
        <th>Inserted On</th>
        <th>Expires On</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of items.videoRenderQueue">
        <td >{{item.messageId}}</td>
        <td >{{item.data}}</td>
        <td >{{item.dequeueCount}}</td>
        <td >{{item.insertedOn }}</td>
        <td >{{item.expiresOn }}</td>        
      </tr>
    </tbody>
  </table>
</div>

<div class="table-reponsive">
  <h4>Combine Video Queue</h4>
  <table class="table table-striped table-hover" id="search-results">
    <thead>
      <tr>
        <th>Message id</th>
        <th>Data</th>
        <th>Dequeue Count</th>
        <th>Inserted On</th>
        <th>Expires On</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of items.combineVideoQueue">
        <td >{{item.messageId}}</td>
        <td >{{item.data}}</td>
        <td >{{item.dequeueCount}}</td>
        <td >{{item.insertedOn }}</td>
        <td >{{item.expiresOn }}</td>        
      </tr>
    </tbody>
  </table>
</div>