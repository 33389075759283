<div *ngIf="securityService.isAdminOrHasSubscription === false"
    class="alert alert-secondary d-flex align-items-center mt-3" role="alert">
    <img class="alertIcon me-2" src="assets/slide-speaker-logo-1.svg" alt="slide speaker logo"
        aria-label="pk_test_0DWE3LU0OK1BqDRoNARu52ej00OOGDIeWW">
    <div>
        Thanks for trying out Slide Speaker! Since you don't have a subscription, you're limited to 1 project with a
        maximum of 5 slides. <a *ngIf="securityService.hasSubscription === false" class="alert-link" [routerLink]="['/subscription-status']">
            Subscribe
        </a>
    </div>   
</div>