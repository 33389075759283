<div mat-dialog-title class="p-3 bg-secondary text-white">
    {{item?.id }} - {{item?.jobTypeName}}
</div>
<div mat-dialog-content>
    <div class="row" *ngIf="item === null">
        <div class="col-12 text-center">
            <div class="spinner-border text-primary" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
    </div>
    <div *ngIf="item !== null">
        <app-read-only-field-view [parentForm]="theForm" controlName="lastModifiedDate" displayName="last modified date"
            [value]="item.timestamp.toString()" #lastModifiedDate></app-read-only-field-view>
        <app-read-only-field-view [parentForm]="theForm" controlName="id" displayName="id" [value]="item.id" #id></app-read-only-field-view>
        <app-read-only-field-view [parentForm]="theForm" controlName="scriptId" displayName="scriptId" [value]="item.scriptId" #scriptId></app-read-only-field-view>
        <app-read-only-field-view [parentForm]="theForm" controlName="parentJobId" displayName="parentJobId" [value]="item.parentJobId" #parentJobId></app-read-only-field-view>

        <app-read-only-field-view [parentForm]="theForm" controlName="jobTypeName" displayName="jobTypeName" [value]="item.jobTypeName" #jobTypeName></app-read-only-field-view>
        <app-read-only-field-view [parentForm]="theForm" controlName="jobType" displayName="jobType" [value]="item.jobType" #jobType></app-read-only-field-view>
        <app-read-only-field-view [parentForm]="theForm" controlName="status" displayName="status" [value]="item.status" #status></app-read-only-field-view>


        <app-read-only-field-view [parentForm]="theForm" controlName="errorMessage" displayName="errorMessage" [value]="item.errorMessage" #errorMessage></app-read-only-field-view>
        <app-read-only-field-view [parentForm]="theForm" controlName="projectId" displayName="projectId" [value]="item.projectId" #projectId></app-read-only-field-view>
        <app-read-only-field-view [parentForm]="theForm" controlName="slideDataId" displayName="slideDataId" [value]="item.slideDataId" #slideDataId></app-read-only-field-view>
        <app-read-only-field-view [parentForm]="theForm" controlName="slideItemId" displayName="slideItemId" [value]="item.slideItemId" #slideItemId></app-read-only-field-view>
        <app-read-only-field-view [parentForm]="theForm" controlName="slideNumbers" displayName="slideNumbers"
            [value]="formatSlideNumbers(item.slideNumbers)" #slideNumbers></app-read-only-field-view>
        <app-read-only-field-view [parentForm]="theForm" controlName="replaceExisting" displayName="replaceExisting"
            [value]="(item.replaceExisting ? 'true' : 'false')" #replaceExisting></app-read-only-field-view>
        <app-read-only-field-view [parentForm]="theForm" controlName="endDate" displayName="end date"
            [value]="toDateString(item.EndDate)" #endDate></app-read-only-field-view>


        @if (item.errors.length === 0) {
            <div class="alert alert-success" role="alert">
                No errors
            </div>
        }
        @else {
            <div class="alert alert-danger" role="alert">
                <ul>
                    <li *ngFor="let error of item.errors">
                        {{error}}
                    </li>
                </ul>
            </div>
        }
    </div>
</div>
<div mat-dialog-actions>
    <div>
        <button type="button" class="btn btn-primary mx-2" (click)="previous()">
            &lt; &lt; Prev
        </button>
    </div>
    <div>
        <button type="button" class="btn btn-primary mx-2" (click)="cancel()">
            Close
        </button>
    </div>
    <div>
        <button type="button" class="btn btn-primary mx-2" (click)="next()">
            Next &gt; &gt;
        </button>
    </div>
</div>