import { Component, OnInit } from '@angular/core';
import { interval, Observable, Subscription } from 'rxjs';
import { SecurityService } from 'src/app/_services/security.service';

@Component({
  selector: 'app-evaluation-mode-message',
  templateUrl: './evaluation-mode-message.component.html',
  styleUrl: './evaluation-mode-message.component.css'
})
export class EvaluationModeMessageComponent implements OnInit {
  timer$: Observable<number> | null = null;
  timerSubscription: Subscription | null = null;



  constructor(public securityService: SecurityService) {


  }

  ngOnInit() {
    if (this.securityService.currentUser !== null) {
      console.log('EvaluationModeMessageComponent.ngOnInit() currentUser is not null');
      this.securityService.ensureCurrentUserIsPopulated(false);
    }
    else {
      console.log('EvaluationModeMessageComponent.ngOnInit() currentUser is null');

      this.timer$ = interval(2000);

      this.timerSubscription = this.timer$.subscribe(() => {
        if (this.securityService.currentUser !== null) {
          // unsubscribe and stop the timer
          console.log('EvaluationModeMessageComponent.ngOnInit() currentUser is not null...stopping timer');
          this.timerSubscription?.unsubscribe();
          this.timerSubscription = null;
          this.timer$ = null;
        }
        else {
          console.log('EvaluationModeMessageComponent.ngOnInit() timer fired');
          this.securityService.ensureCurrentUserIsPopulated(false);
        }
      });
    }
  }
}
