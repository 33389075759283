<nav style="
    --bs-breadcrumb-divider: url(
      &#34;data:image/svg + xml,
      %3Csvgxmlns='http://www.w3.org/2000/svg'width='8'height='8'%3E%3Cpathd='M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z'fill='currentColor'/%3E%3C/svg%3E&#34;
    );
  " aria-label="breadcrumb">
  <ol class="breadcrumb">
    <li class="breadcrumb-item">
      <a routerLink="/project/project-list/{{theModel.ownerId}}">Projects</a>
    </li>
    <li class="breadcrumb-item" *ngIf="theModel">
      {{ theModel.name }}
    </li>

  </ol>

  <div class="row">
    <div class="col p-1">
      <button (click)="refresh()" class="btn btn-outline-primary" data-bs-toggle="tooltip" data-bs-placement="top"
        title="Refresh this page" id="refresh-button">
        refresh
      </button>
    </div>

    <div class="col p-1 content-align-center" *ngIf="theModel !== null && theModel.isVideoCurrent === true">
      <button class="btn btn-outline-secondary" data-bs-toggle="tooltip" (click)="updatePresentationVideo()"
        data-bs-placement="top" id="update-presentation-video-button" title="Video is ready. No refresh needed...but you can if you want.">
        update video
      </button>
    </div>

    <div class="col p-1" *ngIf="theModel !== null && theModel.isVideoCurrent === false">
      <button (click)="updatePresentationVideo()" class="btn btn-outline-secondary" data-bs-toggle="tooltip"
        data-bs-placement="top" id="update-presentation-video-button" title="Refresh Presentation Video">
        update video
      </button>
    </div>

    <div class="col p-1">
      <button *ngIf="theModel !== null && theModel.hasVideo === true" (click)="showVideoDialog()"
        class="btn btn-outline-success" title="View video" id="show-video-dialog-button"
        title="Click to view the presentation video." data-bs-toggle="tooltip" data-bs-placement="top">
        <span class="spinner-border spinner-border-sm" aria-hidden="true" *ngIf="isLoadingVideo === true"></span>
        view video
      </button>

      <button *ngIf="theModel !== null && theModel.hasVideo === false" class="btn btn-outline-dark"
        id="show-video-dialog-button" title="No video available. Click 'update video' to create one."
        data-bs-toggle="tooltip" data-bs-placement="top">
        view video
      </button>
    </div>

    <div class="col justify-content-end p-1">
      <button (click)="goToProjectEditor()" class="btn btn-outline-info" data-bs-toggle="tooltip"
        data-bs-placement="top" title="Edit project settings" id="edit-project-button">settings</button>
    </div>
  </div>
</nav>

<div class="form-horizontal">
  <h2>Project: {{theModel.name}}</h2>

  <div *ngIf="message" class="alert alert-primary" role="alert">{{message}}</div>

  <app-evaluation-mode-message></app-evaluation-mode-message>

  <app-project-job-status [ownerId]="theModel.ownerId" [projectId]="theModel.id"></app-project-job-status>

  <div class="form-group">
    <form [formGroup]="theForm">

      <app-slide-data-list [autoLoad]="false" #slideDataListControl></app-slide-data-list>


    </form>
  </div>
</div>