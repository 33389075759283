/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApplicationConstants } from 'src/app/_common/application-constants';
import { Project } from 'src/app/_models/project';
import { ProjectService } from 'src/app/_services/project.service';
import { RoutingHelperService } from 'src/app/_services/routing-helper.service';
import { TextboxFieldComponent } from '../../textbox-field/textbox-field.component';
import { debounceTime } from 'rxjs';
import { CommonUtilities } from 'src/app/_common/common-utilities';
import { ApplicationError } from 'src/app/_common/application-error';
import { JobQueueService } from 'src/app/_services/job-queue.service';
import { PronunciationGridFormArray } from '../../pronunciation-grid/pronunciation-grid-form-array';
import { ProjectData } from 'src/app/_models/project-data';
import { VoiceInfo } from 'src/app/_models/voice-info';
import { CustomPronunciation } from 'src/app/_models/custom-pronunciation';
import { PronunciationGridRowItem } from '../../pronunciation-grid-row/pronunciation-grid-row-item';
import { ProjectJobService } from 'src/app/_services/project-job.service';
import { SlideDataService } from 'src/app/_services/slide-data.service';

@Component({
  selector: 'app-project-editor',
  templateUrl: './project-editor.component.html',
  styleUrl: './project-editor.component.css'
})
export class ProjectEditorComponent implements OnInit {
  public theModel: Project;
  public message: string = ApplicationConstants.emptyMessage;
  public theId: string = ApplicationConstants.emptyMessage;
  public ownerId: string = ApplicationConstants.emptyMessage;
  public theSelectedFile: File | null = null;
  public isLoaded: boolean = false;
  public isLoading: boolean = false;
  public isSaved: boolean = false;

  public blob: Blob | null = null;

  @ViewChild('projectName', { static: true })
  public projectNameControl: TextboxFieldComponent | null = null;

  theForm = this.fb.nonNullable.group({
    projectName: [ApplicationConstants.defaultString,
    [Validators.minLength(1), Validators.maxLength(100), Validators.required]],
    projectDescription: [ApplicationConstants.defaultString],
    isProjectNameAlreadyInUse: [false],
    projectNameMessage: [ApplicationConstants.emptyMessage],
    selectedFilename: ['(not set)', Validators.required],
    pronunciationGrid: new PronunciationGridFormArray(this.fb),
    selectedVoiceId: [''],
    selectedVoiceName: [''],
    selectedVoiceDescription: [''],
    selectedVoicePreviewUrl: ['']
  });


  constructor(
    private projectService: ProjectService,
    private queueService: JobQueueService,
    private projectJobService: ProjectJobService,
    private routingHelper: RoutingHelperService,
    private slideDataService: SlideDataService,
    private fb: FormBuilder) {
    this.theModel = new Project();
  }

  ngOnInit(): void {
    this.theForm.controls.projectNameMessage.disable();

    this.ownerId = this.routingHelper.getValue('ownerId');
    this.theId = this.routingHelper.getValue('id');

    if (this.theId !== '0') {
      this.load(this.ownerId, this.theId, false);
    }
    else {
      this.isLoaded = true;
      this.isLoading = false;

      this.theForm.controls.projectName.valueChanges.pipe(
        debounceTime(250)).subscribe(
          () => {
            // this.theModel.name = newValue;
            this.checkProjectNameIsAvailable();
          });
    }

    console.log(`ProjectEditorComponent.ngOnInit(): ownerId=${this.ownerId}, theId=${this.theId}`);
  }

  queuePerSlideScreenshots() {
    this.queueService.queuePerSlideScreenshots(this.ownerId, this.theId).subscribe({
      next: (data) => {
        console.log(`queuePerSlideScreenshots(): data=${data}`);
      },
      error: (error: ApplicationError) => {
        this.message = CommonUtilities.formatErrorMessage(error);
      }
    });
  }

  downloadProjectData() {
    this.slideDataService.getByProject(this.theModel.ownerId, this.theModel.id).subscribe((data) => {

      if (data === null) {
        console.error('downloadProjectData(): data is null');
        return;
      }
      else {
        const json = JSON.stringify(data, null, 2);

        this.blob = new Blob([json], { type: 'application/json' });

        const downloadURL = window.URL.createObjectURL(this.blob);
        const link = document.createElement('a');
        link.href = downloadURL;
        link.download = `project-details-${this.theModel.id}.json`;
        link.click();
      }
    });
  }

  load(ownerId: string, id: string, isReload = false) {
    this.ownerId = ownerId;
    this.theId = id;

    if (isReload === true) {
      this.routingHelper.navigateTo(`/project/project-editor/${this.ownerId}/${this.theId}`);
    }

    this.isLoading = true;
    this.isLoaded = false;
    this.isSaved = true;
    this.projectService.getDataByOwnerAndById(this.ownerId, this.theId).subscribe({
      next: (data: ProjectData | null) => {
        if (data === null || data.project === null) {
          this.message = `Project with id ${this.theId} not found.`;
          this.isLoaded = false;
          this.isLoading = false;
          return;
        }
        else {
          this.theModel = data.project;

          const selectedVoice = this.getSelectedVoice(data);

          if (selectedVoice !== null) {
            this.theForm.patchValue({
              selectedVoiceId: selectedVoice.voiceId,
              selectedVoiceName: selectedVoice.voiceName,
              selectedVoiceDescription: selectedVoice.description,
              selectedVoicePreviewUrl: selectedVoice.previewUrl
            });

            this.theForm.controls.selectedVoicePreviewUrl.enable();
          }
          else {
            this.theForm.patchValue({
              selectedVoiceId: '',
              selectedVoiceName: '',
              selectedVoiceDescription: '',
              selectedVoicePreviewUrl: ''
            });
          }

          this.theForm.patchValue({
            projectName: this.theModel.name,
            projectDescription: this.theModel.description,
            selectedFilename: '(not set)'
          });

          this.updatePronunciationGrid(data);

          this.isLoaded = true;
          this.isLoading = false;
        }
      },
      error: (error: ApplicationError) => {
        this.message = CommonUtilities.formatErrorMessage(error);
      }
    });
  }

  updatePronunciationGrid(data: ProjectData) {
    if (data === null ||
      data.project === null ||
      data.project.customPronunciations === null) {
      return;
    }

    const pronunciations = data.project.customPronunciations;

    if (pronunciations === null || pronunciations.length === 0) {
      return;
    }

    pronunciations.forEach((temp: CustomPronunciation) => {

      const item = new PronunciationGridRowItem();
      item.literalString.setValue(temp.literalString);
      item.phoneticPronunciation.setValue(temp.phoneticPronunciation);

      const row = this.fb.nonNullable.group<PronunciationGridRowItem>(
        item
      );

      this.theForm.controls.pronunciationGrid.controls.push(row);
    });
  }

  getSelectedVoice(data: ProjectData): VoiceInfo | null {
    if (data === null || data.availableVoices === null || data.availableVoices.length === 0 || data.project === null) {
      return null;
    }
    else {
      const selectedVoice = data.availableVoices.find(v => v.voiceId === data.project!.voiceId);

      if (selectedVoice === null || selectedVoice === undefined) {
        return null;
      }
      else {
        return selectedVoice;
      }
    }
  }

  private checkProjectNameIsAvailable() {
    if (this.isSaved === true) {
      // don't check if the project name is available if the project has already been saved
      this.theForm.patchValue({
        isProjectNameAlreadyInUse: false,
        projectNameMessage: ApplicationConstants.emptyMessage
      });

      this.theForm.controls.projectName.setErrors(null);
      this.theForm.controls.projectNameMessage.disable();

      return;
    }


    this.projectService.isProjectNameAvailable(this.ownerId, this.theForm.controls.projectName.value).subscribe({
      next: (data: boolean) => {
        if (data === false) {
          this.theForm.patchValue({
            isProjectNameAlreadyInUse: true,
            projectNameMessage: 'Project name is not available.'
          });

          this.theForm.controls.projectName.setErrors({ 'isProjectNameAlreadyInUse': true });
          this.theForm.controls.projectNameMessage.enable();
        }
        else {
          this.theForm.patchValue({
            isProjectNameAlreadyInUse: false,
            projectNameMessage: ApplicationConstants.emptyMessage
          });
          this.theForm.controls.projectName.setErrors(null);
          this.theForm.controls.projectNameMessage.disable();
        }
      }
    });
  }

  onFileSelected(event: any) {
    const target = event.target;

    if (target === null) {
      console.error('onFileSelected(): target is null');
      return;
    }
    else if (target.files === null) {
      console.error('onFileSelected(): target.files is null');
      return;
    }
    else if (target.files.length === 0) {
      console.error('onFileSelected(): target.files.length is 0');
      return;
    }
    else {
      console.log('onFileSelected(): file selected');

      const file = target.files[0];
      this.theSelectedFile = file;

      if (this.theSelectedFile !== null) {
        console.log(`onFileSelected(): selected file name is ${this.theSelectedFile.name}`);
        const filename = this.theSelectedFile.name;

        this.theForm.controls.selectedFilename.setValue(filename);
      }
    }
  }

  onUpload() {
    // const file = this.theForm.controls.file.value;
    // if (file) {
    //   this.uploadFile(file);
    // } else {
    //   console.error('No file selected');
    // }
  }

  uploadFile(file: File) {
    console.log(`uploading file ${file.name}...`);

    this.projectService.uploadFile(this.ownerId, this.theId, file).subscribe({
      next: () => {
        console.log(`uploadFile(): file ${file.name} uploaded`);
        this.message = `file ${file.name} uploaded.`;
      },
      error: (error: ApplicationError) => {
        this.message = CommonUtilities.formatErrorMessage(error);
      }
    });
  }

  queueSlideTextParsing(skipScreenshots: boolean) {
    this.queueService.queueParseText(this.ownerId, this.theId, skipScreenshots, -1, -1).subscribe({
      next: (data) => {
        console.log(`queueSlideTextParsing(): data=${data}`);
      },
      error: (error: ApplicationError) => {
        this.message = CommonUtilities.formatErrorMessage(error);
      }
    });
  }

  queueSlideScreenshots() {
    this.queueService.queueScreenshots(this.ownerId, this.theId).subscribe({
      next: (data) => {
        console.log(`queueSlideScreenshots(): data=${data}`);
      },
      error: (error: ApplicationError) => {
        this.message = CommonUtilities.formatErrorMessage(error);
      }
    });
  }

  markAllAsCurrent() {
    this.slideDataService.markAllAsCurrent(this.ownerId, this.theId).subscribe();
  }


  save() {
    if (this.isSaved === false) {
      this.saveForUploadFile();
    } else {
      this.saveForAdditionalChanges();
    }
  }

  private saveForAdditionalChanges() {
    this.message = ApplicationConstants.emptyMessage;

    if (this.theForm.valid === false) {
      return;
    }

    this.theModel.name = this.theForm.controls.projectName.value;
    this.theModel.description = this.theForm.controls.projectDescription.value;

    const pronunciations: CustomPronunciation[] = [];

    this.theForm.controls.pronunciationGrid.controls.forEach(item => {


      if (item.controls.literalString.value === null ||
        item.controls.literalString.value.trim() === '' ||
        item.controls.markedForDelete.value === true) {
        return;
      }
      else {
        const pronunciation = new CustomPronunciation();

        pronunciation.literalString = item.controls.literalString.value.trim();

        let phoneticPronunciation = item.controls.phoneticPronunciation.value;

        if (phoneticPronunciation === null || phoneticPronunciation.trim() === '') {
          phoneticPronunciation = '';
        }

        pronunciation.phoneticPronunciation = phoneticPronunciation.trim();

        pronunciations.push(pronunciation);
      }
    });

    this.theModel.customPronunciations = pronunciations;

    this.projectService.save(this.theModel).subscribe({
      next: (data: Project) => {
        this.isSaved = true;
        this.message = ApplicationConstants.savedMessage;
        this.theModel = data;
        this.theId = data.id.toString();
      },
      error: (error: ApplicationError) => {
        this.message = CommonUtilities.formatErrorMessage(error);
      }
    });
  }


  private saveForUploadFile() {
    this.message = ApplicationConstants.emptyMessage;
    if (this.theForm.valid) {
      this.beforeSave();

      if (this.theSelectedFile === null || this.theSelectedFile === undefined) {
        this.message = 'No file selected.';

        this.theForm.controls.selectedFilename.setErrors({ 'required': true });

        return;
      }
      else {
        this.projectService.save(this.theModel).subscribe({
          next: (data: Project) => {
            this.isSaved = true;
            this.message = ApplicationConstants.savedMessage;
            this.theModel = data;
            this.theId = data.id.toString();

            if (this.theSelectedFile !== null) {
              this.uploadFile(this.theSelectedFile)

              this.load(data.ownerId, data.id, true);
            }
          },
          error: (error: ApplicationError) => {
            this.message = CommonUtilities.formatErrorMessage(error);
          }
        });
      }
    } else {
      const msg = 'Form is in an invalid state';
      this.message = msg;
      console.info(msg);
    }
  }

  uploadSelectedFile() {
    if (this.theSelectedFile !== null) {
      console.log(`uploadSelectedFile(): uploading file ${this.theSelectedFile.name}`);
      this.uploadFile(this.theSelectedFile);
    }
    else {
      console.error('uploadSelectedFile(): no file selected...skipping');
    }
  }

  beforeSave() {
    if (this.theModel === null) {
      this.theModel = new Project();
    }

    if (this.theModel.ownerId === '') {
      this.theModel.ownerId = this.ownerId;
    }

    this.theModel.name = this.theForm.controls.projectName.value;
    this.theModel.description = this.theForm.controls.projectDescription.value;
  }

  showValidationErrors() {
    this.message = '';
    if (this.theForm.valid) {
      this.message = '';
    } else {
      const errors = this.theForm.errors;

      if (errors !== null) {
        this.message = errors.toString();
      } else {

        for (const controlName in this.theForm.controls) {
          console.log(controlName);
          const temp = this.theForm.get(controlName);

          // if temp is formarray
          if (temp instanceof FormArray) {
            this.showValidationErrorsForFormArray(temp);
          }
          else {
            if (temp !== null && temp.errors !== null) {
              for (const error in temp.errors) {
                if (error === 'required') {
                  this.message += controlName + ' is required; ';
                }
                else if (error === 'email') {
                  this.message += controlName + ' not a valid email; ';
                }
                else {
                  this.message += controlName + ' ' + error + '; ';
                }
              }
            }
          }
        }
      }
    }

    console.log(`showValidationErrors(): ${this.message}`);
  }

  showValidationErrorsForFormArray(temp: FormArray<any>) {
    for (let i = 0; i < temp.length; i++) {
      const formGroup = temp.controls[i] as FormGroup<PronunciationGridRowItem>;
      for (const controlName in formGroup.controls) {
        const temp = formGroup.get(controlName);
        if (temp !== null && temp.errors !== null) {
          for (const error in temp.errors) {
            if (error === 'required') {
              this.message += controlName + '[' + i + '] is required; ';
            }
            else if (error === 'email') {
              this.message += controlName + '[' + i + '] not a valid email; ';
            }
            else {
              this.message += controlName + '[' + i + '] ' + error + '; ';
            }
          }
        }
      }
    }
  }

  cancel() {
    if (this.isSaved === false) {
      this.routingHelper.back();
    }
    else {
      const url = `/project/project-details/${this.theModel.ownerId}/${this.theModel.id}`;

      this.routingHelper.navigateTo(url);
    }
  }

  navigateToList() {
    this.routingHelper.navigateTo('/project/project-list');
  }

  navigateToProject() {
    const url = `/project/project-details/${this.theModel.ownerId}/${this.theModel.id}`;

    this.routingHelper.navigateTo(url);
  }

  navigateToRecentJobs() {
    const url = `/project-job/recent-project-jobs/${this.theModel.ownerId}/${this.theModel.id}`;

    this.routingHelper.navigateTo(url);
  }


  deleteProjectJobs() {
    this.projectJobService.deleteProjectJobs(this.ownerId, this.theId).subscribe({
      next: (data) => {
        console.log(`deleteProjectJobs(): data=${data}`);
      },
      error: (error: ApplicationError) => {
        this.message = CommonUtilities.formatErrorMessage(error);
      }
    });
  }

  delete() {
    if (window.confirm('Are you sure you want to delete this item?')) {
      if (this.theId !== '0') {
        this.projectService.deleteById(this.ownerId, this.theId)
          .subscribe({
            next: () => {
              this.navigateToList();
            },
            error: error => {
              this.message = CommonUtilities.formatErrorMessage(error);
            }
          });
      } else {
        this.navigateToList();
      }
    }
  }
}
