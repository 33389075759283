import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { PronunciationGridRowItem } from './pronunciation-grid-row-item';

@Component({
  selector: 'app-pronunciation-grid-row',
  templateUrl: './pronunciation-grid-row.component.html',
  styleUrl: './pronunciation-grid-row.component.css'
})
export class PronunciationGridRowComponent {
  @Input()
  formGroup!: FormGroup<PronunciationGridRowItem>;

  constructor() {
  }

  delete() {
    this.formGroup.patchValue({
      markedForDelete: true
    });

    this.formGroup.controls.literalString.disable();
    this.formGroup.controls.phoneticPronunciation.disable();
  }

  undelete() {
    this.formGroup.patchValue({
      markedForDelete: false
    });

    this.formGroup.controls.literalString.enable();
    this.formGroup.controls.phoneticPronunciation.enable();
  }
}

