<nav style="
    --bs-breadcrumb-divider: url(
      &#34;data:image/svg + xml,
      %3Csvgxmlns='http://www.w3.org/2000/svg'width='8'height='8'%3E%3Cpathd='M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z'fill='currentColor'/%3E%3C/svg%3E&#34;
    );
  " aria-label="breadcrumb">
    <ol class="breadcrumb">
        <li class="breadcrumb-item">
            <a routerLink="/project/project-list/{{ownerId}}">Projects</a>
        </li>
        <li class="breadcrumb-item" *ngIf="theModel">
            <a routerLink="/project/project-details/{{theModel.ownerId}}/{{ theModel.id }}">{{
                theModel.name
                }}</a>
        </li>
    </ol>
</nav>

<div *ngIf="message!==''">
    <div class="alert alert-primary" role="alert">
        {{message}}
    </div>
</div>

<button class="btn btn-link" (click)="load()">refresh</button> |
<button class="btn btn-link" (click)="clear()">clear</button>


<h4>Project Jobs</h4>
<div>
    Last Updated: {{lastRefreshDate}}
</div>

<div class="table-reponsive">
    <table class="table table-striped table-hover vw-100 vh-100 w-100 mw-100" id="search-results">
        <thead>
            <tr>
                <th>Script Id</th>
                <th>Message Id</th>
                <th>Job Type</th>
                <th>Job Type Name</th>
                <th>Status</th>
                <th>Project Id</th>
                <th>Slide Data Id</th>
                <th>Slide Item Id</th>
                <th>Parent Job Id</th>
                <th>Slide Numbers</th>
                <th>Create Date</th>
                <th>Id</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let item of data; index as i" (click)="showDetailDialog(i)" [class.table-primary]="selectedIndex === i">
                <td>{{item.scriptId}}</td>
                <td>{{maxLength(100, item.messageId)}}</td>
                <td>{{item.jobType}}</td>
                <td>{{item.jobTypeName}}</td>
                <td>{{item.status}}</td>
                <td>{{item.projectId}}</td>
                <td>{{item.slideDataId}}</td>
                <td>{{item.slideItemId}}</td>
                <td>{{item.parentJobId}}</td>
                <td>{{item.slideNumbers.join(", ")}}</td>
                <td>{{item.timestamp }}</td>
                <td>{{item.id }}</td>
            </tr>
        </tbody>
    </table>
</div>