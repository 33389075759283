import { Component, Input } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { PronunciationGridRowItem } from '../pronunciation-grid-row/pronunciation-grid-row-item';
import { PronunciationGridFormArray } from './pronunciation-grid-form-array';

@Component({
  selector: 'app-pronunciation-grid',
  templateUrl: './pronunciation-grid.component.html',
  styleUrl: './pronunciation-grid.component.css'
})
export class PronunciationGridComponent {
  @Input() control!: FormArray<FormGroup<PronunciationGridRowItem>>;
  
  public get formGroup(): FormGroup {
    return this.control.parent as FormGroup;
  }

  public get pronunciationGrid(): PronunciationGridFormArray | null {
    const typedControl = this.control as PronunciationGridFormArray;
    if (typedControl === null) {
      return null;
    }
    return typedControl;
  }  

  constructor() {
  }

  addRow() {
    if (this.pronunciationGrid === null) {
      console.log('pronunciationGrid is null');
    }
    else {
      this.pronunciationGrid.addRow();
    }
  }
}
