import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule, NoopAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ReactiveFormsModule } from '@angular/forms';
import { AppComponent } from './app.component';

import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSnackBarModule } from '@angular/material/snack-bar';

import { TextboxFieldComponent } from './_components/textbox-field/textbox-field.component';
import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { ComboboxFieldComponent } from './_components/combobox-field/combobox-field.component';
import { LookupDropdownListComponent } from './_components/lookup-dropdown-list/lookup-dropdown-list.component';

import { ProjectDetailsComponent } from
  './_components/project/project-details/project-details.component';
import { ProjectListComponent } from
  './_components/project/project-list/project-list.component';

import { SlideDataDetailsComponent } from
  './_components/slide-data/slide-data-details/slide-data-details.component';
import { SlideDataListComponent } from
  './_components/slide-data/slide-data-list/slide-data-list.component';

import { SlideItemListComponent } from
  './_components/slide-item/slide-item-list/slide-item-list.component';

import { JobQueueDetailsComponent } from './_components/job-queue/job-queue-details/job-queue-details.component';
import { ProjectEditorComponent } from './_components/project/project-editor/project-editor.component';
import { BootstrapDebuggerComponent } from './_components/bootstrap-debugger/bootstrap-debugger.component';

import { PronunciationGridComponent } from './_components/pronunciation-grid/pronunciation-grid.component';
import { PronunciationGridRowComponent } from './_components/pronunciation-grid-row/pronunciation-grid-row.component';
import { VoiceChooserComponent } from './_components/project/voice-chooser/voice-chooser.component';
import { MsalModule, MsalInterceptor, MSAL_INSTANCE, MSAL_GUARD_CONFIG, MSAL_INTERCEPTOR_CONFIG, MsalService, MsalGuard, MsalBroadcastService, MsalRedirectComponent } from '@azure/msal-angular';
import { FailedComponent } from './failed/failed.component';
import { ProfileComponent } from './profile/profile.component';
import { BaseUrlInterceptor } from './_services/base-url-interceptor';
import { HomeComponent } from './home/home.component';
import { MSALInstanceFactory, MSALGuardConfigFactory, MSALInterceptorConfigFactory } from './msal-config';
import { ProjectJobStatusComponent } from './_components/project-job-status/project-job-status.component';
import { RecentProjectJobsComponent } from './_components/project-job/recent-project-jobs/recent-project-jobs.component';
import { ProjectJobDetailDialogComponent } from './_components/project-job/project-job-detail-dialog/project-job-detail-dialog.component';
import { ReadOnlyFieldViewComponent } from './_components/read-only-field-view/read-only-field-view.component';
import { SubscriptionStatusComponent } from './_components/subscription-status/subscription-status.component';
import { SubscriptionThanksComponent } from './_components/subscription-thanks/subscription-thanks.component';
import { EvaluationModeMessageComponent } from './_components/evaluation-mode-message/evaluation-mode-message.component';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { AdminComponent } from './_components/admin/admin.component';
import { PresentationOutlineListComponent } from './_components/presentation-outline/presentation-outline-list/presentation-outline-list.component';
import { PresentationOutlineDetailComponent } from './_components/presentation-outline/presentation-outline-detail/presentation-outline-detail.component';
import { SlideOutlineDetailComponent } from './_components/presentation-outline/slide-outline-detail/slide-outline-detail.component';
import { SlideOutlineBulletDetailComponent } from './_components/presentation-outline/slide-outline-bullet-detail/slide-outline-bullet-detail.component';
import { PresentationOutlineCreateComponent } from './_components/presentation-outline/presentation-outline-create/presentation-outline-create.component';
import { CheckboxFieldComponent } from './_components/checkbox-field/checkbox-field.component';
import { TextareaFieldComponent } from './_components/textarea-field/textarea-field.component';




@NgModule({
  declarations: [
    AppComponent,
    NavMenuComponent,
    BootstrapDebuggerComponent,
    TextboxFieldComponent,
    ComboboxFieldComponent,
    LookupDropdownListComponent,
    ProjectDetailsComponent,
    ProjectEditorComponent,
    ProjectListComponent,
    PresentationOutlineListComponent,
    PresentationOutlineDetailComponent,
    SlideDataDetailsComponent,
    SlideDataListComponent,
    SlideItemListComponent,
    JobQueueDetailsComponent,
    PronunciationGridComponent,
    PronunciationGridRowComponent,
    VoiceChooserComponent,
    ProfileComponent,
    FailedComponent,
    HomeComponent,
    ProjectJobStatusComponent,
    RecentProjectJobsComponent,
    ProjectJobDetailDialogComponent,
    ReadOnlyFieldViewComponent,
    SubscriptionStatusComponent,
    SubscriptionThanksComponent,
    EvaluationModeMessageComponent,
    AdminComponent,
    PresentationOutlineListComponent,
    PresentationOutlineDetailComponent,
    SlideOutlineDetailComponent,
    SlideOutlineBulletDetailComponent,
    PresentationOutlineCreateComponent,
    CheckboxFieldComponent,
    TextareaFieldComponent
  ],
  bootstrap: [AppComponent, MsalRedirectComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA], imports: [BrowserModule,
    NoopAnimationsModule,
    MatTableModule,
    MatSnackBarModule,
    AppRoutingModule,
    ReactiveFormsModule,
    MatMenuModule,
    MsalModule,
    MatButtonModule,
    MatToolbarModule,
    MatListModule,
    MatDialogModule,
    NgbModule,
    BrowserAnimationsModule], providers: [
      {
        provide: HTTP_INTERCEPTORS,
        useClass: MsalInterceptor,
        multi: true
      },
      {
        provide: MSAL_INSTANCE,
        useFactory: MSALInstanceFactory
      },
      {
        provide: MSAL_GUARD_CONFIG,
        useFactory: MSALGuardConfigFactory
      },
      {
        provide: MSAL_INTERCEPTOR_CONFIG,
        useFactory: MSALInterceptorConfigFactory
      },
      MsalService,
      MsalGuard,
      MsalBroadcastService,
      {
        provide: HTTP_INTERCEPTORS,
        useClass: BaseUrlInterceptor,
        multi: true,
      },
      provideHttpClient(withInterceptorsFromDi()),
      provideAnimationsAsync()
    ]
})
export class AppModule { }
