import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApplicationSettings } from '../_models/application-settings';
import { Observable, catchError, tap } from 'rxjs';
import { CommonUtilities } from '../_common/common-utilities';

@Injectable({
  providedIn: 'root'
})
export class ApplicationSettingsService {

  public loaded = false;
  public settings: ApplicationSettings | null = null;

  constructor(
    private http: HttpClient
  ) { }

  ensureSettingsAreLoaded() {
    if (this.loaded === false) {
      console.log('ApplicationSettingsService.ensureSettingsAreLoaded calling getSettings...');
      this.getSettings().subscribe();
    }
    else {
      console.log('ApplicationSettingsService.ensureSettingsAreLoaded settings already loaded.');
    }
  }

  getSettings(): Observable<ApplicationSettings> {
    console.log('ApplicationSettingsService.getSettings...');
    return this.http.get<ApplicationSettings>('/api/settings').pipe(
      tap(settings => {
        console.log('ApplicationSettingsService.getSettings success', settings);
        this.settings = settings;
        this.loaded = true;
      }),
      catchError(err =>
        CommonUtilities.handleHttpError<ApplicationSettings>(err))
    );
  }
}
