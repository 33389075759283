<h3>Projects</h3>
<ng-container *ngIf="!hideCreate">
  <div>
    <a [routerLink]="" (click)="createNew()" class="actionLink">Create New Project</a> |
    <a [routerLink]="" (click)="createOutline()" class="actionLink" title="Use ChatGPT to create a new presentation">Create Presentation using ChatGPT</a> |
    <a [routerLink]="" (click)="refresh()" class="actionLink">Refresh</a>
  </div>
</ng-container>

<div *ngIf="message" class="alert alert-primary" role="alert">{{message}}</div>

<app-evaluation-mode-message></app-evaluation-mode-message>

<form [formGroup]="theForm">
  <div *ngIf="theForm.controls.currentOwnerId.enabled === true">
    <app-textbox-field [parentForm]="theForm" controlName="currentOwnerId" displayName="Owner Id"
      displayName="Change Owner Id"></app-textbox-field>
    <div class="btn-toolbar">
      <button (click)="changeOwner()" class="btn btn-primary">Change Owner</button>
    </div>
  </div>  
</form>


<div class="form-group table-reponsive">
  <table class="table table-striped table-hover" id="search-results">
    <thead>
      <tr>
        <th *ngIf="userInfo!==null && userInfo.isAdministrator === true">Owner id</th>
        <th>Name</th>
        <th>Description</th>
        <th>Last Modified</th>
        <th>&nbsp;</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of items">
        <td *ngIf="userInfo!==null && userInfo.isAdministrator === true" (click)="rowClicked($event, item)">
          {{item.ownerId}}</td>
        <td (click)="rowClicked($event, item)">{{item.name}}</td>
        <td (click)="rowClicked($event, item)">{{item.description}}</td>
        <td (click)="rowClicked($event, item)">{{item.timestamp | date:'short'}}</td>
        <td>
          <button mat-icon-button role="button" class="border-0 btn btn-link" [matMenuTriggerFor]="menu"
            [matMenuTriggerData]="{ item: item }" aria-label="Delete project">
            <i class="fa-solid fa-ellipsis"></i>
          </button>
        </td>
      </tr>
    </tbody>
  </table>
</div>
<mat-menu #menu="matMenu">
  <ng-template matMenuContent let-item="item">

    <button mat-menu-item (click)="delete($event, item)">
      <i class="fa-solid fa-trash-can"></i>
      &nbsp;
      <span>Delete</span>
    </button>
  </ng-template>
</mat-menu>