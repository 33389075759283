/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, OnInit, Input } from '@angular/core';
import { SlideItem } from '../../../_models/slide-item';
import { Router } from '@angular/router';
import { ApplicationError } from 'src/app/_common/application-error';
import { ApplicationConstants } from 'src/app/_common/application-constants';
import { SlideItemImageInfo } from 'src/app/_models/slide-item-image-info';
import { SayTextUpdate } from 'src/app/_models/say-text-update';
import { ProjectSlideData } from 'src/app/_models/project-slide-data';
import { SlideItemService } from 'src/app/_services/slide-item.service';
import { MatSnackBar } from '@angular/material/snack-bar';
declare let bootstrap: any;
@Component({
  selector: 'app-slide-item-list',
  templateUrl: './slide-item-list.component.html',
  styleUrls: ['./slide-item-list.component.css']
})
export class SlideItemListComponent implements OnInit {
  @Input() public data: ProjectSlideData | null = null;
  @Input() public hideCreate = false;

  public wasVoiceOverTextChanged = false;
  public message: string = ApplicationConstants.emptyMessage;
  private tooltipList = new Array<any>();

  constructor(
    private slideitemService: SlideItemService,
    private router: Router,
    private snackBar: MatSnackBar) { }


  ngOnInit() {
    if (this.data !== null) {
      this.message = '';
    }
    else {
      this.message = 'No data available';
    }
  }

  public showToastMessage(message: string, action: string) {
    this.snackBar.open(message, action, { duration: 3000, verticalPosition: 'top', horizontalPosition: 'center' });
  }

  enableTooltips(isForMobileElement: boolean) {
    // console.log('enableTooltips() -- Enabling tooltips');

    this.data?.slideItems.forEach(item => {
      const elementId = this.getImageTooltipId(item, isForMobileElement);
      const element = document.getElementById(elementId);

      const tooltip = bootstrap.Tooltip.getOrCreateInstance(element);
      // console.log(`enableTooltips() -- tooltip: ${tooltip}`);
    });
  }

  getTextAreaId(item: SlideItem, isForMobileElement: boolean): string {
    if (isForMobileElement === true) {
      return 'textarea-mobile-' + item.id;
    }
    else {
      return 'textarea-' + item.id;
    }
  }

  getAudioControlId(item: SlideItem, isForMobileElement: boolean): string {
    if (isForMobileElement === true) {
      return 'audio-mobile-' + item.id;
    }
    else {
      return 'audio-' + item.id;
    }
  }

  getImageTooltipId(item: SlideItem, isForMobileElement: boolean): string {
    if (isForMobileElement === true) {
      return 'tooltip-mobile-' + item.id;
    }
    else {
      return 'tooltip-' + item.id;
    }
  }

  sayTextChanged($event: Event, item: SlideItem, isFromMobileElement: boolean, forceRefresh: boolean) {
    if (item !== null && item.slideItemNumber !== null) {
      const elementId = this.getTextAreaId(item, isFromMobileElement);

      const sayTextElement = document.getElementById(elementId) as HTMLInputElement | null;

      if (sayTextElement !== null) {
        const value = sayTextElement.value;

        if (item.sayText !== value) {
          item.sayText = value;
        }

        console.log(`sayTextChanged() -- sayTextElement: ${sayTextElement}`);
        console.log(`sayTextChanged() -- sayTextElement.value: ${sayTextElement.value}`);

        const data = new SayTextUpdate();
        data.ownerId = item.ownerId;
        data.projectId = item.projectId;
        data.slideDataId = item.slideId;
        data.slideItemId = item.id;
        data.slideItemNumber = item.slideItemNumber;
        data.sayText = sayTextElement.value;
        data.refresh = forceRefresh;

        this.wasVoiceOverTextChanged = true;

        this.slideitemService.updateSayText(data).subscribe({
          next: (data: any) => {
            console.log('sayTextChanged() -- data return value: ' + JSON.stringify(data));
            this.showToastMessage('Voice over text updated successfully. Be sure to press Refresh to get the new audio.', 'Close');
          }, error: (error: ApplicationError) => {
            console.log('sayTextChanged() -- error: ' + error.friendlyMessage);
          }
        });
      }
    }
  }

  private updateAudioControlUrl(item: SlideItem, isFromMobileElement: boolean) {
    const audioControlId = this.getAudioControlId(item, isFromMobileElement);
    const audioControl = document.getElementById(audioControlId) as HTMLAudioElement | null;

    if (audioControl !== null) {
      audioControl.src = item.audioFileUrl;
      audioControl.load();
    }
  }

  refreshAudioFile(item: SlideItem, isFromMobileElement: boolean) {
    if (item !== null) {
      this.slideitemService.getUpdatedAudioUrl(item).subscribe({
        next: (data: string | null) => {
          if (data === null) {
            console.log('refreshAudioFile() -- data is null');
          }
          else {
            console.log('refreshAudioFile() -- data: ' + data);
            item.audioFileUrl = data;

            this.updateAudioControlUrl(item, isFromMobileElement);

            this.showToastMessage('Audio refreshed', 'Close');
          }
        }, error: (error: ApplicationError) => {
          console.log('refreshAudioFile() -- ERROR: ' + error.friendlyMessage);
        }
      });
    }
  }

  rowClicked(event: MouseEvent, item: SlideItem) {
    this.router.navigate(['/slide-item/slide-item-details/' + item.id]);
  }

  getImageUrl(item: SlideItem): SlideItemImageInfo {
    const imageInfo = this.data?.slideItemImages.find(x => x.slideItemId === item.id);

    if (imageInfo === undefined || imageInfo === null) {
      return new SlideItemImageInfo();
    }
    else {
      return imageInfo;
    }
  }  
}
