import { Component } from '@angular/core';

@Component({
  selector: 'app-bootstrap-debugger',
  templateUrl: './bootstrap-debugger.component.html',
  styleUrl: './bootstrap-debugger.component.css'
})
export class BootstrapDebuggerComponent {

}
