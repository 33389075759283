import { Component, OnInit } from '@angular/core';
import { ApplicationError } from 'src/app/_common/application-error';
import { CommonUtilities } from 'src/app/_common/common-utilities';
import { VoiceInfo } from 'src/app/_models/voice-info';
import { SecurityService } from 'src/app/_services/security.service';
import { VoiceInfoService } from 'src/app/_services/voice-info.service';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrl: './admin.component.css'
})
export class AdminComponent implements OnInit {
  public message: string = '';

  constructor(
    private securityService: SecurityService,
    private voiceService: VoiceInfoService) { }

  public ngOnInit() {
    console.log('admin ngOnInit');

    if (this.securityService.isAdministrator !== true) {
      this.message = 'You are not authorized to view this page.';
    }
    
  }

  public getVoices() {
    if (this.securityService.isAdministrator !== true) {
      this.message = 'You are not authorized to view this page.';
      return;
    }
    else {
      console.log('Getting voice list...');
      
      this.voiceService.getList("system").subscribe({
        next: (data: VoiceInfo[]) => {
          if (data === null || data.length === 0) {
            this.message = `No voices found for this user.`;
            return;
          }
          else {
            console.log(`Voices found: ${data.length}`);

            console.log('Voices:');
            
            console.log(JSON.stringify(data, null, 2));            
          }          
        },
        error: (error: ApplicationError) => {
          this.message = CommonUtilities.formatErrorMessage(error);
        }
      });
    }    
  }

  public refreshVoices() {
    if (this.securityService.isAdministrator !== true) {
      this.message = 'You are not authorized to view this page.';
      return;
    }
    else {
      console.log('Refreshing voice list.');

      this.voiceService.refreshVoices().subscribe({
        next: (response) => this.message = response,
        error: (error) => this.message = error.message
      });
    }    
  }
}
